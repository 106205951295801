import React, { Component } from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "actions";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FileDownload from "@mui/icons-material/FileDownload";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";

import Button from "@mui/material/Button";
import path from "path";
import PDFViewer from "components/pdfViewer";

import moment from "moment";
import MessageFormat from "messageformat";
import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";
import { selectHealthDeclarationOfUsers } from "../../../../../../redux/health-declaration/hdf.selectors";
import { HDFDeclarationSummary } from "../../../../../../components/health-declaration-form/summary/HDFDeclarationSummary.component";
import { MyDropzone } from "../../../../../../components/health-declaration-form/summary-table/DropZone.component";
import { selectIsPaymentEnabled } from "../../../../../../selectors/ReviewAndConsentSelectors";
import { document } from "../../../../../../assets/data/documents";

class ReviewConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf: undefined,
      fileName: undefined,
      open: false,
      consent: false,
      plans: props.user.currentPlans,
      dependentPlans: props.user.premiumPlans,
      planSelected: props.user.planSelected,
      planSelectedDependent: props.user.planSelectedDependent,
      expanded: false,
      totalPremium: 0,
      rateMatrics: props.user.rateMatrics,
      payment: "",
      consentPayment: "",
      policyNo: props.user.client_name.policy_no,
      isPruAgencyMedEnrolment:
        props.user.client_name.config.isPruAgencyMedEnrolment === "True"
          ? true
          : false,
      kofaxCheckInterval: undefined,
      documents: []
    };
    this.props.consent(false);
  }

  handleHDFSubmission = () => {
    /**
     * @todo RAHUL to check the values for nonHDFPayload
     */

    const nonHDFPayload = {
      images: this.state.documents,
      consent: this.props.user.consent,
      hdfConsent: true,
      paymentConsent: this.props.user.paymentConsentRequired,
      coverageDetails: this.props.user.coverage_details,
      paymentMethod: false,
      totalPremium: 420,
      dependants: this.props.user.client_name.dependants
    };

    const HDFPayload = this.props.hdf.users.map(user => {
      const modifiedUserObject = {};
      const dataArray = [];
      let dataArrayObject = {};
      let personalDetailsObject = {};

      Object.keys(user).map(userKey => {
        console.log(user[userKey]);
        if (
          userKey === "id" ||
          userKey === "idNo" ||
          // userKey === "full_name" ||
          userKey === "type"
        ) {
          modifiedUserObject[userKey] = user[userKey];
        } else if (userKey === "full_name") {
        } else {
          dataArrayObject = {
            ...dataArrayObject,
            ...personalDetailsObject,
            [userKey]: user[userKey]
          };
          // dataArrayObject.set("personalDetailsObject", personalDetailsObject);
        }
      });
      modifiedUserObject["id"] = user.id;
      modifiedUserObject["idNo"] = user.id_no;
      if (user.person_type === "EMPLOYEE") {
        modifiedUserObject["type"] = "Employee";
      } else if (user.person_type === "SPOUSE") {
        modifiedUserObject["type"] = "Spouse";
      } else {
        modifiedUserObject["type"] = "Child";
      }
      // dataArray.push(personalDetailsObject);
      dataArray.push(dataArrayObject);
      modifiedUserObject["data"] = dataArray;

      return modifiedUserObject;
    });
    this.props.submitHDF(nonHDFPayload, HDFPayload, this.props.user.id);
  };

  handleChange = name => event => {
    var isConsent = event.target.checked;
    this.setState({ ...this.state, [name]: isConsent });
    this.props.consent(isConsent);

    if (this.props.hdf.users.length > 0) {
      !this.props.hdf.CMDocumentName && isConsent && this.handleHDFSubmission();
    }
    /*
     pru agency local testing
    if (this.props.hdf.users.length > 0) {
      !this.props.hdf.CMDocumentName && isConsent && this.handleHDFSubmission();
    }
      */
  };
  handleChangePayment = name => event => {
    var isConsent = event.target.checked;
    this.setState({ ...this.state, [name]: isConsent });
    this.props.paymentConsent(isConsent);
  };

  employeeEnrolmentCheck = coverage => {
    let coverageId = this.props.user.currentPlans.find(
      currentPlan => currentPlan.planType === coverage
    ).coverageId;
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules = categoryRules && categoryRules[coverageId];
    return coverageRules;
  };

  hidePremiumsCheck = coverageId => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules =
      isPruAgencyEnrolment === true || this.state.isPruAgencyMedEnrolment
        ? undefined
        : categoryRules[coverageId].hidePremiums;
    return coverageRules;
  };

  handleClick = (docUrl, fileName) => {
    this.setState({ pdf: docUrl, fileName: fileName, open: true });
  };

  handleWebClick = docUrl => {
    window.open(docUrl, "_blank", "noopener noreferrer");
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getLink = () => {
    let str = "https://www.prudential.com.sg/Privacy-Notice";
    return str.link("https://www.prudential.com.sg/Privacy-Notice");
  };
  showButton = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  getCoverageIdByName = coverageName => {
    return this.state.plans.find(plan => plan.planType === coverageName)
      .coverageId;
  };

  getCategorySubsidy = coverage => {
    let categotyPlans =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];

    let categorySubsidyArr = categotyPlans[coverage].subsidy;
    let categorySubsidy = 0;
    categorySubsidyArr.map(subArray => {
      categorySubsidy = categorySubsidy + parseInt(subArray); //tempSubsidy;
    });
    return categorySubsidy;
  };

  premiumInfo = planSelected => {
    let tempPremium = 0;
    let coverageName = planSelected && planSelected[0];
    var a = moment(this.props.user.dob, "D/M/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    this.state.dependentPlans.map(plan => {
      if (planSelected[1].option === plan.option) {
        plan.details.map(planType => {
          if (planType.header === planSelected[0]) {
            planType.details.map(dependent => {
              if (Array.isArray(planType.multiplier)) {
                if (dependent.header === "Employee") {
                  for (
                    let count = 0;
                    count < planType.multiplier.length;
                    count++
                  ) {
                    let tempPremium1 =
                      dependent.premium[count] * planType.multiplier[count] -
                      dependent.subsidy;
                    tempPremium = tempPremium + tempPremium1;
                  }
                }
              } else {
                if (dependent.header === "Employee") {
                  tempPremium =
                    diffYears >= 71 && this.state.isPruAgencyMedEnrolment
                      ? dependent.premium71 * planType.multiplier -
                        dependent.subsidy
                      : dependent.premium * planType.multiplier -
                        dependent.subsidy;
                }
              }
            });
          }
        });
      }
    });
    let categorySub = this.getCategorySubsidy(
      this.getCoverageIdByName(coverageName)
    );
    return tempPremium - categorySub;
  };

  premiumInfoVertical = (user, planSelectedVertical, insuree) => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let planSelectedLoc = planSelectedVertical;
    let rateMatrics = this.state.rateMatrics;
    let tempPremium = 0;
    if (insuree === "Employee" && planSelectedLoc[1] === "Opt Out")
      return tempPremium;
    if (planSelectedLoc === "Opt Out") return tempPremium;
    let diffDays;

    var a = moment(user.dob, "D/M/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    diffDays = b.diff(a, "years");
    let occupationClass = this.props.occupationToClassValue(user.occupation);
    let gender = user.gender;

    rateMatrics.map(rateMatric => {
      if (isPruAgencyEnrolment) {
        rateMatric.details.map(detail => {
          if (
            insuree === "Employee" &&
            detail.coverageId === planSelectedLoc[1].coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = gender === "M" ? rule.maleRate : rule.femaleRate;
                let SA = "0";
                let healthLoading = "0";
                let previousPlan = this.props.user.coverage_details.previous;

                if (planSelectedLoc[1].header === "Existing") {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc[1].coverageId) {
                      SA = prevPlan.sum_assured;
                      healthLoading = prevPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                } else if (planSelectedLoc[1].header === "VECI") {
                  previousPlan.map(plan => {
                    if (plan.code === "VCCA") {
                      SA = plan.sum_assured;
                    }
                  });
                } else {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc[1].coverageId) {
                      healthLoading = prevPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                  SA =
                    planSelectedLoc[1].details[0].overview.details[0]
                      .details[0];
                }

                if (planSelectedLoc[1].header === "VECI") {
                  tempPremium =
                    parseFloat(healthLoading) +
                    (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      0.3 *
                      parseFloat(rate).toFixed(2)) /
                      1000;
                } else {
                  tempPremium =
                    parseFloat(healthLoading) +
                    (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      parseFloat(rate).toFixed(2)) /
                      1000;
                }
              }
            });
          } else if (
            insuree === "SPOUSE" &&
            detail.coverageId === planSelectedLoc.coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = gender === "M" ? rule.maleRate : rule.femaleRate;
                let SA = "0";
                let healthLoading = "0";
                let previousPlan = this.props.user.dependencies[0]
                  .coverage_details.previous;

                if (planSelectedLoc.header === "Existing") {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc.coverageId) {
                      SA = prevPlan.sum_assured;
                      healthLoading = prevPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                } else if (planSelectedLoc.header === "VECI") {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === "VCCA") {
                      SA = prevPlan.sum_assured;
                    }
                  });
                } else {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc.coverageId) {
                      healthLoading = prevPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                }

                if (planSelectedLoc.header === "VECI") {
                  tempPremium =
                    parseFloat(healthLoading) +
                    (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      0.3 *
                      parseFloat(rate).toFixed(2)) /
                      1000;
                } else {
                  tempPremium =
                    parseFloat(healthLoading) +
                    (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      parseFloat(rate).toFixed(2)) /
                      1000;
                }
              }
            });
          }
        });
      } else if (this.state.isPruAgencyMedEnrolment) {
        rateMatric.details.map(detail => {
          if (
            insuree === "Employee" &&
            detail.coverageId === planSelectedLoc[1].coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = rule.rate;
                let SA = "0";
                let previousPlan = this.props.user.coverage_details.previous;

                if (planSelectedLoc[1].header === "Existing") {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc[1].coverageId) {
                      SA = prevPlan.sum_assured;
                    }
                  });
                } else {
                  SA =
                    planSelectedLoc[1].details[0].overview.details[0]
                      .details[0];
                }

                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                  parseFloat(rate).toFixed(2);
              }
            });
          } else if (
            insuree === "SPOUSE" &&
            detail.coverageId === planSelectedLoc.coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = rule.rate;
                let SA = "0";
                let previousPlan = this.props.user.dependencies[0]
                  .coverage_details.previous;

                if (planSelectedLoc.header === "Existing") {
                  previousPlan.map(prevPlan => {
                    if (prevPlan.code === planSelectedLoc.coverageId) {
                      SA = prevPlan.sum_assured;
                    }
                  });
                } else {
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                }

                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                  parseFloat(rate).toFixed(2);
              }
            });
          }
        });
      } else {
        if (rateMatric.value == occupationClass.value) {
          rateMatric.details.map(detail => {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = 0;
                let SA;
                if (insuree === "Employee") {
                  rate = rule.employeeRate;
                  SA =
                    planSelectedLoc[1].details[0].overview.details[0]
                      .details[0];
                } else if (insuree === "SPOUSE") {
                  rate = rule.spouseRate;
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                } else if (insuree === "CHILD") {
                  rate = rule.childRate;
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                }
                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                    parseFloat(rate).toFixed(2)) /
                  1000;
              }
            });
          });
        }
      }
    });
    return tempPremium;
  };

  premiumInfoDependent = (coverage, plan, dependent) => {
    const isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let multiplier = 0;
    let subsidy = 0;
    let dob = this.props.user.dob;

    var a = moment(dob, "DD/MM/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    if (
      dependent === undefined ||
      dependent === "Dependants" ||
      plan === "Opt Out"
    )
      return 0.0;
    if (dependent && dependent.id) {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage[0]).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage[0] &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === "Individual");
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let premiumDetailsSub = this.state.dependentPlans
          .find(planObj => planObj.option === plan.option)
          .details.find(
            detail =>
              detail.header === coverage[0] && (multiplier = detail.multiplier)
          )
          .details.find(detail1 => detail1.header === "Individual");
        subsidy = premiumDetailsSub.subsidy;
        return premiumDetailsSub.premium * multiplier - subsidy;
      }
    } else {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage[0]).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage[0] &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === dependent);
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let planDetails = this.state.dependentPlans.find(
          planObj => planObj.option === plan.option
        );
        let detailMultiplier = planDetails.details.find(
          detail => detail.header === coverage[0]
        );
        multiplier = detailMultiplier.multiplier;
        let details = planDetails.details.find(
          detail => detail.header === coverage[0]
        );
        let premiumDetailsSub1 = details.details.find(
          detail1 => detail1.header === dependent
        );
        subsidy = premiumDetailsSub1.subsidy;
        return diffYears >= 71 && isPruAgencyMedEnrolment
          ? premiumDetailsSub1.premium71 * multiplier - subsidy
          : premiumDetailsSub1.premium * multiplier - subsidy;
      }
    }
  };

  premiumCoverageCalculation = (planSelectedE, planSelectedD) => {
    let planSelectedTemp = planSelectedE[0];
    let isVerticalPlan = this.state.plans.find(
      plan => plan.planType === planSelectedTemp[0]
    );
    let premiumInfoEmp =
      isVerticalPlan.isVertical === "true"
        ? this.premiumInfoVertical(
            this.props.user,
            planSelectedE[0],
            "Employee"
          )
        : this.premiumInfo(planSelectedE[0]);
    let premiumInfoDep = 0;
    planSelectedD[1] &&
      planSelectedD[1].map(plan => {
        premiumInfoDep =
          premiumInfoDep +
          (isVerticalPlan.isVertical === "true"
            ? this.premiumInfoVertical(
                plan.dependentType,
                plan.planDep,
                plan.dependentType.dependent
              )
            : this.premiumInfoDependent(
                planSelectedD,
                plan.planDep,
                plan.dependentType.full_name ? "Individual" : plan.dependentType
              ));
      });
    return premiumInfoEmp + premiumInfoDep;
  };

  premiumCalculation = planSelected => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let sumPremium = 0;
    let coverageName;
    let planSelectedArray = Array.from(planSelected);
    planSelectedArray.map(selectedPlans => {
      let isVerticalPlan = this.state.plans.find(
        plan => plan.planType === selectedPlans[0]
      );
      if (isVerticalPlan.isVertical === "true") {
        var a = moment(this.props.user.dob, "D/M/YYYY");
        var b = moment();
        let diffDays = b.diff(a, "years");
        let occupationClass = this.props.occupationToClassValue(
          this.props.user.occupation
        );

        if (isPruAgencyEnrolment === true) {
          let user = this.props.user;
          let previousPlan = user.coverage_details.previous;
          let gender = this.props.user.gender;
          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans[1].coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate =
                        gender === "M" ? rule.maleRate : rule.femaleRate;
                      let SA = "0";
                      let healthLoading = "0";

                      if (selectedPlans[1].header === "Existing") {
                        previousPlan.map(prevPlan => {
                          if (selectedPlans[1].coverageId === prevPlan.code) {
                            SA = prevPlan.sum_assured;
                            healthLoading = prevPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                parseFloat(rate).toFixed(2)) /
                                1000;
                          }
                        });
                      } else if (selectedPlans[1].header === "VECI") {
                        previousPlan.map(prevPlan => {
                          if (prevPlan.code === "VCCA") {
                            SA = prevPlan.sum_assured;
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                0.3 *
                                parseFloat(rate).toFixed(2)) /
                                1000;
                          }
                        });
                      } else {
                        previousPlan.map(prevPlan => {
                          if (selectedPlans[1].coverageId === prevPlan.code) {
                            healthLoading = prevPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                          }
                        });
                        SA =
                          selectedPlans[1].details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            parseFloat(rate).toFixed(2)) /
                            1000;
                      }
                    }
                  });
                }
              });
            }
          });
        } else if (this.state.isPruAgencyMedEnrolment) {
          let user = this.props.user;
          let previousPlan = user.coverage_details.previous;
          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans[1].coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate = rule.rate;
                      let SA = "0";
                      if (selectedPlans[1].header === "Existing") {
                        previousPlan.map(prevPlan => {
                          if (selectedPlans[1].coverageId === prevPlan.code) {
                            SA = prevPlan.sum_assured;
                            sumPremium =
                              sumPremium +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                                1000) *
                                parseFloat(rate).toFixed(2);
                          }
                        });
                      } else {
                        SA =
                          selectedPlans[1].details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                            parseFloat(rate).toFixed(2);
                      }
                    }
                  });
                }
              });
            }
          });
        } else {
          this.state.rateMatrics.map(rateMatric => {
            if (rateMatric.value == occupationClass.value) {
              rateMatric.details.map(detail => {
                detail.rules.map(rule => {
                  if (
                    rule.ageBand[0] <= diffDays &&
                    diffDays <= rule.ageBand[1]
                  ) {
                    let rate = rule.employeeRate;
                    let SA =
                      selectedPlans[1].details[0].overview.details[0]
                        .details[0];
                    sumPremium =
                      sumPremium +
                      (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                        parseFloat(rate).toFixed(2)) /
                        1000;
                  }
                });
              });
            }
          });
        }
      } else {
        this.state.dependentPlans.map(premiumPlans => {
          coverageName = selectedPlans[0];
          var a = moment(this.props.user.dob, "D/M/YYYY");
          var b = moment();
          if (
            this.props.user.client_name.config
              .dobValidationByPolicyStartDate === "true"
          ) {
            b = moment(
              this.props.user.client_name.policy_start_date,
              "YYYY-MM-DD"
            );
          }
          let diffYears = b.diff(a, "years");

          if (selectedPlans[1].option === premiumPlans.option) {
            let premiumDetails = premiumPlans.details.find(
              plan => plan.header === selectedPlans[0]
            );
            let premiumDetailsSub =
              premiumDetails &&
              premiumDetails.details.find(obj => obj.header === "Employee");
            let subsidy = premiumDetailsSub && premiumDetailsSub.subsidy;
            let categorySubTemp = this.getCategorySubsidy(
              this.getCoverageIdByName(coverageName)
            );
            if (Array.isArray(premiumDetails.multiplier)) {
              for (
                let count = 0;
                count < premiumDetails.multiplier.length;
                count++
              ) {
                let tempPremium =
                  premiumDetailsSub &&
                  premiumDetailsSub.premium[count] *
                    premiumDetails.multiplier[count] -
                    premiumDetailsSub.subsidy;
                sumPremium = sumPremium + tempPremium;
              }
            } else {
              let tempPremium =
                premiumDetailsSub && isPruAgencyMedEnrolment && diffYears >= 71
                  ? premiumDetailsSub.premium71 * premiumDetails.multiplier -
                    premiumDetailsSub.subsidy
                  : premiumDetailsSub.premium * premiumDetails.multiplier -
                    premiumDetailsSub.subsidy;
              sumPremium = sumPremium + tempPremium;
            }
            sumPremium = sumPremium - categorySubTemp;
          }
        });
      }
    });
    this.state.totalPremium = this.state.totalPremium + sumPremium;
    return sumPremium;
  };

  premiumCalculationDependent = planSelectedDependent => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let key = planSelectedDependent[0];
    let sumPremium = 0;
    let planSelectedDependentArray = Array.from(planSelectedDependent);
    let coverage = planSelectedDependentArray[0];
    let planSelected = planSelectedDependentArray[1];
    let previousPlanDep =
      this.props.user.dependencies.length > 0
        ? this.props.user.dependencies[0].coverage_details.previous
        : [];
    let premiumEmp = 0;
    planSelected &&
      planSelected.map(selectedPlans => {
        if (selectedPlans.planDep === "Opt Out") return sumPremium;
        let isVerticalPlan = this.state.plans.find(
          plan => plan.planType === key
        );
        if (isVerticalPlan.isVertical === "true") {
          var a = moment(
            selectedPlans && selectedPlans.dependentType.dob,
            "D/M/YYYY"
          );
          var b = moment();
          let diffDays = b.diff(a, "years");
          let occupationClass = this.props.occupationToClassValue(
            selectedPlans && selectedPlans.dependentType.occupation
          );

          if (isPruAgencyEnrolment === true) {
            let gender = this.props.user.dependencies[0].gender;

            this.state.rateMatrics.map(rateMatric => {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans.planDep.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate =
                        gender === "M" ? rule.maleRate : rule.femaleRate;
                      let SA = "0";
                      let healthLoading = "0";

                      if (selectedPlans.planDep.header === "Existing") {
                        previousPlanDep.map(prevPlan => {
                          if (
                            selectedPlans.planDep.coverageId === prevPlan.code
                          ) {
                            SA = prevPlan.sum_assured;
                            healthLoading = prevPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                parseFloat(rate).toFixed(2)) /
                                1000;
                          }
                        });
                      } else if (selectedPlans.planDep.header === "VECI") {
                        previousPlanDep.map(prevPlan => {
                          if (prevPlan.code === "VCCA") {
                            SA = prevPlan.sum_assured;
                          }
                        });
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            0.3 *
                            parseFloat(rate).toFixed(2)) /
                            1000;
                      } else {
                        previousPlanDep.map(prevPlan => {
                          if (
                            selectedPlans.planDep.coverageId === prevPlan.code
                          ) {
                            healthLoading = prevPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                          }
                        });
                        SA =
                          selectedPlans.planDep.details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            parseFloat(rate).toFixed(2)) /
                            1000;
                      }
                    }
                  });
                }
              });
            });
          } else if (this.state.isPruAgencyMedEnrolment) {
            this.state.rateMatrics.map(rateMatric => {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans.planDep.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate = rule.rate;
                      let SA = "0";

                      if (selectedPlans.planDep.header === "Existing") {
                        previousPlanDep.map(prevPlan => {
                          if (
                            selectedPlans.planDep.coverageId === prevPlan.code
                          ) {
                            SA = prevPlan.sum_assured;
                            sumPremium =
                              sumPremium +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                                1000) *
                                parseFloat(rate).toFixed(2);
                          }
                        });
                      } else {
                        SA =
                          selectedPlans.planDep.details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                            parseFloat(rate).toFixed(2);
                      }
                    }
                  });
                }
              });
            });
          } else {
            this.state.rateMatrics.map(rateMatric => {
              if (rateMatric.value == occupationClass.value) {
                rateMatric.details.map(detail => {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate;
                      if (selectedPlans.dependentType.dependent === "CHILD")
                        rate = rule.childRate;
                      if (selectedPlans.dependentType.dependent === "SPOUSE")
                        rate = rule.spouseRate;
                      let SA =
                        selectedPlans.planDep.details[0].overview.details[0]
                          .details[0];
                      sumPremium =
                        sumPremium +
                        (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                          parseFloat(rate).toFixed(2)) /
                          1000;
                    }
                  });
                });
              }
            });
          }
        } else {
          this.state.dependentPlans.map(premiumPlans => {
            let dob = this.props.user.dob;

            var a = moment(dob, "DD/MM/YYYY");
            var b = moment();
            if (
              this.props.user.client_name.config
                .dobValidationByPolicyStartDate === "true"
            ) {
              b = moment(
                this.props.user.client_name.policy_start_date,
                "YYYY-MM-DD"
              );
            }
            let diffYears = b.diff(a, "years");

            if (
              selectedPlans.planDep.option &&
              selectedPlans.planDep.option === premiumPlans.option
            ) {
              let premiumDetails = premiumPlans.details.find(
                plan => plan.header === coverage
              );
              let premiumDetailsSub =
                premiumDetails &&
                premiumDetails.details.find(
                  premium =>
                    premium.header ===
                    (selectedPlans.dependentType.full_name
                      ? "Individual"
                      : selectedPlans.dependentType)
                );
              let subsidy = premiumDetailsSub.subsidy;
              if (Array.isArray(premiumDetails.multiplier)) {
                for (
                  let count = 0;
                  count < premiumDetails.multiplier.length;
                  count++
                ) {
                  let tempPremium =
                    premiumDetailsSub.premium[count] *
                      premiumDetails.multiplier[count] -
                    premiumDetailsSub.subsidy;
                  sumPremium = sumPremium + tempPremium;
                }
              } else {
                let multiplier = 0;
                let tempPremiumSub = premiumPlans.details
                  .find(
                    plan =>
                      plan.header === coverage && (multiplier = plan.multiplier)
                  )
                  .details.find(
                    premium =>
                      premium.header ===
                      (selectedPlans.dependentType.full_name
                        ? "Individual"
                        : selectedPlans.dependentType)
                  );
                let subsidy = tempPremiumSub.subsidy;
                let tempPremium =
                  this.state.isPruAgencyMedEnrolment && diffYears >= 71
                    ? tempPremiumSub.premium71 * multiplier - subsidy
                    : tempPremiumSub.premium * multiplier - subsidy;
                sumPremium = sumPremium + tempPremium;
              }
            } else if (selectedPlans.planDep === "Opt Out") {
              sumPremium = sumPremium + 0;
            }
          });
        }
      });
    this.state.totalPremium = this.state.totalPremium + sumPremium + premiumEmp;
    return sumPremium + premiumEmp;
  };

  premiumCalculationTotal = () => {
    let employeePremium = this.premiumCalculation(this.state.planSelected);
    let dependentPremium = 0;
    let sumPremium = 0;
    Array.from(this.state.planSelectedDependent).map(planSelectedDependent1 => {
      dependentPremium =
        dependentPremium +
        this.premiumCalculationDependent(planSelectedDependent1);
    });
    sumPremium = employeePremium + dependentPremium;
    return sumPremium;
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  formatContent = (string, argList) => {
    argList.forEach(arg => {
      string = string.replace(arg[0], arg[1]);
    });
    return string;
  };

  handleChangeRadio = event => {
    let payment = event.target.value;
    this.setState(
      {
        ...this.state,
        payment: payment
      },
      () => {
        this.props.handleNextEnable(true);
        this.props.paymentMethod(payment);
      }
    );
  };

  getDocumentStatus() {
    const kofaxCheckInterval = setInterval(async () => {
      this.props.getKofaxDocumentStatus(this.props.hdf.packageId);
    }, 3000);
    this.setState({ kofaxCheckInterval });
  }

  componentDidMount() {
    if (this.state.payment === "") {
      this.props.handleNextEnable(false);
    }
    if (this.props.hdf.hasHDF === true) {
      this.getDocumentStatus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hdf.CMDocumentName) {
      clearInterval(this.state.kofaxCheckInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.kofaxCheckInterval);
  }

  renderMf(mf) {
    if (mf) {
      return mf;
    }
    return "";
  }

  render() {
    const { labels } = this.props.user.content;
    const { placeholderMap } = this.props.user;
    const mf = new MessageFormat("en");
    const enrolmentYear = mf.compile(this.renderMf(labels["step4.header"]));
    const enrolmentStartEnd = mf.compile(
      this.renderMf(labels["step4.description"])
    );
    const consentHeader = mf.compile(
      this.renderMf(labels["step4.consent.header"])
    );
    const paymentHeader = mf.compile(
      this.renderMf(labels["step4.consent.payment.header"])
    );
    const paymentDesc = mf.compile(
      this.renderMf(labels["step4.consent.payment.description"])
    );
    const declarationHeader = mf.compile(
      this.renderMf(labels["step4.declaration.header"])
    );
    const declarationDesc = mf.compile(
      this.renderMf(labels["step4.declaration.description"])
    );
    const note = mf.compile(this.renderMf(labels["step4.note"]));
    const acknowledgeHeader = mf.compile(
      this.renderMf(labels["step4.acknowledge.header"])
    );
    const acknowledgeFooter = mf.compile(
      this.renderMf(labels["step4.acknowledge.footer"])
    );
    const hdfConsentParagraph = mf.compile(
      this.renderMf(labels["step4.hdf.consent"])
    );
    const placeholderMapTemp = new Map(placeholderMap);
    const { classes, user } = this.props;
    let employeeRequiredHDF = false;
    let spouseRequiredHDF = false;
    let childRequiredHDF = false;

    const { expanded, plans } = this.state;
    let countOptOut = 0;
    const isFCPayment =
      this.state.isPruAgencyMedEnrolment ||
      user.client_name.config.isPruAgencyEnrolment
        ? true
        : false;
    let depPremiumSA = new Map(this.props.user.depPremiumSA);
    let empPremiumSA = new Map(this.props.user.empPremiumSA);

    if (this.props.hdf.users) {
      this.props.hdf.users.map(hdfUser => {
        if (hdfUser.person_type === "EMPLOYEE") {
          employeeRequiredHDF = true;
        } else if (hdfUser.person_type === "SPOUSE") {
          spouseRequiredHDF = true;
        } else {
          childRequiredHDF = true;
        }
      });
    }

    return (
      <div className={classes.rootInfo}>
        <div className={classes.contentInfo}>
          <div>
            <div style={{ padding: "0px 20px" }}>
              <div className={classes.headerInfo}>
                <Typography
                  component={"span"}
                  className={classes.headerText}
                  dangerouslySetInnerHTML={{
                    __html: enrolmentYear({
                      year: placeholderMapTemp.get("{year}")
                    })
                  }}
                />
              </div>
            </div>
            {
              <div
                className={classes.headerInfo}
                style={{ paddingBottom: "0px" }}
              >
                {Array.from(this.state.planSelectedDependent).map(
                  (planCovered, kepPlanCovered) => {
                    let planCoveredTemp = planCovered[1];
                    let premiumValueEmp = new Map(
                      empPremiumSA.get(planCovered[0])
                    );
                    let premiumValueEmpGMM = new Map(
                      empPremiumSA.get(
                        "Group Extended Major Medical Rider (GMM) Benefits"
                      )
                    );
                    let premiumValueDep = new Map(
                      depPremiumSA.get(planCovered[0])
                    );
                    let isGADDPlan = false;

                    premiumValueEmpGMM =
                      premiumValueEmpGMM.size !== 0
                        ? premiumValueEmpGMM
                            .get(user.id)
                            .premium.replace(/,/g, "")
                        : "0";

                    if (
                      this.state.isPruAgencyMedEnrolment &&
                      planCovered[0] ===
                        "Group Accidental Death & Dismemberment (GADD) Benefits"
                    ) {
                      isGADDPlan = true;
                    }

                    return (
                      <div
                        style={{ padding: "0px 20px 40px 20px" }}
                        key={kepPlanCovered}
                      >
                        <div style={{ background: "#F2F2F2", padding: "10px" }}>
                          <Typography
                            component={"span"}
                            className={classes.headerText}
                            style={{ color: "black" }}
                          >
                            {planCovered[0]}
                          </Typography>
                        </div>
                        <Grid
                          container
                          spacing={"8px"}
                          key={kepPlanCovered}
                          style={{ padding: "0px 10px" }}
                        >
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.planText}
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                {planCoveredTemp[0] &&
                                planCoveredTemp[0].dependentType &&
                                planCoveredTemp[0].dependentType.full_name
                                  ? "Individual"
                                  : "Group"}{" "}
                              </Typography>
                            </div>
                          </Grid>
                          {this.state.isPruAgencyMedEnrolment && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextLabel}
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.planText}
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Plan
                              </Typography>
                            </div>
                          </Grid>
                          {this.props.user.underwriting_required && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.planText}
                                  style={{ color: "#000000", fontWeight: 600 }}
                                >
                                  Sum Assured
                                </Typography>
                              </div>
                            </Grid>
                          )}
                          {(this.props.user.client_name.config
                            .isPruAgencyEnrolment === "True" ||
                            isGADDPlan) && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.planText}
                                  style={{ color: "#000000", fontWeight: 600 }}
                                >
                                  Sum Assured
                                </Typography>
                              </div>
                            </Grid>
                          )}
                          {this.props.user.client_name.config
                            .isPruAgencyEnrolment === "True" && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.planText}
                                  style={{ color: "#000000", fontWeight: 600 }}
                                >
                                  Health Loading
                                </Typography>
                              </div>
                            </Grid>
                          )}
                          {this.state.isPruAgencyMedEnrolment && !isGADDPlan && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextLabel}
                                />
                              </div>
                            </Grid>
                          )}
                          {this.hidePremiumsCheck(
                            this.getCoverageIdByName(planCovered[0])
                          ) !== "true" &&
                            this.hidePremiumsCheck(
                              this.getCoverageIdByName(planCovered[0])
                            ) === undefined && (
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                    style={{
                                      color: "#000000",
                                      fontWeight: 600
                                    }}
                                  >
                                    Premium
                                  </Typography>
                                </div>
                              </Grid>
                            )}
                        </Grid>
                        {this.employeeEnrolmentCheck(planCovered[0]) &&
                          this.employeeEnrolmentCheck(planCovered[0])
                            .employeeEnrole !== "false" &&
                          Array.from(this.state.planSelected)
                            .filter(planEmp => planEmp[0] === planCovered[0])
                            .map((planCoveredE, kepPlanCovered) => {
                              return (
                                <Grid
                                  container
                                  spacing={"8px"}
                                  key={kepPlanCovered}
                                  style={{ padding: "0px 10px" }}
                                >
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {`${user.fullName} (Self)`}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.state.isPruAgencyMedEnrolment && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.premiumTextLabel}
                                        />
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {planCoveredE[1] === "Opt Out"
                                          ? planCoveredE[1]
                                          : this.state
                                              .isPruAgencyMedEnrolment &&
                                            !isGADDPlan
                                          ? planCoveredE[1].header.substring(5)
                                          : planCoveredE[1].header}
                                        {/* {planCoveredE[1].header} */}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.props.user.underwriting_required && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {planCoveredE[1] === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueEmp.get(user.id).SA
                                              }`
                                          // `S$${planCoveredE[1].details[0].overview.details[0].details[0]}`
                                          }
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {(this.props.user.client_name.config
                                    .isPruAgencyEnrolment ||
                                    isGADDPlan) && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {planCoveredE[1] === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueEmp.get(user.id).SA
                                              }`
                                          // `S$${planCoveredE[1].details[0].overview.details[0].details[0]}`
                                          }
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.state.isPruAgencyMedEnrolment &&
                                    !isGADDPlan && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.premiumTextLabel}
                                          />
                                        </div>
                                      </Grid>
                                    )}
                                  {this.props.user.client_name.config
                                    .isPruAgencyEnrolment && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {planCoveredE[1] === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueEmp.get(user.id)
                                                  .extraPremium
                                              }`
                                          // `S$${planCoveredE[1].details[0].overview.details[0].details[0]}`
                                          }
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.hidePremiumsCheck(
                                    this.getCoverageIdByName(planCovered[0])
                                  ) !== "true" &&
                                    this.hidePremiumsCheck(
                                      this.getCoverageIdByName(planCovered[0])
                                    ) === undefined && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.planText}
                                          >
                                            S$
                                            {plans.find(
                                              plan =>
                                                plan.planType === planCovered[0]
                                            ).isVertical === "true"
                                              ? planCoveredE[1] !== "Opt Out"
                                                ? parseFloat(
                                                    premiumValueEmp
                                                      .get(user.id)
                                                      .premium.replace(/,/g, "")
                                                  )
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : "0.00"
                                              : // ? parseFloat(this.premiumInfoVertical(user,planCoveredE,"Employee")).toFixed(2)
                                                parseFloat(
                                                  this.premiumInfo(planCoveredE)
                                                ).toFixed(2)}
                                            {user.client_name.config
                                              .hideAnnual != "True"
                                              ? "/year "
                                              : ""}
                                          </Typography>
                                        </div>
                                      </Grid>
                                    )}
                                </Grid>
                              );
                            })}
                        {planCovered[1] &&
                          planCovered[1]
                            .filter(
                              plan => plan.dependentType.dependent === "SPOUSE"
                            )
                            .sort((a, b) =>
                              a.dependentType.full_name.localeCompare(
                                b.dependentType.full_name
                              )
                            )
                            .map((plan, kepPlan) => {
                              return (
                                <Grid
                                  container
                                  spacing={"8px"}
                                  key={kepPlan}
                                  style={{ padding: "0px 10px" }}
                                >
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.dependentType.full_name
                                          ? `${
                                              plan.dependentType.full_name
                                            } (${this.capitalizeFLetter(
                                              plan.dependentType.dependent
                                            )})`
                                          : plan.dependentType}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.state.isPruAgencyMedEnrolment && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.premiumTextLabel}
                                        />
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.planDep === "Opt Out"
                                          ? plan.planDep
                                          : plan.planDep.header}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.props.user.underwriting_required && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {plan.planDep === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).SA
                                              }`}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {(this.props.user.client_name.config
                                    .isPruAgencyEnrolment ||
                                    isGADDPlan) && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {plan.planDep === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).SA
                                              }`}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.props.user.client_name.config
                                    .isPruAgencyEnrolment && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {plan.planDep === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).extraPremium
                                              }`}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.state.isPruAgencyMedEnrolment &&
                                    !isGADDPlan && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.premiumTextLabel}
                                          />
                                        </div>
                                      </Grid>
                                    )}
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plans.find(
                                          plan =>
                                            plan.planType === planCovered[0]
                                        ).isVertical === "true"
                                          ? plan.planDep != "Opt Out"
                                            ? `S$${parseFloat(
                                                premiumValueDep
                                                  .get(plan.dependentType.id)
                                                  .premium.replace(/,/g, "")
                                              )
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}/year`
                                            : "S$0.00/year"
                                          : plan.planDep != "Opt Out"
                                          ? premiumValueDep.get(
                                              plan.dependentType.id
                                            ).premium == "0.00"
                                            ? this.props.user.client_name.config
                                                .waiverAllow !== undefined &&
                                              this.props.user.client_name.config
                                                .waiverAllow === "True"
                                              ? "Waived"
                                              : `S$0.00${
                                                  user.client_name.config
                                                    .hideAnnual != "True"
                                                    ? "/year "
                                                    : ""
                                                }`
                                            : // waived only one waiver is allow
                                              `S$${parseFloat(
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).premium
                                              ).toFixed(2)}${
                                                user.client_name.config
                                                  .hideAnnual != "True"
                                                  ? "/year "
                                                  : ""
                                              }`
                                          : `S$0.00${
                                              user.client_name.config
                                                .hideAnnual != "True"
                                                ? "/year "
                                                : ""
                                            }`
                                        // `S$${parseFloat(premiumValueDep.get(plan.dependentType.id).premium).toFixed(2) }/year`
                                        }
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        {planCovered[0] !==
                          "Group Accidental Death & Dismemberment (GADD) Benefits" &&
                          planCovered[1] &&
                          planCovered[1]
                            .filter(
                              plan => plan.dependentType.dependent === "CHILD"
                            )
                            .sort((a, b) =>
                              a.dependentType.full_name.localeCompare(
                                b.dependentType.full_name
                              )
                            )
                            .map((plan, kepPlan) => {
                              return (
                                <Grid
                                  container
                                  spacing={"8px"}
                                  key={kepPlan}
                                  style={{ padding: "0px 10px" }}
                                >
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.dependentType.full_name
                                          ? `${
                                              plan.dependentType.full_name
                                            } (${this.capitalizeFLetter(
                                              plan.dependentType.dependent
                                            )})`
                                          : plan.dependentType}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.planDep === "Opt Out"
                                          ? plan.planDep
                                          : plan.planDep.header}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.props.user.underwriting_required && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {plan.planDep === "Opt Out"
                                            ? "N/A"
                                            : `S$${
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).SA
                                              }`}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plans.find(
                                          plan =>
                                            plan.planType === planCovered[0]
                                        ).isVertical === "true"
                                          ? plan.planDep != "Opt Out"
                                            ? `S$${parseFloat(
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).premium
                                              ).toFixed(2)}${
                                                user.client_name.config
                                                  .hideAnnual != "True"
                                                  ? "/year "
                                                  : ""
                                              }`
                                            : `S$0.00${
                                                user.client_name.config
                                                  .hideAnnual != "True"
                                                  ? "/year "
                                                  : ""
                                              }`
                                          : plan.planDep != "Opt Out"
                                          ? premiumValueDep.get(
                                              plan.dependentType.id
                                            ).premium == "0.00"
                                            ? this.props.user.client_name.config
                                                .waiverAllow !== undefined &&
                                              this.props.user.client_name.config
                                                .waiverAllow === "True"
                                              ? "Waived"
                                              : `S$0.00${
                                                  user.client_name.config
                                                    .hideAnnual != "True"
                                                    ? "/year "
                                                    : ""
                                                }`
                                            : // waived only one waiver is allow
                                              `S$${parseFloat(
                                                premiumValueDep.get(
                                                  plan.dependentType.id
                                                ).premium
                                              ).toFixed(2)}${
                                                user.client_name.config
                                                  .hideAnnual != "True"
                                                  ? "/year "
                                                  : ""
                                              }`
                                          : `S$0.00${
                                              user.client_name.config
                                                .hideAnnual != "True"
                                                ? "/year "
                                                : ""
                                            }`
                                        //`S$${parseFloat(premiumValueDep.get(plan.dependentType.id).premium).toFixed(2) }/year`
                                        }
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        {planCovered[1] &&
                          planCovered[1]
                            .filter(
                              plan =>
                                plan.dependentType === "Spouse Only" ||
                                plan.dependentType === "Child(ren) Only" ||
                                plan.dependentType === "Spouse & Child(ren)" ||
                                plan.dependentType === "Dependants"
                            )
                            .map((plan, kepPlan) => {
                              return (
                                <Grid
                                  container
                                  spacing={"8px"}
                                  key={kepPlan}
                                  style={{ padding: "0px 10px" }}
                                >
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.dependentType.full_name
                                          ? `${
                                              plan.dependentType.full_name
                                            } (${this.capitalizeFLetter(
                                              plan.dependentType.dependent
                                            )})`
                                          : plan.dependentType}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.state.isPruAgencyMedEnrolment &&
                                    !isGADDPlan && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.premiumTextLabel}
                                          />
                                        </div>
                                      </Grid>
                                    )}
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plan.planDep === "Opt Out"
                                          ? plan.planDep
                                          : this.state.isPruAgencyMedEnrolment
                                          ? plan.planDep.header.substring(5)
                                          : plan.planDep.header}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  {this.props.user.underwriting_required && (
                                    <Grid item xs sm md>
                                      <div style={{ padding: "10px 0px" }}>
                                        <Typography
                                          component={"span"}
                                          className={classes.planText}
                                        >
                                          {plan.planDep === "Opt Out"
                                            ? "N/A"
                                            : `S$${plan.planDep.details[0].overview.details[0].details[0]}`}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.state.isPruAgencyMedEnrolment &&
                                    !isGADDPlan && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.premiumTextLabel}
                                          />
                                        </div>
                                      </Grid>
                                    )}
                                  {this.hidePremiumsCheck(
                                    this.getCoverageIdByName(planCovered[0])
                                  ) !== "true" &&
                                    this.hidePremiumsCheck(
                                      this.getCoverageIdByName(planCovered[0])
                                    ) === undefined &&
                                    plan.planDep !== "Opt Out" && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.planText}
                                          >
                                            {plans.find(
                                              plan =>
                                                plan.planType === planCovered[0]
                                            ).isVertical === "true"
                                              ? `S$${parseFloat(
                                                  premiumValueDep.get(
                                                    plan.dependentType
                                                  ).premium
                                                ).toFixed(2)}${
                                                  user.client_name.config
                                                    .hideAnnual != "True"
                                                    ? "/year "
                                                    : ""
                                                }`
                                              : plan.planDep != "Opt Out"
                                              ? `S$${parseFloat(
                                                  premiumValueDep.get(
                                                    plan.dependentType
                                                  ).premium
                                                ).toFixed(2)}${
                                                  user.client_name.config
                                                    .hideAnnual != "True"
                                                    ? "/year "
                                                    : ""
                                                }`
                                              : `S$0.00${
                                                  user.client_name.config
                                                    .hideAnnual != "True"
                                                    ? "/year "
                                                    : ""
                                                }`
                                            // `S$${parseFloat(premiumValueDep.get(plan.dependentType).premium).toFixed(2) }/year`
                                            }
                                          </Typography>
                                        </div>
                                      </Grid>
                                    )}
                                  {this.hidePremiumsCheck(
                                    this.getCoverageIdByName(planCovered[0])
                                  ) !== "true" &&
                                    this.hidePremiumsCheck(
                                      this.getCoverageIdByName(planCovered[0])
                                    ) === undefined &&
                                    plan.planDep === "Opt Out" && (
                                      <Grid item xs sm md>
                                        <div style={{ padding: "10px 0px" }}>
                                          <Typography
                                            component={"span"}
                                            className={classes.planText}
                                          >
                                            {`S$0.00${
                                              user.client_name.config
                                                .hideAnnual != "True"
                                                ? "/year "
                                                : ""
                                            }`}
                                          </Typography>
                                        </div>
                                      </Grid>
                                    )}
                                </Grid>
                              );
                            })}
                        <div
                          className={classes.premiumBlock}
                          style={{ padding: 0 }}
                        >
                          {this.hidePremiumsCheck(
                            this.getCoverageIdByName(planCovered[0])
                          ) !== "true" &&
                            this.hidePremiumsCheck(
                              this.getCoverageIdByName(planCovered[0])
                            ) === undefined && (
                              <Grid
                                container
                                spacing={"8px"}
                                style={{ padding: "0px 10px" }}
                              >
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.premiumTextLabel}
                                    >
                                      {user.client_name.config.hideAnnual !=
                                      "True"
                                        ? "Annual "
                                        : ""}
                                      Premium
                                    </Typography>
                                  </div>
                                </Grid>
                                {this.state.isPruAgencyMedEnrolment && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.premiumTextLabel}
                                      />
                                    </div>
                                  </Grid>
                                )}
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.premiumTextLabel}
                                    />
                                  </div>
                                </Grid>
                                {this.props.user.client_name.config
                                  .isPruAgencyEnrolment === "True" && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.premiumTextLabel}
                                      />
                                    </div>
                                  </Grid>
                                )}
                                {(this.props.user.client_name.config
                                  .isPruAgencyEnrolment === "True" ||
                                  this.state.isPruAgencyMedEnrolment) && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.premiumTextLabel}
                                      />
                                    </div>
                                  </Grid>
                                )}
                                {this.props.user.underwriting_required && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.premiumTextLabel}
                                      />
                                    </div>
                                  </Grid>
                                )}
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.premiumTextValue}
                                      style={{ float: "none", fontWeight: 600 }}
                                    >
                                      S$
                                      {parseFloat(
                                        this.premiumCoverageCalculation(
                                          Array.from(
                                            this.state.planSelected
                                          ).filter(
                                            planEmp =>
                                              planEmp[0] === planCovered[0]
                                          ),
                                          planCovered
                                        )
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      {user.client_name.config.hideAnnual !=
                                      "True"
                                        ? "/year "
                                        : ""}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            }
            {Array.from(this.state.planSelectedDependent).length > 1 && (
              <div className={classes.headerInfo} style={{ paddingTop: "0px" }}>
                {this.hidePremiumsCheck("GHSB") !== "true" &&
                  this.hidePremiumsCheck("GHSB") === undefined && (
                    <div
                      className={classes.premiumBlockTotal}
                      style={{ padding: "25px 0px 0px 0px" }}
                    >
                      <Grid
                        container
                        spacing={"8px"}
                        style={{ padding: "0px 30px" }}
                      >
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.premiumTextLabel}
                            >
                              Total Annual Premium
                            </Typography>
                          </div>
                        </Grid>
                        {this.state.isPruAgencyMedEnrolment && (
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.premiumTextLabel}
                              />
                            </div>
                          </Grid>
                        )}
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.premiumTextLabel}
                            />
                          </div>
                        </Grid>
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.premiumTextLabel}
                            />
                          </div>
                        </Grid>
                        {this.props.user.underwriting_required && (
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.premiumTextLabel}
                              />
                            </div>
                          </Grid>
                        )}
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.premiumTextLabel}
                              style={{ float: "none", fontWeight: 600 }}
                            >
                              S$
                              {parseFloat(this.premiumCalculationTotal())
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              {user.client_name.config.hideAnnual != "True"
                                ? "/year "
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
              </div>
            )}
            {this.props.hdf.users.length > 0 && (
              <Box>
                <HDFDeclarationSummary
                  user={this.props.hdf.users}
                  userData={this.props.user}
                />
                <MyDropzone
                  handleChildData={data => this.setState({ documents: data })}
                />
              </Box>
            )}
            {this.props.isPaymentEnabled && (
              <div
                className={classes.headerInfo}
                style={{ paddingBottom: "0px" }}
              >
                <div style={{ padding: "0px 20px 20px 20px" }}>
                  <div style={{ background: "#F2F2F2", padding: "10px" }}>
                    <Typography
                      component={"span"}
                      className={classes.headerText}
                      style={{ color: "black" }}
                    >
                      Select Your Payment Method
                    </Typography>
                  </div>
                  {user.client_name.config.isPruAgencyEnrolment !== "True" &&
                    !this.state.isPruAgencyMedEnrolment && (
                      <div style={{ paddingTop: "10px" }}>
                        <Typography
                          component={"span"}
                          className={classes.planText}
                        >
                          <Radio
                            checked={this.state.payment === "Payroll"}
                            onChange={event => this.handleChangeRadio(event)}
                            value="Payroll"
                            name="radio-button-demo"
                            aria-label="Yes"
                            color="primary"
                          />
                          Payroll
                        </Typography>
                        <Typography
                          component={"span"}
                          className={classes.planText}
                        >
                          <Radio
                            checked={this.state.payment === "HEAL$"}
                            onChange={event => this.handleChangeRadio(event)}
                            value="HEAL$"
                            name="radio-button-demo"
                            aria-label="No"
                            color="primary"
                          />
                          HEAL$
                        </Typography>
                      </div>
                    )}
                  {isFCPayment && (
                    <div style={{ paddingTop: "10px" }}>
                      {user.designation === "FC" && (
                        <Typography
                          component={"span"}
                          className={classes.planText}
                        >
                          <Radio
                            checked={this.state.payment === "Commission"}
                            onChange={event => this.handleChangeRadio(event)}
                            value="Commission"
                            name="radio-button-demo"
                            aria-label="Yes"
                            color="primary"
                          />
                          Commission Deduction (October, November, December)
                        </Typography>
                      )}
                      <Typography
                        component={"span"}
                        className={classes.planText}
                      >
                        <Radio
                          checked={this.state.payment === "Cheque or Bank"}
                          onChange={event => this.handleChangeRadio(event)}
                          value="Cheque or Bank"
                          name="radio-button-demo"
                          aria-label="No"
                          color="primary"
                        />
                        Cheque or Bank Transfer by 30 September 2024
                      </Typography>
                    </div>
                  )}
                  {user.client_name.config.isPruAgencyEnrolment !== "True" &&
                    !this.state.isPruAgencyMedEnrolment && (
                      <div className={classes.noteBlockPayment}>
                        <Typography
                          component={"span"}
                          className={classes.noteHead}
                          style={{ fontWeight: "bold" }}
                        >
                          Payment Instructions
                        </Typography>
                        <Typography
                          component={"span"}
                          className={classes.noteHead}
                        >
                          You may choose one of the payment modes above for the
                          premiums of the Voluntary GTL as part of the enrolment
                          process.
                          <br />
                          <br />
                          If there is insufficient HEAL$ to deduct the total
                          premiums (for all parties enrolled for the Voluntary
                          GTL coverage), the amount will be deducted from
                          payroll instead. For such cases, you are deemed to
                          have given consent to deduct the premiums from your
                          payroll.
                          <br />
                          <br />
                          There will be no partial deductions from both HEAL$
                          and payroll.
                          <br />
                          <br />
                          If the premiums are not paid due to insufficient HEAL$
                          or unsuccessful payroll deduction, the Voluntary GTL
                          coverage will lapse and you will be informed by
                          Prudential accordingly.
                        </Typography>
                      </div>
                    )}
                  {isFCPayment && (
                    <div className={classes.noteBlockPayment}>
                      <Typography
                        component={"span"}
                        className={classes.noteHead}
                        style={{ fontWeight: "bold" }}
                      >
                        Payment Instructions
                      </Typography>
                      <Typography
                        component={"span"}
                        className={classes.noteHead}
                      >
                        1. I hereby authorise Prudential to make deduction(s)
                        from my commission for the annual premium required if I
                        have selected "Commission Deduction" as my Payment
                        method above.
                        <br />
                        2. Premium payment will be made via bank transfer by 30
                        September 2023 if I have selected "Cheque/Bank Transfer"
                        as my payment method above.
                        <br />
                        <br />
                        <b>Payment through commission deduction:</b> Please
                        ensure sufficient funds in your commission account to
                        cover deduction in October, November, December 2024.
                        <br />
                        <br />
                        <b>Payment via Bank/FAST transfer:</b> Make payment and
                        notify sgp.es.billing@prudential.com.sg by 30 September
                        2024.
                        <br />
                        <br />
                        <b>
                          Recipient Name: Prudential Assurance Company Singapore
                          (Pte) Limited
                          <br />
                          Recipient Bank: Standard Chartered Bank
                          <br />
                          Recipient's Account number: 01-0519241-4
                          <br />
                          Recipient's SWIFT Code: SCBLSG22XXX
                          <br />
                          Bank Code: 9496
                          <br />
                          <br />
                          Comments / Reference Field for Recipient:
                          <br />
                          {this.state.policyNo} - (Agent NO XXXXX) / (Admin
                          Name, Unit Code Axx), EBO, Contact Number
                          <br />
                          <br />
                          Please note that there may be a slight difference in
                          actual premium calculation due to rounding
                          differences.
                          <br />
                          Premium calculation will be pro-rated based on 7
                          months of coverage.
                        </b>
                      </Typography>
                    </div>
                  )}
                  <div style={{ padding: "0px" }}>
                    <Typography
                      component={"span"}
                      className={classes.noteHead}
                      style={{ fontWeight: "bold" }}
                    >
                      <Checkbox
                        checked={this.state.consentPayment}
                        onChange={this.handleChangePayment("consentPayment")}
                        value="consentPayment"
                        color="primary"
                      />
                      I have read and agreed to the payment instructions above
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.bottomBackgroud}>
          <div className={classes.headerInfo}>
            <Typography component={"span"} className={classes.subHeader1Text}>
              {acknowledgeHeader({})}
            </Typography>

            <div className={classes.noteBlock}>
              <Typography
                component={"span"}
                className={classes.noteHead}
                style={{ fontWeight: "bold" }}
                dangerouslySetInnerHTML={{
                  __html: consentHeader({
                    year: placeholderMapTemp.get("{year}")
                  })
                }}
              />
              <Typography component={"span"} className={classes.noteHead}>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <span>
                    I/We expressly authorise and consent Prudential Assurance
                    Company Singapore (Pte) Limited and its related
                    corporations, respective representatives, agents, third
                    party service providers, contractors and/or appointed
                    distribution/business{" "}
                  </span>

                  {expanded && ( //show if expanded is true
                    <span>
                      partners (collectively referred to as “Prudential”) to
                      collect, use, retain, disclose and process, at their sole
                      discretion, any and all information relating to me/us,
                      including my/our personal particulars, my/our transactions
                      and dealings and my/our policy or policies of insurance
                      with Prudential, and disclosing such information to any of
                      the following, whether in Singapore or elsewhere for
                      purposes stated here and in Prudential’s Privacy Notice:
                      <br />
                      <br />
                      (a) Prudential’s holding companies, branches,
                      representative offices, subsidiaries, related corporations
                      or affiliates; <br />
                      (b) any of Prudential’s contractors or third-party service
                      providers or distribution/business partners or
                      professional advisers or agents;
                      <br />
                      (c) any regulatory, supervisory or other authority, court
                      of law, tribunal or person, in any jurisdiction, where
                      disclosure is required by law, regulation, judgement or
                      order of court or order of any tribunal or as a matter of
                      practice;
                      <br />
                      (d) any actual or potential assignee(s) or transferee(s)
                      of any rights and obligations of Prudential under or
                      relating to my/our policy or policies for any purpose
                      connected with the proposed assignment or transfer; and
                      <br />
                      (e) any credit bureau, insurer or financial adviser,
                      <br />
                      <br />
                      for the purposes of underwriting, customer servicing,
                      statistical analysis, investigation of Prudential’s
                      representatives, monitoring undesirable sales practices,
                      conducting customer due diligence, reporting to regulatory
                      or supervisory authorities and for auditing.
                      <br />
                      <br />
                      Where any personal data (third party personal data)
                      relating to another person (Individual) (e.g. insured
                      persons, family members, and beneficiaries) is disclosed
                      by me/us, I/we represent and warrant that I/we have
                      obtained the consent of the Individual to collect and use
                      the third party personal data and to disclose the third
                      party personal data to the persons enumerated above,
                      whether in Singapore or elsewhere, for the purposes stated
                      above and in Prudential’s Privacy Notice. <br />
                      <br />
                      I/We understand that I/we can refer to Prudential Privacy
                      Notice for more information on contacting Prudential for
                      Feedback, Access, Correction and Withdrawal of using
                      my/our personal data.
                      <br />
                      <br />
                      I/We understand that if I am/We are a European Union
                      (“EU”) resident individual (i.e. my/our residential
                      address is based in any of the EU countries), I/we can
                      refer to Prudential’s Privacy Notice for more information
                      on the rights available to me under the GDPR. <br />
                      <br />
                      Prudential Privacy Notice is available at{" "}
                      <a
                        href="https://www.prudential.com.sg/Privacy-Notice"
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        https://www.prudential.com.sg/Privacy-Notice{" "}
                      </a>{" "}
                      <br />
                      <br />
                      WARNING: PURSUANT TO SECTION 25(5) OF THE INSURANCE ACT
                      (CAP.142), YOU ARE TO DISCLOSE IN THIS ENROLMENT FORM
                      FULLY AND FAITHFULLY ALL THE FACTS YOU KNOW OR OUGHT TO
                      KNOW; OTHERWISE THE INSURANCE EFFECTED MAY BE VOID.
                    </span>
                  )}
                  <a
                    onClick={this.showButton}
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer"
                    }}
                  >
                    {this.state.expanded ? " Read less" : "...Read more"}
                  </a>
                </div>
              </Typography>
            </div>
            {labels["step4.consent.payment.header"] && (
              <div className={classes.noteBlock}>
                <Typography
                  component={"span"}
                  className={classes.noteHead}
                  style={{ fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: paymentHeader({
                      year: placeholderMapTemp.get("{year}")
                    })
                  }}
                />
                <Typography
                  component={"span"}
                  className={classes.noteHead}
                  dangerouslySetInnerHTML={{
                    __html: paymentDesc({
                      year: placeholderMapTemp.get("{year}"),
                      premium: parseFloat(
                        this.props.user.payloadEnrolment.premiumDep
                      ).toFixed(2),
                      totalPremium: parseFloat(
                        this.props.user.premium + this.props.user.premiumDep
                      ).toFixed(2)
                    })
                  }}
                />
              </div>
            )}
            <div className={classes.noteBlock}>
              <Typography
                className={classes.noteHead}
                style={{ fontWeight: "bold" }}
                dangerouslySetInnerHTML={{
                  __html: declarationHeader({
                    year: placeholderMapTemp.get("{year}")
                  })
                }}
              />
              <Typography
                component={"span"}
                className={classes.noteHead}
                dangerouslySetInnerHTML={{
                  __html: declarationDesc({
                    year: placeholderMapTemp.get("{year}")
                  })
                }}
              />
            </div>

            <div className={classes.noteBlock}>
              <Grid container>
                {document.map((doc, index) => {
                  const { fileName, type, desc, category, page, docType } = doc;
                  const docUrl = doc.file
                    ? window.location.href.split("/")[0] +
                      "/static/media/" +
                      path.basename(doc.file)
                    : doc.weblink;

                  if (
                    type &&
                    type.includes(user.client_name.client_name) &&
                    !(docType && docType === "weblink")
                  ) {
                    if (category ? category.includes(user.category) : true) {
                      if (page ? page.includes("REVIEW") : true) {
                        return (
                          <Grid key={index} item xs={12} sm={4} md={4}>
                            <Button
                              style={{ width: "100%" }}
                              onClick={() => {
                                this.handleClick(docUrl, fileName);
                              }}
                            >
                              <FileDownload style={{ color: "#ED1C24" }} />
                              <Typography
                                component={"span"}
                                className={classes.noteHead}
                              >
                                {" "}
                                {doc.desc}{" "}
                              </Typography>
                            </Button>
                          </Grid>
                        );
                      }
                    }
                  } else if (
                    type &&
                    type.includes(user.client_name.client_name) &&
                    docType &&
                    docType === "weblink"
                  ) {
                    if (category ? category.includes(user.category) : true) {
                      if (page ? page.includes("REVIEW") : true) {
                        return (
                          <Grid key={index} item xs={12} sm={4} md={4}>
                            <Button
                              style={{ width: "100%" }}
                              onClick={() => {
                                this.handleWebClick(docUrl);
                              }}
                            >
                              <Typography
                                component={"span"}
                                className={classes.noteHead}
                              >
                                {" "}
                                {doc.desc}{" "}
                              </Typography>
                            </Button>
                          </Grid>
                        );
                      }
                    }
                  }
                }, this)}
              </Grid>
            </div>
            {this.props.user.client_name.config.requiredFullHdf === true && (
              <Typography
                component={"span"}
                className={classes.noteHead}
                dangerouslySetInnerHTML={{
                  __html: hdfConsentParagraph({ prudential: "Prudential" })
                }}
              />
            )}
            <div className={classes.noteBlock}>
              {this.props.hdf.isSubmittingHDF ? (
                <CircularProgress color="secondary" />
              ) : (
                <Typography
                  component={"span"}
                  className={classes.noteHead}
                  style={{ fontWeight: "bold" }}
                >
                  <Checkbox
                    checked={this.state.consent}
                    onChange={this.handleChange("consent")}
                    value="consent"
                    color="primary"
                  />
                  {acknowledgeFooter({})}
                </Typography>
              )}

              {/* {!this.props.hdf.CMDocumentName && (
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    margin: "1%"
                  }}
                  onClick={this.handleHDFSubmission}
                >
                  Proceed with Health Declaration
                </Button>
              )} */}
              {!this.props.hdf.CMDocumentName && (
                <div id={"kofax-signing-container"}>
                  {(employeeRequiredHDF || childRequiredHDF) && (
                    <Button
                      disabled={
                        !this.props.hdf.signingUrl ||
                        this.props.hdf.isMainDocumentSigned ||
                        !this.state.consent
                      }
                      className={
                        !this.props.hdf.signingUrl ||
                        this.props.hdf.isMainDocumentSigned ||
                        !this.state.consent
                          ? classes.disabledSigningButton
                          : classes.signingButton
                      }
                    >
                      <a
                        href={this.props.hdf.signingUrl}
                        style={{
                          textDecoration: "none",
                          color: "white"
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        E-Sign Health Declaration Form (Employee)
                      </a>
                    </Button>
                  )}
                  {spouseRequiredHDF && (
                    <Button
                      disabled={
                        !this.props.hdf.signingUrl == null ||
                        !this.props.hdf.signingUrl == undefined ||
                        ((this.props.hdf.signingUrl != null ||
                          this.props.hdf.signingUrl != undefined) &&
                          !this.props.hdf.isMainDocumentSigned) ||
                        this.props.hdf.isSupplementDocumentSigned ||
                        this.props.hdf.isSubmittingHDF ||
                        !this.state.consent
                      }
                      className={
                        !this.props.hdf.signingUrl == null ||
                        !this.props.hdf.signingUrl == undefined ||
                        ((this.props.hdf.signingUrl != null ||
                          this.props.hdf.signingUrl != undefined) &&
                          !this.props.hdf.isMainDocumentSigned) ||
                        this.props.hdf.isSupplementDocumentSigned ||
                        this.props.hdf.isSubmittingHDF ||
                        !this.state.consent
                          ? classes.disabledSigningButton
                          : classes.signingButton
                      }
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "white"
                        }}
                        // className={classes.signingButton}
                        href={this.props.hdf.spouseSigningUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        E-Sign Health Declaration Form (Spouse)
                      </a>
                    </Button>
                  )}
                </div>
              )}

              <Typography
                component={"span"}
                className={classes.noteHead}
                style={{ margin: "1%" }}
              >
                {this.props.hdf.CMDocumentName}
              </Typography>
            </div>
          </div>
        </div>

        <PDFViewer
          open={this.state.open}
          handleClose={this.handleClose}
          pdf={this.state.pdf}
          fileName={this.state.fileName}
        />
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  contentInfo: {
    paddingTop: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerInfo: {
    padding: theme.spacing(1)
  },
  noteHead: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "28px"
  },
  noteBlock: {
    padding: "20px 0px"
  },
  noteBlockPayment: {
    padding: "10px"
  },
  subHeader1Text: {
    color: "#6B6A6D",
    fontSize: "20px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center"
  },
  warningImage: {
    background: "#ED1C24",
    mixBlendMode: "normal",
    borderRadius: "4px 0px 0px 4px"
  },
  rootInfo: {
    height: "100%",
    padding: "20px"
  },
  contentInfo: {
    //padding: theme.spacing.unit * 2.5,
    paddingTop: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerText: {
    color: "#ED1C24",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center"
  },
  noteHead: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "28px"
  },
  noteBlock: {
    padding: "20px 0px"
  },
  bottomBackgroud: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(4, 4, 0)
  },
  subHeaderText: {
    fontSize: "20px",
    //lineHeight: '55px',
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.388636px",
    color: "#6B6A6D"
  },
  subHeader1Text: {
    color: "#6B6A6D",
    fontSize: "20px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center"
  },
  planText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#68737A"
  },
  premiumBlock: {
    padding: "35px 0px",
    //width:'100%',
    borderBottom: "1px solid #E4E3E4",
    borderTop: "1px solid #E4E3E4",
    verticalAlign: "middle"
  },
  premiumBlockTotal: {
    padding: "45px 0px",
    width: "100%",
    borderBottom: "1px solid #000000",
    borderTop: "1px solid #000000",
    verticalAlign: "middle"
  },
  premiumTextLabel: {
    fontSize: "16px",
    color: "#000000",
    letterSpacing: "0.388636px",
    display: "flex",
    float: "left",
    fontWeight: 600
  },
  premiumTextValue: {
    fontSize: "16px",
    color: "#000000",
    float: "right"
    //fontWeight:600
  },
  premiumText: {
    color: "#333333",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "40px",
    alignItems: "center"
  },
  signingButton: {
    backgroundColor: "red",
    color: "white",
    margin: "1%"
  },
  disabledSigningButton: {
    backgroundColor: "grey",
    color: "white",
    margin: "1%"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user,
    hdf: state.hdf,
    isPaymentEnabled: selectIsPaymentEnabled(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitHDF: () => dispatch(userActions.submitHDF()),
    getKofaxDocumentStatus: () =>
      dispatch(userActions.getKofaxDocumentStatus()),
    getKofaxPackageStatus: () => dispatch(userActions.getKofaxPackageStatus()),
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(ReviewConsent)));
