import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect } from "react";
import FormHelperText from "@mui/material/FormHelperText";

export const MyDropzone = props => {
  const [fileData, setFileData] = useState([]);
  const [error, setError] = useState(false);
  const MAX_FILE_SIZE = 20971520;
  const acceptedImageExtensions = new Set([".png", ".jpg", ".jpeg", ".pdf"]);

  const isImage = name => {
    return acceptedImageExtensions.has(
      `.${name
        .toLowerCase()
        .split(".")
        .pop()}`
    );
  };

  useEffect(() => {
    // call callback function & pass fileData as parameter

    props.handleChildData(fileData);
  }, [fileData]);

  const onDrop = acceptedFiles => {
    console.log("Accepted files on drop", acceptedFiles);

    const fileDatas = acceptedFiles.map(file => {
      if (file.size > MAX_FILE_SIZE || isImage(file.name)) {
        setError(false);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1];
            const fileName = file.name;
            const image = {
              name: fileName,
              data: base64Data
            };
            resolve(image);
          };
        });
      } else {
        setError(true);
      }
    });
    if (fileDatas.every(fileData => fileData !== undefined)) {
      Promise.all(fileDatas).then(dataArray => {
        setFileData([...fileData, ...dataArray]);
      });
    }
  };

  const removeFile = index => {
    const newFileData = [...fileData];
    newFileData.splice(index, 1);
    setFileData(newFileData);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true
  });

  return (
    <Box>
      <Box>
        <Typography
          fontWeight={"bold"}
          style={{ color: "red", paddingLeft: "62px", paddingTop: "32px" }}
        >
          Upload Supporting Medical Documents
        </Typography>
        <Typography style={{ lineHeight: 3, paddingLeft: "62px" }}>
          To help us process your application better, you may wish to upload any
          supporting medical documents if you have them.
        </Typography>
        <Typography style={{ lineHeight: 3, paddingLeft: "62px" }}>
          The file format allowed are JPG, PNG and PDF with max file size 30MB.
        </Typography>
        <Typography style={{ lineHeight: 1, paddingLeft: "62px" }}>
          If file size is in excess of 30MB, kindly send the file(s) to
          sgp.es.medicalunderwriting@prudential.com.sg along with your Name and
          Identification number (NRIC/FIN)
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px dotted",
          borderColor: "grey.500",
          marginLeft: "60px",
          width: "20%",
          height: "100%"
        }}
        {...getRootProps()}
      >
        <Box alignItems="center">
          <CloudUploadIcon
            style={{ color: "red", paddingLeft: "50%", paddingTop: "32px" }}
          />
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography style={{ paddingLeft: "28%", paddingBottom: "32px" }}>
              Drop the files here ...
            </Typography>
          ) : (
            <Typography style={{ paddingLeft: "28%", paddingBottom: "32px" }}>
              Drag or upload your file here
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        {error && (
          <FormHelperText
            style={{ color: "red", paddingLeft: "62px", paddingTop: "32px" }}
          >
            Can't upload as file size is more than 30 MB or type of file not
            supported
          </FormHelperText>
        )}

        {fileData.map((data, index) => (
          <div key={index}>
            {data && (
              <Box>
                <Typography style={{ lineHeight: 3, paddingLeft: "62px" }}>
                  {data?.name}
                  <button onClick={() => removeFile(index)}>X</button>
                </Typography>
              </Box>
            )}
          </div>
        ))}
      </Box>
    </Box>
  );
};
