import axios from "axios";
import config from "config/config";
import { store } from "store";
import Util from "utils/Util";
import * as actionTypes from "../actions/types";

class ApiResource {
  constructor(opt) {
    this.instance = axios.create({
      baseURL: config.baseUrl,
      timeout: config.api.timeout
    });

    let baseURL = config.baseUrl;
    if (!baseURL.endsWith("/")) {
      baseURL += "/";
    }

    this.instance.interceptors.request.use(
      async config => {
        if (config.method.toUpperCase() !== "GET") {
          const response = await axios.get(`${baseURL}v1/csrf`);
          config.headers[response.data.header_name] = response.data.token;
        }

        return config;
      },
      error => Promise.reject(error)
    );
  }

  injectAuthToken(config = {}) {
    let state = store.getState();
    let token = state.user.auth && state.user.auth.access_token;

    if (Util.isEmpty(token)) return;

    config.headers = config.headers || {};
    if (Util.isEmpty(config.headers.Authorization)) {
      config.headers.Authorization = "Bearer " + token;
    }
  }

  request(method, url, params, config) {
    //Inject authorization token
    this.injectAuthToken(method === "get" ? params : config);
    return this.instance[method](url, params, config);
  }

  get(url, config = {}) {
    return this.request("get", url, config);
  }

  post(url, params, config = {}) {
    return this.request("post", url, params, config);
  }

  put(url, params, config = {}) {
    return this.request("put", url, params, config);
  }

  patch(url, params) {
    return this.instance.patch(url, { ...params });
  }

  delete(url) {
    return this.instance.delete(url);
  }
}

class ApiResponse {
  constructor(response) {
    // console.log("APIResponse raw:", response);
    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return response;
    }
  }
}

class ApiError {
  constructor(err) {
    let response = err.response;
    let ciamErrorCode = JSON.stringify(response.data.message);
    let error = {};

    console.log("APIError raw:", err, response, ciamErrorCode);

    if (response == null) {
      error = {
        code: 9999,
        message: err.message,
        defaultMessage: ""
        // defaultMessage: response.statusText
      };
    } else if (response.status >= 200 && response.status < 300) {
      error = {
        code: response.status,
        message: response.message,
        defaultMessage: response.statusText,
        details: response
      };
    } else if (response.status === 404) {
      error = {
        code: response.status,
        message: response.data ? response.data.email_status : "API not found",
        defaultMessage: response.statusText
      };
    } else if (response.status === 401) {
      error = {
        code: response.status,
        message: response.data
          ? response.data.error_description
          : "Not Authorized",
        defaultMessage: response.statusText
      };
      store.dispatch({ type: actionTypes.APP_SET_ERROR, payload: error });
    } else if (response.status === 400) {
      error = {
        code: response.status,
        message: response.data.message,
        defaultMessage: response.statusText
      };
    } else {
      let appError = {
        type: response.data && response.data.error,
        message: response.data && response.data.error_description,
        defaultMessage: response.statusText
      };
      error = {
        code: response.status,
        message: appError.message,
        type: appError.type
      };
    }
    error.fail = true;
    return error;
  }
}

export const APIResource = new ApiResource();
export const APIResponse = ApiResponse;
export const APIError = ApiError;
