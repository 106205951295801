import * as actionTypes from "./types";

import userService from "services/UserServices";
import Enums from "utils/Enums";
import Util from "../utils/Util";
import CiamResponseCode from "../utils/CiamResponseCode";
import { enrolment } from "assets";
import moment from "moment";
import { HDF_TYPES } from "../redux/health-declaration/hdf.types";

export const userActions = {
  refreshToken,
  login,
  logout,
  getEmployeeInfo,
  getResetPasswordOTP,
  getResetLinkValidity,
  putResetPasswordOTP,
  forgotPassword,
  register,
  updateToken,
  agreeTnc,
  getUserInfo,
  CIAMLogin,
  getRSA,
  getOtp,
  postOTP,
  selectPlan,
  selectDependentPlan,
  handleNextEnable,
  premiumCalculationEmp,
  premiumCalculationDep,
  dependentOptOut,
  consent,
  paymentConsent,
  hdfConsent,
  confirmEnrolment,
  postEnrolment,
  employeePlan,
  loadDefaultLabels,
  loadPlans,
  dependantPlanGHSB,
  dependantPlan,
  dependantPlanPGIH,
  saveActiveStep,
  handleBadgeOnDependent,
  setDependentByRelation,
  setDependentOptOut,
  setEmployeeOptOut,
  setDependentOptOutName,
  setEmployeeOptOutName,
  updateMe,
  saveDependent,
  updateDependent,
  deleteDependent,
  getOccupationClassList,
  fillHDFForm,
  selectHDFValue,
  selectHDFRemarks,
  occupationToClassValue,
  setSAandPremium,
  setDepSAandPremium,
  // setTotalPremium,
  paymentMethod,
  capitalizeFLetter,
  checkHDFRequirementCall,
  submitHDF,
  setHasHDF,
  getKofaxDocumentStatus,
  getKofaxPackageStatus,
  retrieveSignedDocument
};

function login(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService
      .login({
        username: encodeURIComponent(username),
        password: encodeURIComponent(password)
      })
      .then(
        response => {
          if (response.success) {
            const tokenObj = Util.jwt_decode(response.data.access_token);
            if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES)) {
              dispatch(success(response.data));
            } else {
              response["code"] = 400;
              response["customMessage"] =
                "The username or password you entered is incorrect.";
              dispatch(failure(response));
              return Promise.reject();
            }
          } else {
            if (response.code === 400)
              response["customMessage"] =
                "The username or password you entered is incorrect.";
            dispatch(failure(response));
          }
          return Promise.resolve(response);
        },
        error => {
          dispatch(failure(error));
          return Promise.reject(error);
        }
      );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getUserInfo() {
  return dispatch => {
    return userService.me().then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.USER_GET_INFO_SUCCESS,
            payload: response.data
          });

          dispatch(getEmployeeInfo());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_GET_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getEmployeeInfo() {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getEmployee(state.user.id).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.EMPLOYEE_GET_INFO_SUCCESS,
            payload: response.data
          });
          dispatch(loadPlans());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.EMPLOYEE_GET_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function employeePlan() {
  return (dispatch, getState) => {
    const user = getState().user;
    const { depRule, currentPlans } = getState().user;
    var mySelectMap = new Map();
    if (user.planSelected !== undefined) {
      mySelectMap = new Map(user.planSelected);
    }
    if (user.status !== "SUBMITTED") {
      user.coverage_details.current.map(plan => {
        if (user.currentPlans.length > 0) {
          let tempPlanKey =
            user.currentPlans &&
            user.currentPlans.filter(
              current => current.coverageId === plan.code
            )[0];
          let planKey = tempPlanKey && tempPlanKey.planType;
          let tempPlanValue =
            user.currentPlans &&
            user.currentPlans.filter(current => current.planType === planKey);
          let planValueTemp = tempPlanValue && tempPlanValue[0];
          let planValue =
            planValueTemp &&
            planValueTemp.planDetails.filter(
              obj => obj.option === plan.option
            )[0];
          mySelectMap.set(planKey, planValue);
        }
      });
    } else {
      if (
        user.client_name.config.isPruAgencyEnrolment === "True" ||
        user.client_name.config.isPruAgencyMedEnrolment === "True"
      ) {
        currentPlans.map(coverage => {
          let coverageType = coverage.planType;
          let coverageId = coverage.coverageId;
          let planObj = new Array();
          let currentPlan = user.coverage_details.current.find(
            plan => plan.code === coverageId
          );
          if (currentPlan === undefined) {
            let planValue = "Opt Out";
            mySelectMap.set(coverageType, planValue);
          } else {
            mySelectMap.set(coverageType, currentPlan);
          }
        });
      } else {
        if (user.coverage_details.current.length > 0) {
          user.coverage_details.current.map(plan => {
            if (user.currentPlans.length > 0) {
              let tempPlanKey =
                user.currentPlans &&
                user.currentPlans.filter(
                  current => current.coverageId === plan.code
                )[0];
              let planKey = tempPlanKey && tempPlanKey.planType;
              let tempPlanValue =
                user.currentPlans &&
                user.currentPlans.filter(
                  current => current.planType === planKey
                );
              let planValueTemp = tempPlanValue && tempPlanValue[0];
              let planValue =
                planValueTemp &&
                planValueTemp.planDetails.filter(
                  obj => obj.option === plan.option
                )[0];
              mySelectMap.set(planKey, planValue);

              // to display GCCE plan as opt out if employee opt out GCCE plan
              let hasGccePlan = user.coverage_details.current.filter(
                plan => plan.code === "GCCE"
              );
              if (hasGccePlan.length === 0) {
                planKey = "Group Crisis Cover Extra (GCCE) Benefits";
                planValue = "Opt Out";
                mySelectMap.set(planKey, planValue);
              }
            }
          });
        } else {
          user.currentPlans &&
            user.currentPlans.map(currentPlan => {
              let planKey = currentPlan && currentPlan.planType;
              let planValue = "Opt Out";
              mySelectMap.set(planKey, planValue);
            });
        }
      }
    }
    dispatch(selectPlan(mySelectMap));
  };
}

function getCoverageByCode(currentPlans, code) {
  return currentPlans.find(plan => plan.coverageId === code).planType;
}
function getRelationByCode(depRule, code) {
  return depRule[code].selectionMethod;
}
function getPlanByCodeOption(currentPlans, code, option) {
  var planDetails = currentPlans.find(plan => plan.coverageId === code)
    .planDetails;
  return planDetails.find(obj => obj.option === option);
}

function dependantPlan() {
  return (dispatch, getState) => {
    const user = getState().user;
    const { depRule, currentPlans } = getState().user;

    var dependantPlanSelected;
    var dependantList = [];
    var myDependentSelectMap = new Map();
    if (user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(user.planSelectedDependent);
    }

    currentPlans.map(coverage => {
      let coverageType = coverage.planType;
      let coverageId = coverage.coverageId;
      let relationType = getRelationByCode(depRule, coverageId);
      let categoryPlans =
        user.category === null
          ? user.client_name.config.categoryRules["All Employees"]
          : user.client_name.config.categoryRules[user.category];
      let dependentEnrole = categoryPlans[coverageId].dependentEnrole;
      if (dependentEnrole === "false") {
        let planObjDep = { planDep: "", dependentType: "Dependants" };
        let planObjListDep = new Array();
        planObjListDep.push(planObjDep);
        myDependentSelectMap.set(coverageType, planObjListDep);
      }
      if (relationType === "relation") {
        let planObjList = new Array();
        let spouseCheck = false;
        let childCheck = false;
        let spousePlan = {};
        let childPlan = {};
        let dependentPlanObj = {};
        user.dependencies.map(dependant => {
          const coverageCode = coverageId;
          let currentPlan = dependant.coverage_details.current.find(
            plan => plan.code === coverageCode
          );
          if (dependant.dependent === "SPOUSE") {
            if (currentPlan) {
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                currentPlan.option
              );
              spouseCheck = true;
              spousePlan = planDep;
              dependentPlanObj = planDep;
            }
          }
          if (dependant.dependent === "CHILD") {
            if (currentPlan) {
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                currentPlan.option
              );
              childCheck = true;
              childPlan = planDep;
              dependentPlanObj = planDep;
            }
          }
        });
        let planObjDep = { planDep: "", dependentType: "" };
        if (childCheck && spouseCheck) {
          planObjDep = {
            planDep: dependentPlanObj,
            dependentType: "Spouse & Child(ren)"
          };
        } else if (!(childCheck || spouseCheck)) {
          planObjDep = { planDep: "Opt Out", dependentType: "Dependants" };
        } else if (spouseCheck) {
          planObjDep = { planDep: spousePlan, dependentType: "Spouse Only" };
        } else if (childCheck) {
          planObjDep = { planDep: childPlan, dependentType: "Child(ren) Only" };
        }
        let planObjListDep = new Array();
        planObjListDep.push(planObjDep);
        myDependentSelectMap.set(coverageType, planObjListDep);
      }
      if (relationType === "name") {
        let planObjListN = new Array();
        user.dependencies.map(dependant => {
          const coverageCode = coverageId;
          let currentPlan = dependant.coverage_details.current.find(
            plan => plan.code === coverageCode
          );

          if (currentPlan) {
            var planDep = getPlanByCodeOption(
              currentPlans,
              currentPlan.code,
              currentPlan.option
            );

            let planObj = { planDep: "", dependentType: "" };
            planObj["planDep"] = planDep;
            planObj["dependentType"] = dependant;
            planObjListN.push(planObj);
          } else {
            let planObj = { planDep: "", dependentType: "" };
            planObj["planDep"] = "Opt Out";
            planObj["dependentType"] = dependant;
            planObjListN.push(planObj);
          }
        });
        myDependentSelectMap.set(coverageType, planObjListN);
      }
      if (relationType === "family") {
        let planObjList = new Array();
        user.dependencies.map(dependant => {
          const coverageCode = coverageId;
          let currentPlan = dependant.coverage_details.current.find(
            plan => plan.code === coverageCode
          );

          if (currentPlan) {
            var planDep = getPlanByCodeOption(
              currentPlans,
              currentPlan.code,
              currentPlan.option
            );
            let planObj = { planDep: "", dependentType: "" };

            planObj["planDep"] = planDep;
            planObj["dependentType"] = "Spouse & Child(ren)";
            planObjList.push(planObj);
          } else {
            let planObj = { planDep: "", dependentType: "" };

            planObj["planDep"] = "Opt Out";
            planObj["dependentType"] = "Spouse & Child(ren)";
            planObjList.push(planObj);
          }
        });
        myDependentSelectMap.set(
          coverageType,
          getUnique(planObjList, "dependentType")
        );
      }
      if (relationType === "ALLIN") {
        let reverseTempMap = {
          "004": "001",
          "005": "002",
          "009": "006",
          "010": "007"
        };
        let tempPlanArray = ["004", "005", "009", "010"];
        let planObjListN = new Array();
        user.dependencies.map(dependant => {
          const coverageCode = coverageId;
          let currentPlan = dependant.coverage_details.current.find(
            plan => plan.code === coverageCode
          );
          if (currentPlan) {
            if (tempPlanArray.includes(currentPlan.option)) {
              let option = currentPlan.option;
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                reverseTempMap[currentPlan.option]
                  ? reverseTempMap[currentPlan.option]
                  : currentPlan.option
              );
              let planDepNew = {};
              planDepNew.option = option;
              planDepNew.coverageId = planDep.coverageId;
              planDepNew.header = planDep.header;
              planDepNew.details = planDep.details;
              let planObj = { planDep: "", dependentType: "" };
              planObj["planDep"] = planDepNew;
              planObj["dependentType"] = dependant;
              planObjListN.push(planObj);
            } else {
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                currentPlan.option
              );
              let planObj = { planDep: "", dependentType: "" };
              planObj["planDep"] = planDep;
              planObj["dependentType"] = dependant;
              planObjListN.push(planObj);
            }
          } else {
            let planObj = { planDep: "", dependentType: "" };
            planObj["planDep"] = "Opt Out";
            planObj["dependentType"] = dependant;
            planObjListN.push(planObj);
          }
        });
        myDependentSelectMap.set(coverageType, planObjListN);
      }
      if (relationType === "cluster") {
        let planObjListDep = new Array();
        let childExist = false;
        let spousePlan = {};
        let childPlan = {};
        user.dependencies.map(dependant => {
          const coverageCode = coverageId;
          let currentPlan = dependant.coverage_details.current.find(
            plan => plan.code === coverageCode
          );
          let planObjDepSpouse = { planDep: "", dependentType: "" };
          let planObjDepChild = { planDep: "", dependentType: "" };
          if (dependant.dependent === "SPOUSE") {
            if (currentPlan) {
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                currentPlan.option
              );
              spousePlan = planDep;
              planObjDepSpouse = {
                planDep: spousePlan,
                dependentType: "Spouse Only"
              };
            } else {
              planObjDepSpouse = {
                planDep: "Opt Out",
                dependentType: "Spouse Only"
              };
            }
          }
          if (dependant.dependent === "CHILD") {
            if (currentPlan) {
              var planDep = getPlanByCodeOption(
                currentPlans,
                currentPlan.code,
                currentPlan.option
              );
              childPlan = planDep;
              planObjDepChild = {
                planDep: childPlan,
                dependentType: "Child(ren) Only"
              };
              if (!childExist) {
                planObjListDep.push(planObjDepChild);
              }
              childExist = true;
            } else {
              planObjDepChild = {
                planDep: "Opt Out",
                dependentType: "Child(ren) Only"
              };
              if (!childExist) {
                planObjListDep.push(planObjDepChild);
              }
              childExist = true;
            }
          }

          planObjListDep.push(planObjDepSpouse);
        });
        myDependentSelectMap.set(coverageType, planObjListDep);
      }
    });
    dispatch(selectDependentPlan(myDependentSelectMap));
  };
}

function getUnique(arr, comp) {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

function dependantPlanGHSB() {
  return (dispatch, getState) => {
    const user = getState().user;
    var dependantPlanSelected;
    var dependantList = [];
    user.dependencies.map(dependant => {
      if (
        dependant.coverage_details.current.filter(e => e.code === "GHSB")
          .length > 0
      ) {
        dependantList.push(dependant.dependent);
      }
    });
    if (dependantList.includes("SPOUSE") && dependantList.includes("CHILD")) {
      dependantPlanSelected = "Spouse & Child(ren)";
    } else if (dependantList.includes("SPOUSE")) {
      dependantPlanSelected = "Spouse Only";
    } else if (dependantList.includes("CHILD")) {
      dependantPlanSelected = "Child(ren) Only";
    } else {
      dependantPlanSelected = "Employee";
    }

    dispatch({
      type: actionTypes.DEPENDENT_OPTION_SELECTED,
      payload: dependantPlanSelected
    });
  };
}

function dependantPlanPGIH() {
  return (dispatch, getState) => {
    var dependantList = getState().user.dependencies;
    dependantList.map(dependant => {
      if (
        dependant.coverage_details.current.filter(e => e.code === "PGIH")
          .length > 0
      ) {
        dependant.optOut = false;
      } else {
        dependant.optOut = true;
      }
    });
    dispatch({
      type: actionTypes.DEPENDENT_OPTOUT_LIST,
      payload: dependantList
    });
  };
}

function logout() {
  userService.logout().catch(err => {});
  return { type: actionTypes.USER_LOGOUT };
}

function getResetPasswordOTP(code) {
  return dispatch => {
    return userService.getResetPasswordOTP(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getResetLinkValidity(code) {
  return dispatch => {
    return userService.getResetLinkValidity(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function putResetPasswordOTP(params) {
  return dispatch => {
    return userService.putResetPasswordOTP(params).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function forgotPassword(username) {
  return dispatch => {
    return userService.forgotPassword({ username }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function register(employeeNo, dob, policyNo, email) {
  return dispatch => {
    return userService.register({ employeeNo, dob, policyNo, email }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function agreeTnc(params) {
  return dispatch => {
    return userService.agreeTnc(params).then(
      resp => {
        dispatch(updateToken(resp.data));
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function updateToken(data) {
  return { type: actionTypes.USER_UPDATE_TOKEN, payload: data };
}

function refreshToken(token) {
  return {
    type: actionTypes.USER_REFRESH_TOKEN,
    payload: userService.refreshToken(token)
  };
}

function CIAMLogin(username, password, randomKey, txnId, cnonce, chash) {
  return (dispatch, getState) => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService
      .CIAMLogin({
        username: encodeURIComponent(username),
        password: encodeURIComponent(password),
        randomKey: randomKey,
        txnId: txnId,
        cnonce,
        chash
      })
      .then(
        response => {
          const tokenObj = Util.jwt_decode(response.data.access_token);
          if (response.success) {
            if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES)) {
              dispatch(success(response.data));
            } else {
              response["code"] = 400;
              response["customMessage"] = Util.validateLogin(
                CiamResponseCode.UNAUTHORIZED
              );
              dispatch(failure(response));
              return Promise.reject(response);
            }
          }
          return Promise.resolve(response);
        },
        error => {
          let errorCode = error.message.split(CiamResponseCode.CIAM_KEYWORD);
          error["customMessage"] = Util.validateLogin(errorCode[0]);
          dispatch(failure(error));
          return Promise.reject(error);
        }
      );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user, username };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getRSA(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService.getRSA().then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }

  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getOtp(username, password, randomKey, txnId) {
  return dispatch => {
    return userService.getOtp({ username, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function postOTP(otp, password, randomKey, txnId) {
  return dispatch => {
    return userService.postOTP({ otp, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        error["customMessage"] = Util.validateOtpLogin(error.message);
        return Promise.reject(error);
      }
    );
  };
}

function selectPlan(plan) {
  return {
    type: actionTypes.SELECT_PLAN,
    payload: plan
  };
}

function selectDependentPlan(plan) {
  return {
    type: actionTypes.SELECT_DEPENDENT_PLAN,
    payload: plan
  };
}

function handleNextEnable(enable) {
  return {
    type: actionTypes.HANDLE_NEXT,
    payload: enable
  };
}

function premiumCalculationEmp(premium) {
  return {
    type: actionTypes.PREMIUM_CALC_EMP,
    payload: premium
  };
}
function premiumCalculationDep(premiumDep) {
  return {
    type: actionTypes.PREMIUM_CALC_DEP,
    payload: premiumDep
  };
}
function setDependentByRelation(dependent) {
  return {
    type: actionTypes.DEPENDENT_BY_RELATION,
    payload: dependent
  };
}
function setDependentOptOut(dependentOptOut) {
  return {
    type: actionTypes.DEPENDENT_OPT_OUT_LIST,
    payload: dependentOptOut
  };
}
function setEmployeeOptOut(employeeOptOut) {
  return {
    type: actionTypes.EMPLOYEE_OPT_OUT_LIST,
    payload: employeeOptOut
  };
}
function setDependentOptOutName(dependentOptOutName) {
  return {
    type: actionTypes.DEPENDENT_OPT_OUT_NAME_LIST,
    payload: dependentOptOutName
  };
}
function setEmployeeOptOutName(employeeOptOutName) {
  return {
    type: actionTypes.EMPLOYEE_OPT_OUT_NAME_LIST,
    payload: employeeOptOutName
  };
}

function dependentOptOut(dependentList) {
  return {
    type: actionTypes.DEPENDENT_OPT_OUT,
    payload: dependentList
  };
}

function consent(consent) {
  return {
    type: actionTypes.CONSENT,
    payload: consent
  };
}
function paymentConsent(paymentConsent) {
  return {
    type: actionTypes.PAYMENT_CONSENT,
    payload: paymentConsent
  };
}
function hdfConsent(hdfConsent) {
  return {
    type: actionTypes.HDF_CONSENT,
    payload: hdfConsent
  };
}

function selectHDFValue(questionDepMap) {
  return {
    type: actionTypes.SELECT_HDF_VALUE,
    payload: questionDepMap
  };
}

function selectHDFRemarks(selectedValue) {
  return {
    type: actionTypes.SELECT_HDF_REMARKS,
    payload: selectedValue
  };
}

function paymentMethod(payment) {
  return {
    type: actionTypes.PAYMENT_METHOD,
    payload: payment
  };
}

function confirmEnrolment() {
  return (dispatch, getState) => {
    let userLogged = getState().user;
    let payloadEnrolment = {
      consent: userLogged.consent,
      hdfConsent: userLogged.hdfConsent,
      paymentConsent: userLogged.paymentConsent,
      total_premium: userLogged.premium + userLogged.premiumDep,
      premiumDep: userLogged.premiumDep,
      payment_method: userLogged.payment,
      hdf: userLogged.hdfPayLoad,
      coverage_details: {
        previous: userLogged.coverage_details.previous,
        current: []
      },
      dependants: getDependents(dispatch, userLogged)
    };
    Array.from(userLogged.planSelected).map(plan => {
      let planObj = {};
      if (plan[1] !== "Opt Out") {
        let planCode = plan[1].coverageId;
        let categotyPlans =
          userLogged.category === null
            ? userLogged.client_name.config.categoryRules["All Employees"]
            : userLogged.client_name.config.categoryRules[userLogged.category];
        let employeeEnrole = categotyPlans[planCode].employeeEnrole;
        if (
          userLogged.underwriting_required === true &&
          employeeEnrole !== "false"
        ) {
          let premiumValue = userLogged.empPremiumSA.get(plan[0]);
          planObj = {
            code: plan[1].coverageId,
            option: plan[1].option,
            sum_assured: premiumValue.get(userLogged.id).SA,
            premium: premiumValue.get(userLogged.id).premium
          };
          payloadEnrolment["coverage_details"].current.push(planObj);
        } else if (
          userLogged.client_name.config.isPruAgencyEnrolment === "True"
        ) {
          let premiumValue = userLogged.empPremiumSA.get(plan[0]);
          planObj = {
            code: plan[1].coverageId,
            option: plan[1].option,
            existing_sum_assured: premiumValue.get(userLogged.id)
              .existingSumAssured,
            sum_assured: premiumValue.get(userLogged.id).SA,
            premium: premiumValue.get(userLogged.id).premium,
            extra_premium: premiumValue.get(userLogged.id).extraPremium
          };
          payloadEnrolment["coverage_details"].current.push(planObj);
        } else if (
          userLogged.client_name.config.isPruAgencyMedEnrolment === "True"
        ) {
          let premiumValueGHS = userLogged.empPremiumSA.get(
            "Group Hospital & Surgical (GHS) Benefits"
          );
          let premiumValueGMM = userLogged.empPremiumSA.get(
            "Group Extended Major Medical Rider (GMM) Benefits"
          );
          premiumValueGMM =
            userLogged.empPremiumSA.get(
              "Group Extended Major Medical Rider (GMM) Benefits"
            ) !== undefined
              ? premiumValueGMM.get(userLogged.id).premium
              : "0";
          let premiumValueEmp = userLogged.empPremiumSA.get(plan[0]);
          let premiumValueDep;
          let dependentType;
          let planDep;
          let totalPremium;
          let basicOfCoverage;

          Array.from(userLogged.planSelectedDependent).map(coverage => {
            coverage[1] &&
              coverage[1].map(planValue => {
                if (coverage[0] === plan[0]) {
                  dependentType = planValue.dependentType;
                  planDep = planValue.planDep;
                }
              });
          });

          if (
            plan[0] !== "Group Accidental Death & Dismemberment (GADD) Benefits"
          ) {
            if (userLogged) {
              let depPremium = userLogged.depPremiumSA.get(plan[0]);
              premiumValueDep = depPremium
                ? parseFloat(depPremium.get(dependentType).premium)
                : 0;
            }
            premiumValueEmp = premiumValueEmp
              ? premiumValueEmp.get(userLogged.id).premium
              : 0;
            premiumValueGHS = premiumValueGHS
              ? premiumValueGHS.get(userLogged.id).premium
              : 0;
            totalPremium =
              parseFloat(premiumValueEmp) + parseFloat(premiumValueDep);

            if (planDep && planDep !== "Opt Out") {
              if (dependentType) {
                if (dependentType === "Spouse & Child(ren)") {
                  basicOfCoverage = "Self + Family";
                } else if (dependentType === "Spouse Only") {
                  basicOfCoverage = "Self + Spouse";
                } else if (dependentType === "Child(ren) Only") {
                  basicOfCoverage = "Self + Children";
                }
              } else {
                basicOfCoverage = "Self Only";
              }
            } else {
              basicOfCoverage = "Self Only";
            }
          }
          planObj = {
            code: plan[1].coverageId,
            option: plan[1].option,
            existing_sum_assured:
              plan[0] ===
              "Group Accidental Death & Dismemberment (GADD) Benefits"
                ? premiumValueEmp.get(userLogged.id).existingSumAssured
                : "",
            sum_assured:
              plan[0] ===
              "Group Accidental Death & Dismemberment (GADD) Benefits"
                ? premiumValueEmp.get(userLogged.id).SA
                : "",
            premium:
              premiumValueEmp &&
              premiumValueGMM &&
              plan[0] ===
                "Group Accidental Death & Dismemberment (GADD) Benefits"
                ? parseFloat(premiumValueEmp.get(userLogged.id).premium)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : parseFloat(totalPremium)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            basic_of_coverage: basicOfCoverage
          };
          payloadEnrolment["coverage_details"].current.push(planObj);
          payloadEnrolment["coverage_details"].previous =
            userLogged.coverage_details.previous;
        } else if (employeeEnrole === "false") {
        } else {
          let premiumValue = userLogged.empPremiumSA.get(plan[0]);
          planObj =
            plan[0] === "Group Crisis Cover Extra (GCCE) Benefits"
              ? {
                  code: plan[1].coverageId,
                  option: plan[1].option,
                  premium: premiumValue.get(userLogged.id).premium,
                  sum_assured: premiumValue.get(userLogged.id).SA,
                  existing_sum_assured: premiumValue.get(userLogged.id)
                    .existingSumAssured,
                  extra_premium: premiumValue.get(userLogged.id).extraPremium
                }
              : { code: plan[1].coverageId, option: plan[1].option };
          payloadEnrolment["coverage_details"].current.push(planObj);
        }
      }
    });
    dispatch({
      type: actionTypes.PAYLOAD,
      payload: payloadEnrolment
    });
  };
}

function fillHDFForm() {
  return (dispatch, getState) => {
    let userLogged = getState().user;
    let questionDepMap = new Map(userLogged.questionDepMap);
    let selectedValue = new Map(userLogged.selectedValue);
    let hdfPayLoad = [];

    questionDepMap.forEach(function(value, key, map) {
      let selectedValues = new Map(value);
      let keyParent = key;
      let userData = { id: "", id_no: "", type: "", data: [] };
      if (userLogged.id == keyParent) {
        let questionData = [];
        let idNo = userLogged.passport_nric;
        userData = {
          id: userLogged.id,
          id_no: idNo,
          type: "Employee",
          data: []
        };
        selectedValues.forEach(function(value, key, map) {
          let childValue =
            value instanceof Map || Array.isArray(value)
              ? new Map(value)
              : value;
          let questioObj = { code: "", value: "", dependentQuestions: [] };
          questioObj.code = key;
          questioObj.value = childValue;
          if (childValue == "Y") {
            let remarksObj = { code: "", value: "" };
            let remarksSet = new Map(selectedValue.get(keyParent));
            let remarks = remarksSet.get(key);
            userLogged.hdfData.details
              .find(detail => detail.code === key)
              .allowedValues.find(val => val.value === "Y")
              .dependentQuestions.map(function(depQ) {
                let remarksObj = { code: "", value: "" };
                remarksObj.code = depQ;
                remarksObj.value = remarks;
                questioObj.dependentQuestions.push(remarksObj);
              }, this);
          }
          userData.data.push(questioObj);
        });

        hdfPayLoad.push(userData);
      } else {
        userLogged.dependencies.map(function(dependent) {
          if (dependent.id == keyParent) {
            let questionData = [];
            let idNo = dependent.id_no;
            userData = {
              id: dependent.id,
              id_no: idNo,
              type: capitalizeFLetter(dependent.dependent),
              data: []
            };
            selectedValues.forEach(function(value, key, map) {
              let childValue =
                value instanceof Map || Array.isArray(value)
                  ? new Map(value)
                  : value;
              let questioObj = { code: "", value: "", dependentQuestions: [] };
              questioObj.code = key;
              questioObj.value = childValue;
              if (childValue == "Y") {
                let remarksObj = { code: "", value: "" };
                let remarksSet = new Map(selectedValue.get(keyParent));
                let remarks = remarksSet.get(key);
                userLogged.hdfData.details
                  .find(detail => detail.code === key)
                  .allowedValues.find(val => val.value === "Y")
                  .dependentQuestions.map(function(depQ) {
                    let remarksObj = { code: "", value: "" };
                    remarksObj.code = depQ;
                    remarksObj.value = remarks;
                    questioObj.dependentQuestions.push(remarksObj);
                  }, this);
              }
              userData.data.push(questioObj);
            });
            hdfPayLoad.push(userData);
          }
        }, this);
      }
    });
    userLogged.payloadEnrolment.hdf = hdfPayLoad;
    dispatch({
      type: actionTypes.PAYLOAD,
      payload: userLogged.payloadEnrolment
    });
  };
}

function getDependents(dispatch, loggedUser) {
  let dependencies = loggedUser.dependencies;
  let tempDependentList = new Array();

  let tempDependent = {
    coverage_details: {
      current: []
    }
  };
  loggedUser.dependencies.map(function(dependent) {
    let tempDependent = {};
    let coverage_details = {};
    let basicOfCoverage;
    tempDependent["id"] = dependent.id;
    let current = new Array();
    Array.from(loggedUser.planSelectedDependent).map(coverage => {
      coverage[1] &&
        coverage[1].map(planValue => {
          if (planValue.planDep && planValue.planDep !== "Opt Out") {
            if (planValue.dependentType) {
              if (planValue.dependentType === "Spouse & Child(ren)") {
                basicOfCoverage = "Self + Family";
              } else if (planValue.dependentType === "Spouse Only") {
                basicOfCoverage = "Self + Spouse";
              } else if (planValue.dependentType === "Child(ren) Only") {
                basicOfCoverage = "Self + Children";
              }
            } else {
              basicOfCoverage = "Self Only";
            }
          } else {
            basicOfCoverage = "Self Only";
          }

          if (planValue.dependentType === "Spouse & Child(ren)") {
            if (planValue.planDep.coverageId && planValue.planDep.option) {
              let premiumValue = new Map(
                loggedUser.depPremiumSA.get(coverage[0])
              );
              let planObj = {
                code: planValue.planDep.coverageId,
                option: planValue.planDep.option,
                basic_of_coverage: basicOfCoverage
              };
              current.push(planObj);
            }
          } else if (planValue.dependentType === "Spouse Only") {
            if (
              dependent.dependent === "SPOUSE" ||
              dependent.dependent === "Spouse"
            ) {
              if (planValue.planDep.coverageId && planValue.planDep.option) {
                let premiumValue = new Map(
                  loggedUser.depPremiumSA.get(coverage[0])
                );
                let planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  basic_of_coverage: basicOfCoverage
                };
                current.push(planObj);
              }
            }
          } else if (planValue.dependentType === "Child(ren) Only") {
            if (
              dependent.dependent === "CHILD" ||
              dependent.dependent === "Child"
            ) {
              if (planValue.planDep.coverageId && planValue.planDep.option) {
                let planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  basic_of_coverage: basicOfCoverage
                };
                current.push(planObj);
              }
            }
          } else if (
            planValue.dependentType.id &&
            planValue.dependentType.id === dependent.id
          ) {
            if (planValue.planDep.coverageId && planValue.planDep.option) {
              let planObj = {};
              let premiumValue = new Map(
                loggedUser.depPremiumSA.get(coverage[0])
              );
              if (loggedUser.underwriting_required === true) {
                planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  sum_assured: premiumValue.get(planValue.dependentType.id).SA,
                  premium: premiumValue.get(planValue.dependentType.id).premium
                };
              } else if (
                loggedUser.client_name.config.isPruAgencyEnrolment === "True"
              ) {
                planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  existing_sum_assured: premiumValue.get(
                    planValue.dependentType.id
                  ).existingSumAssured,
                  premium: premiumValue.get(planValue.dependentType.id).premium,
                  sum_assured: premiumValue.get(planValue.dependentType.id).SA,
                  extra_premium: premiumValue.get(planValue.dependentType.id)
                    .extraPremium
                };
              } else if (
                loggedUser.client_name.config.isPruAgencyMedEnrolment === "True"
              ) {
                planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  existing_sum_assured:
                    premiumValue &&
                    premiumValue.get(planValue.dependentType.id)
                      .existingSumAssured,
                  premium: premiumValue.get(planValue.dependentType.id).premium,
                  sum_assured: premiumValue.get(planValue.dependentType.id).SA
                };
              } else {
                planObj = {
                  code: planValue.planDep.coverageId,
                  option: planValue.planDep.option,
                  premium: premiumValue.get(planValue.dependentType.id).premium
                };
              }
              current.push(planObj);
            }
          }
        });
      coverage_details["current"] = current;
      coverage_details["previous"] = [];
      tempDependent["coverage_details"] = coverage_details;
      dependencies.map(dependant => {
        if (dependant.id === dependent.id) {
          dependant.coverage_details.current = current;
        }
      });
      tempDependentList.push(tempDependent);
      return {
        type: actionTypes.UPDATE_USER_DEPENDANTS,
        payload: dependencies
      };
    });
  });
  return Array.from(new Set(tempDependentList));
}

function setSAandPremium(empPremiumSA) {
  return {
    type: actionTypes.SA_PREMIUM,
    payload: empPremiumSA
  };
}

function setDepSAandPremium(depPremiumSA) {
  return {
    type: actionTypes.DEP_SA_PREMIUM,
    payload: new Map(depPremiumSA)
  };
}

function postEnrolment(id, payload) {
  return (dispatch, getState) => {
    return userService.postEnrolment(id, payload).then(
      resp => {
        dispatch({
          type: actionTypes.UPDATE_STATUS,
          payload: resp.data
        });
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function categoryBasedPlans(config, user, categoryRules) {
  let currentPlans = user.client_name.config.current;
  let category = user.category;
  let categoryRule =
    category === null
      ? categoryRules["All Employees"]
      : categoryRules[category];
  var filteredPlans = currentPlans.filter(coverage => {
    let tempResultOut = coverage.planDetails.filter(planDetail => {
      let coverageId = coverage.coverageId;
      let categoryRulebycoverage = categoryRule && categoryRule[coverageId];
      let tempResultIn =
        categoryRulebycoverage &&
        categoryRulebycoverage.plans.includes(planDetail.option);
      return tempResultIn;
    });
    coverage.planDetails = tempResultOut;
    return tempResultOut;
  });
  return filteredPlans;
}

function loadDefaultLabels(client_name) {
  let defaultLabels = enrolment.content[`DEFAULT`].registration_labels;
  let clientLabels =
    enrolment.content[client_name.toUpperCase()].registration_labels;

  for (var key in defaultLabels) {
    if (clientLabels && clientLabels.hasOwnProperty(key)) {
      defaultLabels[key] = clientLabels[key];
    }
  }

  for (var key in clientLabels) {
    if (!(defaultLabels && defaultLabels.hasOwnProperty(key))) {
      defaultLabels[key] = clientLabels[key];
    }
  }

  return {
    type: actionTypes.LOAD_DEFAULT_LABELS,
    registration_labels: defaultLabels
  };
}

function loadPlans() {
  return (dispatch, getState) => {
    const user = getState().user;

    const userType = "RI";
    var rateMatrics = user.client_name.config.rateMatrics;
    var premiumPlans = user.client_name.config.premium;
    var categoryRules = user.client_name.config.categoryRules;

    let remoteContent = user.client_name.content_config.labels;
    let defaultContent = enrolment.content[`DEFAULT`].labels;
    let hdfData = enrolment.hdfForm;

    for (var key in defaultContent) {
      if (remoteContent && remoteContent.hasOwnProperty(key)) {
        defaultContent[key] = remoteContent[key];
      }
    }

    for (var key in remoteContent) {
      if (!(defaultContent && defaultContent.hasOwnProperty(key))) {
        defaultContent[key] = remoteContent[key];
      }
    }
    var content = { labels: defaultContent, lookups: {} };

    var currentPlans = categoryBasedPlans(
      user.client_name.config,
      user,
      categoryRules
    );
    var previousPlans = enrolment[userType].default.previous;
    // var previousSelectedPlan = user.coverage_details.previous.length >0 && user.coverage_details.previous.filter(previous => previous.code==="GHSB")[0].option || '';
    var depRule = user.client_name.config.dependentRules;
    var placeholderMap = new Map(loadPlaceholder(user));
    dispatch({
      type: actionTypes.LOAD_PLANS,
      currentPlans,
      previousPlans,
      premiumPlans,
      content,
      depRule,
      // previousSelectedPlan,
      placeholderMap,
      rateMatrics,
      hdfData
    });
    if (user.status === "SUBMITTED") {
      dispatch(employeePlan());
      dispatch(dependantPlan());
    }
  };
}

function loadPlaceholder(user) {
  let placeholderMap = new Map();
  placeholderMap.set("{fullName}", user.fullName);
  placeholderMap.set("{year}", moment(user.policy_start_date).format("YYYY"));
  placeholderMap.set(
    "{enrolmentStart}",
    moment(user.enrolment_start_date).format("DD MMMM YYYY")
  );
  placeholderMap.set(
    "{enrolmentEnd}",
    moment(user.enrolment_end_date).format("DD MMMM YYYY")
  );
  placeholderMap.set(
    "{policyStart}",
    moment(user.policy_start_date).format("DD MMMM YYYY")
  );
  placeholderMap.set(
    "{policyEnd}",
    moment(user.policy_end_date).format("DD MMMM YYYY")
  );
  placeholderMap.set("{supportEmail}", user.client_name.config.supportEmail);
  user.client_name.config.underwriterEmail &&
    placeholderMap.set(
      "{underwriterEmail}",
      user.client_name.config.underwriterEmail
    );
  return placeholderMap;
}
function saveActiveStep(activeStep) {
  return {
    type: actionTypes.ACTIVE_STEP,
    payload: activeStep
  };
}

function removeEmpty(arrayList) {
  var tempArrayList = arrayList.filter(value => JSON.stringify(value) !== "{}");
  return tempArrayList;
}

function handleBadgeOnDependent(showDependentBadge) {
  return {
    type: actionTypes.SHOW_BADGE,
    payload: showDependentBadge
  };
}

function removeDuplicate(data) {
  var i = 0,
    j = data.length - 1,
    obj;
  for (; i < data.length; i++) {
    obj = data[i];
    for (; j > i; j--) {
      if (obj.id === data[j].id) {
        if (Array.isArray(obj.coverage_details.current)) {
          obj.coverage_details.current = obj.coverage_details.current.concat([
            data[j].coverage_details.current
          ]);
        } else {
          obj.coverage_details.current = [].concat([
            data[j].coverage_details.current,
            obj.coverage_details.current
          ]);
        }
        data.splice(j, 1);
      }
    }
  }
}

function updateMe(payload) {
  return dispatch => {
    return userService.updateMe(payload).then(
      response => {
        if (response.success) {
          dispatch(getUserInfo());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_SAVE_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function saveDependent(employeeId, payload) {
  return dispatch => {
    return userService.saveDependent(employeeId, payload).then(
      response => {
        if (response.success) {
          dispatch(getUserInfo());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_SAVE_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function updateDependent(employeeId, payload) {
  return dispatch => {
    return userService.updateDependent(employeeId, payload).then(
      response => {
        if (response.success) {
          dispatch(getUserInfo());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_SAVE_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function deleteDependent(employeeId, dependentId) {
  return dispatch => {
    return userService.deleteDependent(employeeId, dependentId).then(
      response => {
        if (response.success) {
          dispatch(getUserInfo());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_SAVE_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getOccupationClassList(occupation) {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getOccupationClassList(occupation).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.OCCUPATION_CLASS_LIST_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.OCCUPATION_CLASS_LIST_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function occupationToClassValue(occupationLabel) {
  return (dispatch, getState) => {
    const state = getState();
    let user = getState().user;
    let valueObj = {};
    let occupationObj;
    if (typeof occupationLabel === "object" && occupationLabel !== null) {
      occupationObj =
        user.occupationClassList &&
        user.occupationClassList.find(
          occupation => occupationLabel.label === occupation.occupation
        );
      valueObj.value = occupationObj.occupation_class;
      valueObj.label = occupationObj.occupation;
    } else if (
      occupationLabel !== undefined &&
      occupationLabel !== "" &&
      occupationLabel != null
    ) {
      occupationObj =
        user.occupationClassList &&
        user.occupationClassList.find(
          occupation => occupationLabel === occupation.occupation
        );
      valueObj.value = occupationObj && occupationObj.occupation_class;
      valueObj.label = occupationObj && occupationObj.occupation;
    } else {
      valueObj.value = "";
      valueObj.label = "";
    }

    return valueObj;
  };
}

function capitalizeFLetter(str) {
  return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
}

// function checkHDFRequirementCall(personId, params) {
//   return dispatch => {
//     return userService.checkHDFRequirementCall(personId, params).then(
//       resp => {
//         const { success, data } = resp;
//         if (success) {
//           data.length > 0
//             ? dispatch(handleUpdateHealthDeclaration(data))
//             : dispatch(handleUpdateHealthDeclaration([]));
//         }
//         return Promise.resolve(resp);
//       },
//       error => {
//         return Promise.reject(error);
//       }
//     );
//   };
// }

function checkHDFRequirementCall(personId, params) {
  return dispatch => {
    const { UPDATE_HEALTH_DECLARATION } = HDF_TYPES;

    return userService.checkHDFRequirementCall(personId, params).then(
      resp => {
        const { success, data } = resp;
        if (success) {
          dispatch({
            type: UPDATE_HEALTH_DECLARATION,
            payload: data
          });
        }
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function submitHDF(nonHDFPayload, hdfPayload, userId) {
  const {
    SUBMIT_HDF_STARTED,
    SUBMIT_HDF_SUCCESS,
    SUBMIT_HDF_FAILED
  } = HDF_TYPES;

  return (dispatch, getState) => {
    dispatch({
      type: SUBMIT_HDF_STARTED
    });

    const params = {
      ...nonHDFPayload,
      hdf: [...hdfPayload]
    };

    return userService.submitHDF(userId, params).then(
      resp => {
        const { success } = resp;
        success &&
          dispatch({
            type: SUBMIT_HDF_SUCCESS,
            payload: resp.data
          });
        return Promise.resolve(resp);
      },
      error => {
        dispatch({
          type: SUBMIT_HDF_FAILED
        });
        return Promise.reject(error);
      }
    );
  };
}

function setHasHDF(hasHDF) {
  const { HAS_HEALTH_DECLARATION } = HDF_TYPES;

  return (dispatch, getState) => {
    dispatch({
      type: HAS_HEALTH_DECLARATION,
      payload: hasHDF
    });
  };
}

function getKofaxDocumentStatus(packageId) {
  const {
    GET_DOCUMENT_STATUS_START,
    GET_DOCUMENT_STATUS_SUCCESS,
    GET_DOCUMENT_STATUS_FAILED
  } = HDF_TYPES;

  return (dispatch, getState) => {
    const hdf = getState().hdf;
    if (hdf.isMainDocumentSigned) {
      // if (hdf.spouseSigningUrl && hdf.isSupplementDocumentSigned) {
      //   dispatch(getKofaxPackageStatus(packageId));
      // } else {
      //   dispatch(getKofaxPackageStatus(packageId));
      // }
      // branch states the same function
      dispatch(getKofaxPackageStatus(packageId));
    }

    dispatch({
      type: GET_DOCUMENT_STATUS_START
    });

    return userService.getKofaxDocumentStatus(packageId).then(
      resp => {
        const { success, data } = resp;

        let documentStatus = {};
        data.map(document => {
          const { order, state } = document;
          if (order === "1") {
            documentStatus["mainDocumentStatus"] =
              state === "COMPLETE" ? true : false;
            documentStatus["supplementDocumentStatus"] = undefined;
          }
          if (order === "2") {
            documentStatus["supplementDocumentStatus"] =
              state === "COMPLETE" ? true : false;
          }
        });

        if (success) {
          dispatch({
            type: GET_DOCUMENT_STATUS_SUCCESS,
            payload: documentStatus
          });
        }
        return Promise.resolve(resp);
      },
      error => {
        dispatch({
          type: GET_DOCUMENT_STATUS_FAILED
        });

        return Promise.reject(error);
      }
    );
  };
}

function getKofaxPackageStatus(packageId) {
  const {
    GET_PACKAGE_STATUS_START,
    GET_PACKAGE_STATUS_SUCCESS,
    GET_PACKAGE_STATUS_FAILED
  } = HDF_TYPES;

  return (dispatch, getState) => {
    const user = getState().user;

    dispatch({
      type: GET_PACKAGE_STATUS_START
    });

    return userService.getKofaxPackageStatus(packageId).then(
      resp => {
        const { success, data } = resp;

        if (success && data.hdfStatus === "COMPLETE") {
          dispatch({
            type: GET_PACKAGE_STATUS_SUCCESS
          });

          dispatch(retrieveSignedDocument(user.id, packageId));
        }
        return Promise.resolve(resp);
      },
      error => {
        dispatch({
          type: GET_PACKAGE_STATUS_FAILED
        });

        return Promise.reject(error);
      }
    );
  };
}

function retrieveSignedDocument(personId, packageId) {
  const {
    GET_CM_DOCUMENT_DETAILS_START,
    GET_CM_DOCUMENT_DETAILS_SUCCESS,
    GET_CM_DOCUMENT_DETAILS_FAILED
  } = HDF_TYPES;

  return (dispatch, getState) => {
    dispatch({
      type: GET_CM_DOCUMENT_DETAILS_START
    });

    return userService.retrieveSignedDocument(personId, packageId).then(
      resp => {
        const { success, data } = resp;

        if (success && data.status === "SIGNED") {
          dispatch({
            type: GET_CM_DOCUMENT_DETAILS_SUCCESS,
            payload: data.documentName
          });
        }
        return Promise.resolve(resp);
      },
      error => {
        dispatch({
          type: GET_CM_DOCUMENT_DETAILS_FAILED
        });
        return Promise.reject(error);
      }
    );
  };
}
