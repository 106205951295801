import React, { Component } from "react";

import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";

import classNames from "classnames";
import { withStyles } from "@mui/styles";
import { common } from "@mui/material/colors";
import MobileDetect from "mobile-detect";
import { images as assetsImages } from "assets";
const mobileDetect = new MobileDetect(window.navigator.userAgent);

class HeaderPlain extends Component {
  isLandscapeMode = () => {
    return (
      mobileDetect.tablet() !== null || window.innerHeight < window.innerWidth
    );
  };

  render() {
    const { classes } = this.props;
    const images = assetsImages;

    return (
      <AppBar color="default" className={classNames(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftLogo}>
            {this.isLandscapeMode() && (
              <img
                src={images.pru_menu_work_logo}
                alt="logo"
                className={classes.pruworkImgLogo}
              />
            )}
          </div>
          <div className={classes.brandLogoContainer}>
            <img
              src={images.pru_menu_logo}
              alt="brand_logo"
              className={classes.pruLogo}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    zIndex: 1
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  imgLogo: {
    maxWidth: "30%"
  },
  flex: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  appBar: {
    position: "fixed",
    backgroundColor: common.white
  },
  brandLogoContainer: {
    alignItems: "flex-end",
    textAlign: "right",
    width: "50%"
  },
  leftLogo: {
    width: "50%"
  },
  pruLogo: {
    maxWidth: 157
  },
  pruworkImgLogo: {
    maxWidth: 177
  }
});

export default withStyles(styles, { withTheme: true })(HeaderPlain);
