import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { injectIntl } from "react-intl";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { images as assetsImages, menuIcons } from "assets";
// Styles
import "./LeftDrawer.css";
import { userActions } from "actions";
import Toolbar from "@mui/material/Toolbar";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Util from "utils/Util";
import muiTheme from "themes";
import Grid from "@mui/material/Grid";

// import {
//   getAvaliableProductBenefits,
//   isGrabGroupEmployee
// } from "selectors/CommonSelectors";

class MobileLeftDrawer extends Component {
  constructor(props) {
    super(props);
    this.routes = this.props.routes;

    this.state = {
      //listBenefits: getAvaliableProductBenefits(props.agentType)
    };
  }

  onLogoutClick = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  renderIcon = menu => {
    const { classes } = this.props;
    return menu.icon !== undefined ? (
      <Icon>{menu.icon}</Icon>
    ) : (
      <Avatar
        src={menu.image}
        alt={menu.name}
        className={classes.iconImageWrapper}
        imgProps={{ className: classes.iconImage }}
      />
    );
  };

  render() {
    const { user, location, drawerOpen, classes, intl } = this.props;
    const { listBenefits } = this.state;
    const filteredRoute = this.routes.filter(
      route =>
        user.auth.scope.includes(route.access) || route.access === undefined
    );
    let images = assetsImages;

    const userAvatar = (
      <div
        className={classNames(
          classes.profile,
          !drawerOpen && classes.profileClose
        )}
      >
        <Typography className={classes.profileText}>{user.fullName}</Typography>
        {/* <div className={classes.profileActions}>
          <Button
            variant="contained"
            color="primary"
            to={"/auth/myInfo"}
            onClick={this.props.handleDrawerClose}
            component={props => <Link {...props} />}
            className={classes.button}
          >
            My Info
          </Button>
        </div>
        <div className={classes.profileActions}>
          <Button
            className={classes.logout}
            color="primary"
            onClick={this.onLogoutClick}
          >
            Log out
          </Button>
        </div> */}
      </div>
    );

    return (
      <div>
        <Drawer
          variant="temporary"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !drawerOpen && classes.drawerPaperClose
            )
          }}
          open={drawerOpen}
          onClose={this.props.handleDrawerClose}
          ModalProps={{
            keepMounted: true
          }}
        >
          <div className={classes.drawerInner}>
            <Toolbar disableGutters={true}>
              <IconButton
                aria-label="close drawer"
                onClick={this.props.handleDrawerClose}
                className={classNames(drawerOpen && classes.hide)}
              >
                <Icon>clear</Icon>
              </IconButton>
              <img
                src={images.pru_menu_logo}
                className={classes.imgLogo}
                alt="logo"
              />
            </Toolbar>

            <Divider style={{ marginTop: 0 }} />

            {userAvatar}

            <MenuList>
              {filteredRoute.map((child, index) => {
                if (child.menu && child.menu.show) {
                  if (
                    child.menu.validate &&
                    child.menu.validate.employeeBenefit &&
                    (Util.isEmpty(listBenefits) ||
                      isGrabGroupEmployee(this.props.user))
                  ) {
                    return null;
                  }
                  return (
                    <Link
                      key={index}
                      to={child.path}
                      className={classes.menuItem}
                    >
                      <MenuItem
                        onClick={this.props.handleDrawerClose}
                        className={
                          location.pathname === child.path
                            ? classes.menuItemActive
                            : ""
                        }
                      >
                        <ListItemIcon className={classes.icon}>
                          {this.renderIcon(child.menu)}
                        </ListItemIcon>
                        <ListItemText
                          primary={child.name}
                          classes={{ primary: classes.itemText }}
                        />
                      </MenuItem>
                    </Link>
                  );
                } else return null;
              })}
              <span onClick={this.onLogoutClick} className={classes.menuItem}>
                <MenuItem>
                  <ListItemIcon className={classes.icon}>
                    <Avatar
                      src={menuIcons.logout}
                      alt="logout"
                      className={classes.iconImageWrapper}
                      imgProps={{ className: classes.iconImage }}
                    />
                    {/* <Icon>{menuIcons.learn}</Icon> */}
                  </ListItemIcon>
                  <ListItemText
                    primary="Log Out"
                    classes={{ primary: classes.itemText }}
                  />
                </MenuItem>
              </span>
            </MenuList>
          </div>
          <div style={{ flexGrow: 1 }} />
          {drawerOpen && (
            <div className={classes.infoContainer}>
              <Grid container spacing={"16px"}>
                <Grid item sm={12} md={12} xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.bottomHeader}
                  >
                    <b>For any clarifications</b>
                  </Typography>
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <Grid container>
                    <Grid item sm={2} md={2} xs={2}>
                      <img
                        className={classes.socialMedia}
                        src={menuIcons.contacts}
                        alt="contact us"
                      />
                    </Grid>
                    <Grid item sm={10} md={10} xs={10}>
                      <Typography
                        component={"div"}
                        className={classes.bottomText}
                      >
                        <b>Contact us</b>
                      </Typography>
                      <Typography
                        component={"div"}
                        className={classes.bottomLight}
                      >
                        <a
                          href={`mailto:${user.client_name.config.supportEmail}`}
                          style={{ color: "#6B6A6D" }}
                        >
                          {user.client_name.config.supportEmail}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item sm={12} md={12} xs={12}>
                        <Grid container>
                        <Grid item sm={2} md={2} xs={2}>
                        <img className={classes.socialMedia} src={menuIcons.readBenefits} alt="read benefits" />
                        </Grid>
                        <Grid item sm={10} md={10} xs={10}>
                            <Typography component={'span'} className={classes.bottomText}>
                            <b>Read your benefits</b>
                            </Typography>
                            <Typography component={'span'} className={classes.bottomLight}>               
                            <a href={intl.formatMessage({ id: "enrolment.url.benefits" })} target="_blank" style={{color: '#6B6A6D'}}> Employee Insurance & Medical Benefits Handbook (FY20) </a>                                                                
                            </Typography>
                        </Grid>
                        </Grid>                   
                    </Grid> */}
                {/* <Grid item sm={12} md={12} xs={12}>
                        <Grid container>
                        <Grid item sm={2} md={2} xs={2}>
                            <img className={classes.socialMedia}  src={menuIcons.learn} alt="learn more"/>
                        </Grid>
                        <Grid item sm={10} md={10} xs={10}>
                            <Typography component={'span'} className={classes.bottomText}>
                            <b>Learn more</b>                        
                            </Typography>
                            <Typography component={'span'} className={classes.bottomLight}>                    
                            <a href={intl.formatMessage({ id: "enrolment.url.faq" })} target="_blank" style={{color: '#6B6A6D'}}> PACSNET FAQ </a>                                           
                            </Typography>
                        </Grid>
                        </Grid>             
                    </Grid> */}
              </Grid>

              {/* <Paper className={classes.paperRoot} elevation={0}>
                <div className={classes.callContent}>
                  <Button
                    href="tel:1800 835 9733"
                    variant="fab"
                    color="primary"
                    aria-label="add"
                  >
                    <Icon>phone</Icon>
                  </Button>
                  <div className={classes.callInfo}>
                    <Typography className={classes.helpText}>
                      Call Our Helpline
                    </Typography>
                    <a
                      href="tel:1800 835 9733"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        href="tel:1800 835 9733"
                        color="primary"
                        className={classes.phoneText}
                      >
                        1800 835 9733
                      </Typography>
                      <Typography className={classes.helpText}>
                        (Mon-Fri, 8:30am to 5:30pm)
                      </Typography>
                    </a>
                    <a
                      href="tel:+65 6233 3299"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        href="tel:+65 6233 3299"
                        color="primary"
                        className={classes.phoneText}
                      >
                        +65 6233 3299
                      </Typography>
                      <Typography className={classes.helpText}>
                        (if you're dialling from overseas)
                      </Typography>
                    </a>
                  </div>
                </div>
                <div className={classes.policyContainer}>
                  <Typography component="p" className={classes.policyText}>
                    POLICY NUMBER:{" "}
                  </Typography>
                  {user.policy &&
                    user.policy.policy_no && (
                      <Typography
                        component="p"
                        className={classes.policyNumber}
                      >
                        {user.policy.policy_no}{" "}
                      </Typography>
                    )}
                </div>
              </Paper> */}
              {/* <a
                href="/termsAndConditions"
                target="_blank"
                style={{ padding: 10 }}
              >
                Terms & Conditions
              </a> */}
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}

const styles = theme => ({
  menuItem: {
    textDecoration: "none"
  },
  drawerPaper: {
    position: "absolute",
    height: "100%",
    width: theme.miniLeftDrawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    width: 0,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerInner: {
    width: theme.miniLeftDrawer.width
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    ...theme.mixins.toolbar
  },
  imgLogo: {
    maxWidth: "54%"
  },
  profile: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "1",
    maxHeight: 172,
    transition: "all 0.2s linear"
  },
  profileClose: {
    opacity: "0",
    maxHeight: 0
  },
  profileAvatar: {
    width: 80,
    height: 80
  },
  profileText: {
    textAlign: "center",
    padding: 10
  },
  profileActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around"
  },
  logout: {
    marginTop: 10,
    fontWeight: "bold"
  },
  menuItemActive: {
    backgroundColor: "#f2f2f2 !important",
    borderRight: "4px solid red"
  },
  paperRoot: {
    border: "solid 1px #E4E3E4",
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
    margin: "auto",
    marginBottom: 20,
    padding: 5,
    textAlign: "center",
    width: "80%"
  },
  helpText: {
    "font-size": 11,
    color: "#6A6B6D",
    textAlign: "center"
  },
  phoneText: {
    "font-weight": 600,
    "font-size": 16
  },
  iconImageWrapper: {
    overflow: "unset",
    width: "unset",
    height: "unset"
  },
  iconImage: {
    width: 24,
    height: 25
  },
  //   infoContainer: {
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center"
  //   },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F2F2",
    padding: 16
  },
  callContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    margin: theme.spacing.unit,
    paddingBottom: "14px",
    backgroundColor: muiTheme.colors.blackScale.black50,
    borderBottom: "solid 1px " + muiTheme.colors.blackScale.black60
  },
  callText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 11
  },
  callNumber: {
    color: "#ED1B24",
    textAlign: "center",
    fontSize: 20,
    lineHeight: 1.56,
    letterSpacing: 0.5,
    fontWeight: 600
  },
  policyContainer: {
    display: "flex",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    margin: `15px 30px `,
    fontSize: 14
  },
  policyText: {
    color: "#6A6B6D"
  },
  policyNumber: {
    fontWeight: "600"
  },
  itemText: {
    color: "#6B6A6D"
  },
  bottomLight: {
    fontSize: "13px",
    lineHeight: "24px",
    color: "#6B6A6D"
  }
});

function mapStateToProps(state) {
  return {
    agentType: state.user.auth.pcode,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withStyles(styles, { withTheme: true })(MobileLeftDrawer)))
);
