import React, { Component } from "react";
import moment from "moment";
import smoothscroll from "smoothscroll-polyfill";
import { renderRoutes } from "react-router-config";
import { withStyles } from "@mui/styles";
import MobileDetect from "mobile-detect";
import Header from "components/Header";
import Hidden from "@mui/material/Hidden";
import HeaderPlain from "components/HeaderPlain";
import MiniLeftDrawer from "components/MiniLeftDrawer";
import MobileLeftDrawer from "components/MobileLeftDrawer";
import { connect } from "react-redux";
import { userActions, appActions } from "actions";
import { images as assetsImages } from "assets";

import SessionExpireDialog from "components/SessionExpireDialog";
import classNames from "classnames";
import { bindActionCreators } from "redux";

smoothscroll.polyfill();
const mobileDetect = new MobileDetect(window.navigator.userAgent);

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navDrawerOpen: false,
      tokenRefreshTimeInMin: 1
    };

    this.mainRef = element => {
      this.main = element;
    };

    this.mainMobileRef = element => {
      this.mainMobile = element;
    };

    this.route = props.route;
  }

  componentWillMount() {
    const { user } = this.props;
    const { tokenRefreshTimeInMin } = this.state;

    if (user.loggedIn && user.auth) {
      //if token expire
      const expire = moment(user.auth.expire_time);
      const diffInMinute = expire.diff(moment(), "minutes");
      if (diffInMinute < tokenRefreshTimeInMin) {
        this.props.logout();
        this.props.history.push("/");
      } else {
        this.props.getUserInfo().catch(err => {
          console.log(err.defaultMessage);
        });
        this.props.getOccupationClassList().catch(err => {
          console.log(err.defaultMessage);
        });
      }
    } else {
      this.props.logout();
      this.props.history.push("/");
    }
  }

  c;

  componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({
        // navDrawerOpen: nextProps.width === LARGE
        navDrawerOpen: nextProps.width === "500px"
      });
    }

    try {
      if (
        this.props.location.pathname !== nextProps.location.pathname ||
        nextProps.app.scrollToTop
      ) {
        if (this.main) this.main.scrollTo(0, 0);
        this.props.scrollToTopFin();
      }
    } catch (error) {
      console.warn("ScrollTo not working for IE/Edge, or newer unknown tech");
    }
  }

  handleMenuToggle = () => {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    });
  };

  render() {
    let { navDrawerOpen, tokenRefreshTimeInMin } = this.state;
    const { classes, history, user } = this.props;

    if (!user.auth) return <div />;

    const filteredRoute = this.route.routes.filter(
      route =>
        user.auth.scope.includes(route.access) || route.access === undefined
    );

    let images = assetsImages;
    return (
      <div className={classes.root}>
        <SessionExpireDialog
          history={history}
          tokenRefreshTimeInMin={tokenRefreshTimeInMin}
        />
        <Hidden mdUp>
          <Header
            drawerOpen={navDrawerOpen}
            handleDrawerOpen={this.handleMenuToggle}
          />
          <div className={classes.appFrame}>
            <MobileLeftDrawer
              user={this.props.user}
              avatar={images.avatar}
              routes={this.route.routes}
              drawerOpen={navDrawerOpen}
              handleDrawerClose={this.handleMenuToggle}
            />

            <main ref={this.mainMobileRef} className={classes.content}>
              {renderRoutes(filteredRoute)}
            </main>
          </div>
        </Hidden>

        <Hidden smDown>
          <HeaderPlain />
          <div className={classes.appFrame}>
            <MiniLeftDrawer
              user={this.props.user}
              avatar={images.avatar}
              drawerOpen={navDrawerOpen}
              routes={this.route.routes}
              handleMenuToggle={this.handleMenuToggle}
            />
            <main
              ref={this.mainRef}
              className={classNames(
                classes.content,
                navDrawerOpen && classes.menuShow,
                !navDrawerOpen && classes.menuClose
              )}
            >
              {/* -- Render Child pages */}
              {renderRoutes(filteredRoute)}
            </main>
          </div>
        </Hidden>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.background.color
  },
  appFrame: {
    display: "flex",
    flex: 1,
    height: `calc(99vh - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: `64px`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.mixins.toolbar.minHeight}px`
    }
  },
  content: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: mobileDetect.mobile() === null ? "auto" : "hidden",
    WebkitOverflowScrolling: "touch",
    background: "url(" + assetsImages.background + ")",
    backgroundSize: "cover",
    backgroundPosition: "100% 100%",
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuShow: {
    marginLeft: `${theme.miniLeftDrawer.width}px`
  },
  menuClose: {
    marginLeft: `${theme.miniLeftDrawer.widthClosed}px`
  }
});

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout()),
    getUserInfo: () => dispatch(userActions.getUserInfo()),
    getOccupationClassList: () =>
      dispatch(userActions.getOccupationClassList()),
    loadPlans: () => dispatch(userActions.loadPlans()),
    ...bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Auth));
