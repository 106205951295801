import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { userActions } from "actions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import MessageFormat from "messageformat";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EnroleMyDetails from "components/EnroleMyDetails";
import SimpleConfirmDialog from "components/SimpleConfirmDialog";
import Util from "utils/Util";

const FORMAT_DATE = "MM/DD/YYYY";
const parseNationality = (nationality, props) => {
  return props.intl.messages["user.nationality." + nationality] || nationality;
};
const styles = theme => ({
  root: {
    width: "95%",
    overflowX: "auto",
    padding: 30
  },

  field: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000",
    padding: 20,
    textAlign: "center"
  },
  header: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000",
    fontWeight: "bold"
  },

  label: {
    fontSize: "18px",
    lineHeight: "35px",
    padding: 20,
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    textAlign: "center"
  },
  table: {
    minWidth: 700
  },
  icon: {
    margin: theme.spacing.unit,
    cursor: "pointer"
  },
  button: {
    minWidth: "0px",
    padding: "8px"
  }
});

const maritalStatus = [
  {
    value: "M",
    label: "Married"
  },
  {
    value: "S",
    label: "Single"
  },
  {
    value: "W",
    label: "Widowed"
  },
  {
    value: "D",
    label: "Divorced"
  }
];

const gender = [
  {
    value: "M",
    label: "Male"
  },
  {
    value: "F",
    label: "Female"
  }
];

class EnroleDependentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      dependents: this.props.user.dependencies,
      open: false,
      nationality: this.fetchNationality(props),
      dependent: {},
      openConfirmDialog: false
    };
  }

  formatDate = date => {
    return (date && moment(date, FORMAT_DATE).toDate()) || null;
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  handleClickOpen = dependent => {
    this.setState({ dependent: dependent, open: true });
  };
  handleDelete = dependent => {
    this.setState({ dependent: dependent, openConfirmDialog: true });
  };
  closeCofirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  responseConfirmDialog = () => {
    this.closeCofirmDialog();
    let userEnrole = Util.clone(this.state.dependent);
    let pruAgencyPlanTypes = [
      "Group Hospital & Surgical (GHS) Benefits",
      "Group Extended Major Medical Rider (GMM) Benefits",
      "Group Accidental Death & Dismemberment (GADD) Benefits"
    ];
    userEnrole.dependent = this.capitalizeFLetter(userEnrole.dependent);
    this.props
      .deleteDependent(this.state.user.id, userEnrole.id)
      .then(response => {
        if (response.success) {
          let showDependentBadge = new Map();
          if (this.props.user.showDependentBadge !== undefined) {
            showDependentBadge = new Map(this.props.user.showDependentBadge);
          }
          let dependentOptOutName = new Map();
          if (this.props.user.dependentOptOutName !== undefined) {
            dependentOptOutName = new Map(this.props.user.dependentOptOutName);
          }
          let myDependentSelectMap = new Map(
            this.props.user.planSelectedDependent
          );
          let mySelectMap = new Map(this.props.user.planSelected);
          if (this.props.user.underwriting_required === true) {
            let questionDepMap = new Map(this.props.user.questionDepMap);
            let selectedValue = new Map(this.props.user.selectedValue);

            questionDepMap.delete(userEnrole.id);
            selectedValue.delete(userEnrole.id);
            this.props.selectHDFValue(questionDepMap);
            this.props.selectHDFRemarks(selectedValue);
          }

          let tempPlaonObjList = [];
          this.props.user.currentPlans.map(planHead => {
            // tempPlaonObjList = myDependentSelectMap && myDependentSelectMap.get(planHead.planType) && myDependentSelectMap.get(planHead.planType).filter(planObj => {
            //   return planObj.dependentType.id != userEnrole.id
            // })
            myDependentSelectMap.delete(planHead.planType);
            mySelectMap.delete(planHead.planType);
            dependentOptOutName = new Map();
            // myDependentSelectMap.set(planHead.planType,tempPlaonObjList);
          });

          if (
            this.props.user.client_name.config.isPruAgencyMedEnrolment ===
            "True"
          ) {
            for (let index = 0; index < pruAgencyPlanTypes.length; index++) {
              showDependentBadge.set(pruAgencyPlanTypes[index], false);
            }
          }

          this.props.handleBadgeOnDependent(showDependentBadge);
          this.props.setDependentOptOutName(dependentOptOutName);
          this.props.selectDependentPlan(myDependentSelectMap);
          this.props.selectPlan(mySelectMap);
        }
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  marriedStatus = status => {
    switch (status) {
      case "M":
        return "Married";
        break;
      case "S":
        return "Single";
        break;
      case "W":
        return "Widowed";
        break;
      case "D":
        return "Divorced";
        break;
      default:
        return "No Status Found";
    }
  };
  handleChange = name => event => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.value
      }
    });
  };

  updateRecord = userEnrole => {
    this.setState({
      dependents: this.props.user.dependencies
    });
  };

  handleDateChange = name => date => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: (date && moment(date).format(FORMAT_DATE)) || null
      }
    });
  };

  fetchNationality = propss => {
    let strNationality = "user.nationality";
    let nationalityArr = new Array();
    let data = propss.intl.messages;
    for (var prop in data) {
      if (prop.includes(strNationality)) {
        let nationalityObj = { value: "", label: "" };
        nationalityObj["value"] = prop.split(".")[2];
        nationalityObj["label"] = data[prop];
        nationalityArr.push(nationalityObj);
      }
    }
    return nationalityArr;
  };

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.user.dependencies) !==
      JSON.stringify(this.props.user.dependencies)
    ) {
      //Perform some operation
      this.setState({
        dependents: nextProps.user.dependencies
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { labels } = this.props.user.content;
    const { placeholderMap } = this.props.user;
    const { user, nationality } = this.state;
    const mf = new MessageFormat("en");
    const dependantNote =
      labels["step1.dependant.note"] &&
      mf.compile(labels["step1.dependant.note"]);
    const placeholderMapTemp = new Map(placeholderMap);
    const isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let maxDependent = false;
    let existingInsuredDependant = false;

    if (isPruAgencyEnrolment) {
      let user = this.props.user;

      if (user.dependencies.length > 0) {
        maxDependent = true;
      }

      if (
        user.dependencies[0] &&
        user.dependencies[0].existing_insured === true
      ) {
        existingInsuredDependant = true;
      }
    }

    return (
      <div className={classes.root}>
        {dependantNote ? (
          <span>
            <nobr>
              <span className={classes.header}>Dependant Information </span>
              <span
                className={classes.dependantNote}
                dangerouslySetInnerHTML={{
                  __html: dependantNote({
                    year: placeholderMapTemp.get("{year}")
                  })
                }}
              />
            </nobr>
          </span>
        ) : (
          <Typography component={"span"} className={classes.header}>
            Dependant Information
          </Typography>
        )}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.label}>
                Dependant Full Name
              </TableCell>
              <TableCell className={classes.label}>NRIC/FIN</TableCell>
              <TableCell className={classes.label}>Date of Birth</TableCell>
              <TableCell className={classes.label}>
                Relationship to Employee
              </TableCell>
              {this.state.user.enable_dependent_registration && (
                <TableCell className={classes.label}>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.dependents
              .filter(dependent => dependent.dependent === "SPOUSE")
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.field}
                    >
                      {row.full_name}
                    </TableCell>
                    <TableCell className={classes.field}>{row.id_no}</TableCell>
                    <TableCell className={classes.field}>{row.dob}</TableCell>
                    <TableCell className={classes.field}>
                      {this.capitalizeFLetter(row.dependent)}
                    </TableCell>
                    {this.state.user.enable_dependent_registration && (
                      <TableCell className={classes.field}>
                        <Button
                          aria-label="edit"
                          className={classes.button}
                          onClick={e => this.handleClickOpen(row)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          aria-label="delete"
                          className={classes.button}
                          onClick={e => this.handleDelete(row)}
                          disabled={existingInsuredDependant}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {this.state.dependents
              .filter(dependent => dependent.dependent === "CHILD")
              .sort((a, b) => a.full_name.localeCompare(b.full_name))
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.field}
                    >
                      {row.full_name}
                    </TableCell>
                    <TableCell className={classes.field}>{row.id_no}</TableCell>
                    <TableCell className={classes.field}>{row.dob}</TableCell>
                    <TableCell className={classes.field}>
                      {this.capitalizeFLetter(row.dependent)}
                    </TableCell>
                    {this.state.user.enable_dependent_registration && (
                      <TableCell className={classes.field}>
                        <Button
                          aria-label="edit"
                          className={classes.button}
                          onClick={e => this.handleClickOpen(row)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          aria-label="delete"
                          className={classes.button}
                          onClick={e => this.handleDelete(row)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        {this.state.user.enable_dependent_registration && (
          <div style={{ float: "right", padding: "20px" }}>
            <Button
              onClick={e => this.handleClickOpen({})}
              variant="contained"
              color="primary"
              disabled={maxDependent || existingInsuredDependant}
            >
              Add Dependant
            </Button>
          </div>
        )}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullScreen
          disableEnforceFocus
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ paddingTop: "55px" }}>
            <EnroleMyDetails
              title="Dependant Information"
              history={this.props.history}
              type="Dependant"
              updateRecord={this.updateRecord.bind(this, this.state.dependent)}
              buttonName="Save"
              onClose={this.handleClose}
              userEnrole={this.state.dependent}
            />
          </DialogContent>
        </Dialog>
        <SimpleConfirmDialog
          name="leave-delete-dialog"
          title="Delete Dependant."
          description="Are you sure you want to delete the dependant."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeCofirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(EnroleDependentDetails)));
