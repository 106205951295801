import {
  pruAgencyAgeRange,
  pruAgencyMedAgeRange,
  siaAgeRange
} from "../config/age-range.constants";

/**
 *
 * @param {import("../config/age-range.constants").AgeRangeWithInsuredType} ageRange
 * @param {number} diffYears
 * @param {boolean} isExistingInsured
 * @returns
 */
const validateAgeByInsuredType = (ageRange, diffYears, isExistingInsured) => {
  if (isExistingInsured) {
    return {
      result: Boolean(
        diffYears >= ageRange.existingInsured.min.value &&
          diffYears <= ageRange.existingInsured.max.value
      ),
      ageRange: ageRange.existingInsured
    };
  }

  return {
    result: Boolean(
      diffYears >= ageRange.nonExistingInsured.min.value &&
        diffYears <= ageRange.nonExistingInsured.max.value
    ),
    ageRange: ageRange.nonExistingInsured
  };
};

export const PruAgencyEnrolment = {
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateSpouseAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      pruAgencyAgeRange.dependant.spouse,
      diffYears,
      isExistingInsured
    );
  },
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateEmployeeAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      pruAgencyAgeRange.employee,
      diffYears,
      isExistingInsured
    );
  }
};

export const PruAgencyMedEnrolment = {
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateSpouseAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      pruAgencyMedAgeRange.dependant.spouse,
      diffYears,
      isExistingInsured
    );
  },
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateEmployeeAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      pruAgencyMedAgeRange.employee,
      diffYears,
      isExistingInsured
    );
  }
};

export const SiaEnrolment = {
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateSpouseAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      siaAgeRange.dependant.spouse,
      diffYears,
      isExistingInsured
    );
  },
  /**
   * @param {number} diffYears
   * @param {boolean} isExistingInsured
   */
  validateEmployeeAge(diffYears, isExistingInsured) {
    return validateAgeByInsuredType(
      siaAgeRange.employee,
      diffYears,
      isExistingInsured
    );
  },
  /**
   * @param {number} diffDays
   * @param {number} diffYears
   */
  validateChildAge(diffDays, diffYears) {
    const result = {
      result: Boolean(
        diffDays >= siaAgeRange.dependant.child.general.min.value &&
          diffYears <= siaAgeRange.dependant.child.general.max.value
      ),
      ageRange: siaAgeRange.dependant.child.general
    };

    return result;
  }
};
