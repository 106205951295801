import * as actionTypes from "actions/types";
import moment from "moment";
import Util from "../utils/Util";
import Enums from "../utils/Enums";

const initialState = {
  id: null,
  fullName: "",
  designation: "Managing Partner",
  category: "",
  dependencies: [],
  featching: false,
  fetched: false,
  error: null,
  planSelected: ""
};

const formatDate = unixTimestamp => {
  if (isNaN(unixTimestamp)) {
    return unixTimestamp;
  } else {
    return moment(unixTimestamp).format("L");
  }
};

const formatDependent = ({ dob, date_of_employment, ...data }) => ({
  dob: formatDate(dob),
  date_of_employment:
    date_of_employment != null ? formatDate(date_of_employment) : null,
  ...data
});

const reducer = (state = initialState, action) => {
  function decodeAccessToken(token) {
    const decoded = Util.jwt_decode(token);
    return {
      expire_time: moment.unix(decoded.exp).toDate(),
      role: decoded.ar,
      pid: decoded.pid,
      pcode: decoded.pcode,
      scope: decoded.scope.join(" ")
    };
  }
  switch (action.type) {
    case actionTypes.GET_SIGNED_URL_SUCCESS: {
      return {
        ...state,
        signingURL: action.payload
      };
    }

    case actionTypes.USER_UPDATE_TOKEN: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        auth: Object.assign(
          {
            ...state.auth,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token
          },
          decodeAccessToken(action.payload.access_token)
        )
      };
    }
    case actionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: action.user.scope.includes(Enums.SCOPE_TYPE.TC_W)
          ? true
          : false,
        loggedIn: action.user.scope.includes(Enums.SCOPE_TYPE.TC_W)
          ? false
          : true,
        auth: Object.assign(
          { ...action.user },
          decodeAccessToken(action.user.access_token)
        )
      };
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        error: action.error
      };
    case actionTypes.USER_REFRESH_TOKEN_PENDING:
      return {
        ...state,
        isTokenRefreshing: true,
        isTokenRefreshed: false,
        isTokenRefreshError: false
      };
    case actionTypes.USER_REFRESH_TOKEN_FULFILLED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: true,
        tokenRefreshedTime: new Date(),
        auth: Object.assign(
          {
            ...action.payload.data,
            access_token: action.payload.data.access_token,
            refresh_token: action.payload.data.refresh_token
          },
          decodeAccessToken(action.payload.data.access_token)
        )
      };
    case actionTypes.USER_REFRESH_TOKEN_REJECTED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: false,
        isTokenRefreshError: true
      };
    case actionTypes.USER_GET_INFO_SUCCESS:
      return {
        ...state,
        category: action.payload.category,
        id: action.payload.id,
        fullName: action.payload.full_name,
        coverage_start_date: action.payload.coverage_start_date,
        coverage_end_date: action.payload.coverage_end_date,
        dob: action.payload.dob,
        email: action.payload.email,
        date_of_employment:
          action.payload.date_of_employment != null
            ? formatDate(action.payload.date_of_employment)
            : null,
        gender: action.payload.gender,
        passport_nric: action.payload.id_no,
        country_of_residence: action.payload.country_of_residence,
        marital_status: action.payload.marital_status,
        designation: action.payload.designation,
        agency_unit: action.payload.agency_unit,
        fc_code: action.payload.fc_code,
        nationality: action.payload.nationality,
        account_id: action.payload.account_id,
        employee_no: action.payload.employee_no,
        existing_insured: action.payload.existing_insured,
        exclusion: action.payload.exclusion,
        height: action.payload.height,
        weight: action.payload.weight,
        occupation: action.payload.occupation
      };
    case actionTypes.USER_GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload
      };

    case actionTypes.EMPLOYEE_GET_INFO_SUCCESS:
      return {
        ...state,
        underwriting_status: action.payload.underwriting_status,
        dependencies: action.payload.dependants.map(formatDependent),
        client_name: action.payload,
        coverage_details: action.payload.coverage_details,
        enrolment_end_date: formatDate(action.payload.enrolment_end_date),
        enrolment_id: action.payload.enrolment_id,
        enrolment_start_date: formatDate(action.payload.enrolment_start_date),
        policy_end_date: formatDate(action.payload.policy_end_date),
        policy_no: action.payload.policy_no,
        policy_start_date: formatDate(action.payload.policy_start_date),
        status: action.payload.status,
        enable_dependent_registration:
          action.payload.enable_dependent_registration,
        enable_self_registration: action.payload.enable_self_registration,
        underwriting_required: action.payload.underwriting_required,
        paymentConsentRequired: action.payload.config.paymentConsentRequired
      };

    case actionTypes.EMPLOYEE_GET_INFO_ERROR:
      return {
        ...state,
        dependencies: []
      };

    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        auth: null,
        loggedIn: false
      };

    case actionTypes.SELECT_PLAN:
      return {
        ...state,
        planSelected: action.payload
        //option:action.payload.option
      };

    case actionTypes.SELECT_DEPENDENT_PLAN:
      return {
        ...state,
        planSelectedDependent: action.payload
      };

    case actionTypes.HANDLE_NEXT:
      return {
        ...state,
        enableNext: action.payload
      };

    case actionTypes.PREMIUM_CALC_EMP:
      return {
        ...state,
        premium: action.payload
      };

    case actionTypes.PREMIUM_CALC_DEP:
      return {
        ...state,
        premiumDep: action.payload
      };

    case actionTypes.DEPENDENT_OPT_OUT:
      return {
        ...state,
        dependencies: action.payload
      };

    case actionTypes.CONSENT:
      return {
        ...state,
        consent: action.payload
      };

    case actionTypes.PAYMENT_CONSENT:
      return {
        ...state,
        paymentConsent: action.payload
      };

    case actionTypes.HDF_CONSENT:
      return {
        ...state,
        hdfConsent: action.payload
      };

    case actionTypes.PAYLOAD:
      return {
        ...state,
        payloadEnrolment: action.payload,
        coverage_details: action.payload.coverage_details
      };
    case actionTypes.UPDATE_USER_DEPENDANTS:
      return {
        ...state,
        dependencies: action.payload
      };
    case actionTypes.EMPLOYEE_OPTION_SELECTED:
      return {
        ...state,
        option: action.payload
      };
    // case actionTypes.DEPENDENT_OPTION_SELECTED:
    //     return {
    //     ...state,
    //     planSelectedDependent: action.payload
    //   };
    case actionTypes.DEPENDENT_OPTOUT_LIST:
      return {
        ...state,
        dependencies: action.payload
      };
    case actionTypes.UPDATE_STATUS:
      return {
        ...state,
        status: action.payload
      };
    case actionTypes.LOAD_PLANS:
      return {
        ...state,
        currentPlans: action.currentPlans,
        previousPlans: action.previousPlans,
        premiumPlans: action.premiumPlans,
        content: action.content,
        depRule: action.depRule,
        previousSelectedPlan: action.previousSelectedPlan,
        placeholderMap: action.placeholderMap,
        rateMatrics: action.rateMatrics,
        hdfData: action.hdfData
      };
    case actionTypes.ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      };
    case actionTypes.SHOW_BADGE:
      return {
        ...state,
        showDependentBadge: action.payload
      };
    case actionTypes.DEPENDENT_BY_RELATION:
      return {
        ...state,
        dependentByRelation: action.payload
      };

    case actionTypes.DEPENDENT_OPT_OUT_LIST:
      return {
        ...state,
        dependentOptOut: action.payload
      };
    case actionTypes.DEPENDENT_OPT_OUT_NAME_LIST:
      return {
        ...state,
        dependentOptOutName: action.payload
      };
    case actionTypes.EMPLOYEE_OPT_OUT_LIST:
      return {
        ...state,
        employeeOptOut: action.payload
      };
    case actionTypes.EMPLOYEE_OPT_OUT_NAME_LIST:
      return {
        ...state,
        employeeOptOutName: action.payload
      };
    case actionTypes.SELECT_HDF_VALUE:
      return {
        ...state,
        questionDepMap: action.payload
      };
    case actionTypes.SELECT_HDF_REMARKS:
      return {
        ...state,
        selectedValue: action.payload
      };

    case actionTypes.OCCUPATION_CLASS_LIST_SUCCESS:
      return {
        ...state,
        occupationClassList: action.payload
      };
    case actionTypes.SA_PREMIUM:
      return {
        ...state,
        empPremiumSA: action.payload
      };
    case actionTypes.DEP_SA_PREMIUM:
      return {
        ...state,
        depPremiumSA: action.payload
      };
    case actionTypes.TOTAL_PREMIUM:
      return {
        ...state,
        totalPremium: action.payload
      };
    case actionTypes.PAYMENT_METHOD:
      return {
        ...state,
        payment: action.payload
      };
    case actionTypes.HDF_PAYLOAD:
      return {
        ...state,
        hdfPayload: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
