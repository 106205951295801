import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { appActions, userActions } from "actions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import HeaderPlain from "components/HeaderPlain";
import { withStyles } from "@mui/styles";
import SimpleMessageDialog from "components/SimpleMessageDialog";
import Enums from "utils/Enums";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const FORMAT_DATE = "YYYY-MM-DD";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeNo: "",
      client: "default",
      labels: {},
      dob: null,
      referenceNo: "",
      email: "",
      confirm_email: "",
      formErrors: new Map(),
      success: false,
      error: false,
      isRegistering: false,
      employeeNoChk: false,
      referenceNoChk: false,
      emailChk: false,
      confirmEmailChk: false,
      dobChk: false
    };
  }

  handleInputChange = event => {
    const target = event.target;
    this.setState({
      [target.id]: target.value
    });
  };

  handleDateChange = date => {
    this.setState({
      dob: (date && moment(date).format(FORMAT_DATE)) || null
    });
    let formErrors = this.state["formErrors"];
    if (formErrors.size > 0) {
      formErrors.delete("dob");
      this.setState({
        formErrors: formErrors,
        dobChk: true
      });
    } else {
      this.setState({
        dobChk: true
      });
    }
  };

  handleBlurDob = date => {
    let formErrors = this.state["formErrors"];
    let emptyDobErrorMsg = "This field could not be empty.";
    //dob validation
    if (this.state["dob"] == null) {
      if (formErrors.size > 0) {
        this.setState({
          formErrors: formErrors.set("dob", emptyDobErrorMsg)
        });
      } else {
        formErrors = new Map();
        this.setState({
          formErrors: formErrors.set("dob", emptyDobErrorMsg)
        });
      }
    } else {
      if (formErrors.size > 0) {
        formErrors.delete("dob");
        this.setState({
          formErrors: formErrors,
          dobChk: true
        });
      }
      this.setState({
        dobChk: true
      });
    }
  };

  handleBlur = event => {
    let formErrors = this.state["formErrors"];
    let labels = this.state.labels;
    let employeeEmptyErrorMsg = labels["registration.employeeNo.emptyMsg"];
    let employeeAlphanumericErrorMsg =
      labels["registration.employeeNo.alphanumericErrorMsg"];
    let referenceEmptyErrorMsg = labels["registration.referenceNo.emptyMsg"];
    let referenceAlphanumericErrorMsg =
      labels["registration.referenceNo.alphanumericErrorMsg"];
    let referenceCharErrorMsg = labels["registration.referenceNo.charErrorMsg"];
    let emailEmptyErrorMsg = labels["registration.email.emptyMsg"];
    let emailInvalidErrorMsg = labels["registration.email.invalidMsg"];
    let confirmationEmailEmptyMsg =
      labels["registration.confirmEmail.emptyMsg"];
    let confirmationEmailInvalidMsg =
      labels["registration.confirmEmail.invalidMsg"];
    if (event.target.id == "employeeNo") {
      // employee number empty and alphanumeric validation
      if (this.state["employeeNo"] == "") {
        if (formErrors.size > 0) {
          this.setState({
            formErrors: formErrors.set("employeeNo", employeeEmptyErrorMsg)
          });
        } else {
          formErrors = new Map();
          this.setState({
            formErrors: formErrors.set("employeeNo", employeeEmptyErrorMsg)
          });
        }
      } else {
        let isInvalid;
        let meRegex = /[^\w]|_/g;
        isInvalid = meRegex.test(this.state["employeeNo"]);
        if (isInvalid) {
          if (formErrors.size > 0) {
            this.setState({
              formErrors: formErrors.set(
                "employeeNo",
                employeeAlphanumericErrorMsg
              )
            });
          } else {
            formErrors = new Map();
            this.setState({
              formErrors: formErrors.set(
                "employeeNo",
                employeeAlphanumericErrorMsg
              )
            });
          }
        } else {
          if (this.state["formErrors"].size > 0) {
            formErrors.delete("employeeNo");
            this.setState({
              formErrors: formErrors,
              employeeNoChk: true
            });
          }
          this.setState({
            employeeNoChk: true
          });
        }
      }
    }

    if (event.target.id == "referenceNo") {
      // reference no empty and alphanumeric validation
      if (this.state["referenceNo"] == "") {
        if (formErrors.size > 0) {
          this.setState({
            formErrors: formErrors.set("referenceNo", referenceEmptyErrorMsg)
          });
        } else {
          formErrors = new Map();
          this.setState({
            formErrors: formErrors.set("referenceNo", referenceEmptyErrorMsg)
          });
        }
      } else {
        let isInvalid;
        let meRegex = /[^\w]|_/g;
        isInvalid = meRegex.test(this.state["referenceNo"]);
        if (isInvalid) {
          if (formErrors.size > 0) {
            this.setState({
              formErrors: formErrors.set(
                "referenceNo",
                referenceAlphanumericErrorMsg
              )
            });
          } else {
            formErrors = new Map();
            this.setState({
              formErrors: formErrors.set(
                "referenceNo",
                referenceAlphanumericErrorMsg
              )
            });
          }
        } else {
          // 8 char
          let isValid;
          let meRegex = /^.{8,8}$/;
          isValid = meRegex.test(this.state["referenceNo"]);
          if (!isValid) {
            if (formErrors.size > 0) {
              this.setState({
                formErrors: formErrors.set("referenceNo", referenceCharErrorMsg)
              });
            } else {
              formErrors = new Map();
              this.setState({
                formErrors: formErrors.set("referenceNo", referenceCharErrorMsg)
              });
            }
          } else {
            if (this.state["formErrors"].size > 0) {
              formErrors.delete("referenceNo");
              this.setState({
                formErrors: formErrors,
                referenceNoChk: true
              });
            }
            this.setState({
              referenceNoChk: true
            });
          }
        }
      }
    }

    if (event.target.id == "email") {
      // email empty and validity validation validation on if the confirm email address is the same
      if (this.state["email"] == "") {
        if (formErrors.size > 0) {
          this.setState({
            formErrors: formErrors.set("email", emailEmptyErrorMsg)
          });
        } else {
          formErrors = new Map();
          this.setState({
            formErrors: formErrors.set("email", emailEmptyErrorMsg)
          });
        }
      } else {
        let isValid;
        let meRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        let email = this.state["email"].toLowerCase();
        isValid = meRegex.test(email);
        if (!isValid) {
          if (formErrors.size > 0) {
            this.setState({
              formErrors: formErrors.set("email", emailInvalidErrorMsg)
            });
          } else {
            formErrors = new Map();
            this.setState({
              formErrors: formErrors.set("email", emailInvalidErrorMsg)
            });
          }
        } else {
          if (this.state["confirm_email"] !== "") {
            if (this.state["confirm_email"] !== this.state["email"]) {
              if (formErrors.size > 0) {
                this.setState({
                  formErrors: formErrors.set(
                    "confirm_email",
                    confirmationEmailInvalidMsg
                  )
                });
              } else {
                formErrors = new Map();
                this.setState({
                  formErrors: formErrors.set(
                    "confirm_email",
                    confirmationEmailInvalidMsg
                  )
                });
              }
            } else {
              if (this.state["formErrors"].size > 0) {
                formErrors.delete("confirm_email");
                this.setState({
                  formErrors: formErrors,
                  confirmEmailChk: true
                });
              }
            }
          }
          if (formErrors.size > 0) {
            formErrors.delete("email");
            this.setState({
              formErrors: formErrors,
              emailChk: true
            });
          }
          this.setState({
            emailChk: true
          });
        }
      }
    }

    if (event.target.id == "confirm_email") {
      // confirmation email validation
      if (this.state["confirm_email"] == "") {
        // if (formErrors.size > 0) {
        //   this.setState({
        //     formErrors: formErrors.set(
        //       "confirm_email",
        //       confirmationEmailEmptyMsg
        //     )
        //   });
        // } else {
        //   this.setState({
        //     formErrors: formErrors.set(
        //       "confirm_email",
        //       confirmationEmailEmptyMsg
        //     )
        //   });
        // }
        this.setState({
          formErrors: formErrors.set("confirm_email", confirmationEmailEmptyMsg)
        });
      } else {
        if (this.state["confirm_email"] !== this.state["email"]) {
          if (formErrors.size > 0) {
            this.setState({
              formErrors: formErrors.set(
                "confirm_email",
                confirmationEmailInvalidMsg
              )
            });
          } else {
            formErrors = new Map();
            this.setState({
              formErrors: formErrors.set(
                "confirm_email",
                confirmationEmailInvalidMsg
              )
            });
          }
        } else {
          if (this.state["formErrors"].size > 0) {
            formErrors.delete("confirm_email");
            this.setState({
              formErrors: formErrors,
              confirmEmailChk: true
            });
          }
          this.setState({
            confirmEmailChk: true
          });
        }
      }
    }
  };

  handleClose() {
    this.setState({ success: false });
    this.props.history.push("/");
  }

  handleCloseError() {
    this.setState({ error: false });
  }

  submit = values => {
    // no errors server side validation
    if (
      this.state["employeeNoChk"] &&
      this.state["referenceNoChk"] &&
      this.state["confirmEmailChk"] &&
      this.state["emailChk"] &&
      this.state["dobChk"]
    ) {
      if (
        this.state["formErrors"].size == 0 ||
        this.state["formErrors"].size == undefined
      ) {
        values.employeeNo = this.state["employeeNo"];
        values.referenceNo = this.state["referenceNo"];
        values.dob = new Date(this.state["dob"]).toLocaleDateString("en-GB");
        values.email = this.state["email"];
        this.setState({ isRegistering: true });
        this.props
          .register(
            values.employeeNo,
            values.dob,
            values.referenceNo,
            values.email
          )
          .then(
            resp => {
              if (resp.success)
                this.setState({
                  success: true,
                  isRegistering: false,
                  email: values.email
                });
              else
                this.setState({
                  isRegistering: false,
                  errorMessage: resp.message
                });
            },
            error => {
              this.setState({
                error: true,
                isRegistering: false,
                errorMessage: error.customMessage || error.message
              });
            }
          );
      }
    }
  };

  componentWillMount() {
    // this.props.reset();
  }

  render() {
    const { classes, user } = this.props;
    const {
      email,
      success,
      error,
      errorMessage,
      formErrors,
      isRegistering
    } = this.state;
    let registerDisallow = true;

    if (
      this.state["employeeNoChk"] &&
      this.state["referenceNoChk"] &&
      this.state["confirmEmailChk"] &&
      this.state["emailChk"] &&
      this.state["dobChk"]
    ) {
      if (
        this.state["formErrors"].size == 0 ||
        this.state["formErrors"].size == undefined
      ) {
        registerDisallow = false;
      } else {
        registerDisallow = true;
      }
    }
    this.state.client = this.props.match.params.id;
    let validClientName = Enums.VALID_CLIENT_NAME;
    if (!validClientName.includes(this.state.client)) {
      this.props.history.push("/notfound");
    }
    let labels = this.props.loadDefaultLabels(this.state.client)
      .registration_labels;
    this.state.labels = labels;
    return (
      <div className="registration">
        <HeaderPlain />
        <div className={classes.wrapper}>
          <Paper className={classes.root} elevation={2}>
            <div style={{ padding: 20 }}>
              <Typography variant="title" className={classes.titleBoxText}>
                Registration
              </Typography>
            </div>

            <div>
              <Grid container spacing={"24px"}>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ padding: "10px 10%" }}>
                    <TextField
                      fullWidth
                      style={{ paddingRight: "20px" }}
                      required
                      id="employeeNo"
                      // label= {<TooltipEmployeeNo/>}
                      label="Employee No"
                      placeholder="Enter EmployeeNo"
                      margin="normal"
                      helperText={labels["registration.employeeNo.hint"]}
                      inputProps={{
                        maxLength: 10
                      }}
                      onChange={this.handleInputChange}
                      onBlur={this.handleBlur}
                    />
                    <br />
                    <label
                      className={classes.validationError}
                      style={{ marginLeft: "0px" }}
                    >
                      {formErrors.get("employeeNo") !== undefined
                        ? formErrors.get("employeeNo")
                        : ""}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ padding: "10px 10%" }}>
                    <DatePicker
                      fullWidth
                      style={{ paddingRight: "20px" }}
                      id="dob"
                      required
                      label="Date Of Birth"
                      placeholder="Enter Date of Birth"
                      // type="date"
                      value={this.state["dob"]}
                      format="DD/MM/YYYY"
                      animateYearScrolling={true}
                      keyboard
                      margin="normal"
                      helperText={labels["registration.dob.hint"]}
                      onChange={this.handleDateChange}
                      onBlur={this.handleBlurDob}
                      maxDate={new Date()}
                      // InputProps={{
                      //   inputProps: {
                      //     max: new Date().toISOString().slice(0, 10)
                      //   }
                      // }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <br />
                    <label
                      className={classes.validationError}
                      style={{ marginLeft: "0px" }}
                    >
                      {formErrors.get("dob") !== undefined
                        ? formErrors.get("dob")
                        : ""}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ padding: "10px 10%" }}>
                    <TextField
                      fullWidth
                      style={{ paddingRight: "20px" }}
                      required
                      id="referenceNo"
                      label="Company Reference No"
                      placeholder="Enter Company Reference No"
                      inputProps={{
                        maxLength: 8
                      }}
                      margin="normal"
                      helperText={labels["registration.referenceNo.hint"]}
                      onChange={this.handleInputChange}
                      onBlur={this.handleBlur}
                    />
                    <label
                      className={classes.validationError}
                      style={{ marginLeft: "0px" }}
                    >
                      {formErrors.get("referenceNo") !== undefined
                        ? formErrors.get("referenceNo")
                        : ""}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ padding: "10px 10%" }}>
                    <TextField
                      fullWidth
                      style={{ paddingRight: "20px" }}
                      required
                      id="email"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      onCopy={function(e) {
                        e.preventDefault();
                      }}
                      onPaste={function(e) {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      type="email"
                      margin="normal"
                      helperText={labels["registration.email.hint"]}
                      onChange={this.handleInputChange}
                      onBlur={this.handleBlur}
                    />
                    <br />
                    <label
                      className={classes.validationError}
                      style={{ marginLeft: "0px" }}
                    >
                      {formErrors.get("email") !== undefined
                        ? formErrors.get("email")
                        : ""}
                    </label>
                    {/* {formErrors.get("confirm_email") !== undefined &&
                    formErrors.get("confirm_email") !==
                      "Email Address Mismatch" ? (
                      <label
                        className={classes.validationError}
                        style={{ marginLeft: "0px" }}
                      >
                        {formErrors.get("confirm_email") !== undefined
                          ? formErrors.get("confirm_email")
                          : ""}
                      </label>
                    ) : (
                      <label
                        className={classes.validationError}
                        style={{ marginLeft: "0px" }}
                      >
                        {formErrors.get("email") !== undefined
                          ? formErrors.get("email")
                          : ""}
                      </label>
                    )} */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ padding: "10px 10%" }}>
                    <TextField
                      fullWidth
                      style={{ paddingRight: "20px" }}
                      required
                      id="confirm_email"
                      label="Confirm Email Address"
                      placeholder="Confirm your email address"
                      onCopy={function(e) {
                        e.preventDefault();
                      }}
                      onPaste={function(e) {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      type="password"
                      margin="normal"
                      onChange={this.handleInputChange}
                      onBlur={this.handleBlur}
                    />
                    <br />
                    <label
                      className={classes.validationError}
                      style={{ marginLeft: "0px" }}
                    >
                      {formErrors.get("confirm_email") !== undefined
                        ? formErrors.get("confirm_email")
                        : ""}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>
            <br />
            <div className={classes.register}>
              <Fade
                in={isRegistering}
                style={{
                  transitionDelay: isRegistering ? "40ms" : "0ms"
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
            </div>
            <div className={classes.register} style={{ padding: 20 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={registerDisallow}
                onClick={this.submit}
              >
                Register
              </Button>
            </div>
            <br />
          </Paper>
        </div>
        <SimpleMessageDialog
          type="success"
          name="register-successfully"
          title="Registration Successful!"
          description={`Your account has been registered, an activation email has sent to your email at ${email}`}
          closeButtonText="OK"
          closeHandler={this.handleClose.bind(this)}
          open={success}
        />
        <SimpleMessageDialog
          type="error"
          name="register-fail"
          title="Registration Fail!"
          description={`${errorMessage}`}
          closeButtonText="Close"
          closeHandler={this.handleCloseError.bind(this)}
          open={error}
        />
      </div>
    );
  }
}

const styles = theme => ({
  wrapper: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 300,
    right: 0,
    width: 550,
    height: 400,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 310
    }
  },
  titleBoxText: {
    textAlign: "center"
  },
  register: {
    textAlign: "center"
  },
  root: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5)
  },
  successIcon: {
    fontSize: 50,
    marginBottom: 20,
    color: "green"
  },
  successContent: {
    textAlign: "center"
  },
  validationError: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    position: "absolute",
    fontFamily: "OpenSans",
    lineHeight: "1em"
  },
  errorMessage: {
    marginBottom: theme.spacing.unit,
    marginLeft: "50px",
    color: theme.palette.error.main
  }
});

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  // validate,
  withStyles(styles)(Register)
);
