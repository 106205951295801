import Enums from "utils/Enums";
var bedImage = require("assets/img/dashboard/bed_img.png").default;

export default {
  premium: [
    {
      header: "Plan 1",
      option: "001",
      details: [
        {
          header: "Medical Benefits GHSB & GSOP",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 529
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 0
            }
          ]
        },
        {
          header: "Group Supplementary Major Medical (GSMM) Benefits",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 529
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 0
            }
          ]
        }
      ]
    },
    {
      header: "Plan 2",
      option: "002",
      details: [
        {
          header: "Medical Benefits GHSB & GSOP",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 529
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 0
            }
          ]
        },
        {
          header: "Group Supplementary Major Medical (GSMM) Benefits",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 317
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 317
            }
          ]
        }
      ]
    },
    {
      header: "Plan 3",
      option: "003",
      details: [
        {
          header: "Medical Benefits GHSB & GSOP",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 317
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 317
            }
          ]
        },
        {
          header: "Group Supplementary Major Medical (GSMM) Benefits",
          details: [
            {
              header: "Spouse Only",
              label: "for your Spouse",
              premium: 317
            },
            {
              header: "Child(ren) Only",
              label: "for your Child(ren)",
              premium: 317
            },
            {
              header: "Spouse & Child(ren)",
              label: "for your Spouse + Child(ren)",
              premium: 317
            },
            {
              header: "Employee",
              label: "Opt Out All Dependents",
              premium: 317
            }
          ]
        }
      ]
    }
    // {
    //   header: "Plan 2",
    //   option: '002',
    //   details: {
    //     header: "GHS & GSMM",
    //     details: [
    //       {
    //         header: "Spouse Only",
    //         label: "for your Spouse",
    //         premiumGHS: 581.25,
    //         premiumGSMM: 315
    //       },
    //       {
    //         header: "Child(ren) Only",
    //         label: "for your Child(ren)",
    //         premiumGHS: 581.25,
    //         premiumGSMM: 315
    //       },
    //       {
    //         header: "Spouse & Child(ren)",
    //         label: "for your Spouse + Child(ren)",
    //         premiumGHS: 1162.50,
    //         premiumGSMM: 525
    //       },
    //       {
    //         header: "Employee",
    //         label: "Opt Out All Dependents",
    //         premiumGHS: 90,
    //         premiumGSMM: 84
    //       },

    //     ]
    //   }
    // },
    // {
    //   header: "Plan 3",
    //   option: '003',
    //   details: {
    //     header: "GHS & GSMM",
    //     details: [
    //       {
    //         header: "Spouse Only",
    //         label: "for your Spouse",
    //         premiumGHS: 782.50,
    //         premiumGSMM: 402
    //       },
    //       {
    //         header: "Child(ren) Only",
    //         label: "for your Child(ren)",
    //         premiumGHS: 782.50,
    //         premiumGSMM: 402
    //       },
    //       {
    //         header: "Spouse & Child(ren)",
    //         label: "for your Spouse + Child(ren)",
    //         premiumGHS: 1565,
    //         premiumGSMM: 670
    //       },
    //       {
    //         header: "Employee",
    //         label: "Opt Out All Dependents",
    //         premiumGHS: 251,
    //         premiumGSMM: 142
    //       },

    //     ]
    //   }
    // }
  ],
  previous: [
    // {
    //   header: "Plan 1",
    //   option: '001',
    //   details: [
    //     // {
    //     //   overview: {
    //     //     header: "Overview",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "Coverage"
    //     //         ],
    //     //         details: [
    //     //           "Group Hospital & Surgical (GHS)",
    //     //           "Group Supplementary Major Medical (GSMM)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "Types of Hospital"
    //     //         ],
    //     //         details: [
    //     //           "Private Hospital (PTE)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "No. of Beds in Room"
    //     //         ],
    //     //         details: [
    //     //           {
    //     //             image: bedImage,
    //     //             number: 2
    //     //           }
    //     //         ]
    //     //       }
    //     //     ]
    //     //   },
    //     // },
    //     {
    //       overview: {
    //         header: "Group Hospital & Surgical (GHS) Benifits",
    //         details: [
    //           {
    //             header: [
    //               "1A. Hospital Room & Board"
    //             ],
    //             details: [
    //               "2 beds in Private Hospital"
    //             ]
    //           },
    //           {
    //             header: [
    //               "1B. Intensive Care Unit (ICU)"
    //             ],
    //             details: [
    //               "3 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "1C. High Dependency Ward (HDW)"
    //             ],
    //             details: [
    //               "2 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2. In-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               " 2A. Other Hospital Services* \n"
    //             ],
    //             details: [
    //               "5,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 2B. Surgical Fees"
    //             ],
    //             details: [
    //               "7,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 2C. In-Hospital Doctor’s Visit*"
    //             ],
    //             details: [
    //               "$150 per day"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3. Out-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
    //               " 3B. Pre-hospitalization Specialists’ Consultation & Medication*",
    //               " 3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
    //             ],
    //             details: [
    //               "      \n",
    //               "  1,500 ",
    //               "      \n"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3D. Emergency Outpatient Treatment"
    //             ],
    //             details: [
    //               "1,250"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3E. Dental - removal of impacted teeth / fractured jaw"
    //             ],
    //             details: [
    //               "1,500"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3F. Rehabilitation Benefit"
    //             ],
    //             details: [
    //               "5,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3G. Inpatient Psychiatric Treatment"
    //             ],
    //             details: [
    //               "1,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3H. Overall Limits per disability for admission to Government Restructured Hospitals"
    //             ],
    //             details: [
    //               "25,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               " 3I. Death Benefit"
    //             ],
    //             details: [
    //               "3,000"
    //             ]
    //           }
    //         ]
    //       }
    //     },
    //     // {
    //     //   overview: {
    //     //     header: "Group Supplementary Major Medical (GSMM) Benefits",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "1A. Hospital Room & Board*"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1B. Other In-Hospital Benefits"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1C. Deductible"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
    //     //         ],
    //     //         details: [
    //     //           "20,000"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1E. Parental Accommodation of child age below 12 years of age*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1F. Daily Home Nursing*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2. Co-insurance"
    //     //         ],
    //     //         details: [
    //     //           ""
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2A. Insured",
    //     //           "2B. Prudential"
    //     //         ],
    //     //         details: [
    //     //           "15%",
    //     //           "85%"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "3. Subject to overall maximum benefit payable per disability"
    //     //         ],
    //     //         details: [
    //     //           "40,000"
    //     //         ]
    //     //       },
    //     //     ]
    //     //   }
    //     // }
    //   ]
    // },
    // {
    //   header: "Plan 2",
    //   option: '002',
    //   details: [
    //     // {
    //     //   overview: {
    //     //     header: "Overview",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "Coverage"
    //     //         ],
    //     //         details: [
    //     //           "Group Hospital & Surgical (GHS)",
    //     //           "Group Supplementary Major Medical (GSMM)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "Types of Hospital"
    //     //         ],
    //     //         details: [
    //     //           "Government Restructured Hospital (GRH)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "No. of Beds in Room"
    //     //         ],
    //     //         details: [
    //     //           {
    //     //             image: bedImage,
    //     //             number: 1
    //     //           }
    //     //         ]
    //     //       }
    //     //     ]
    //     //   }
    //     // },
    //     {
    //       overview: {
    //         header: "Group Hospital & Surgical (GHS) Benifits",
    //         details: [
    //           {
    //             header: [
    //               "Hospital Room & Board"
    //             ],
    //             details: [
    //               "1 bed in Government Restructured Hospital"
    //             ]
    //           },
    //           {
    //             header: [
    //               "Intensive Care Unit (ICU)"
    //             ],
    //             details: [
    //               "3 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "High Dependency Ward (HDW)"
    //             ],
    //             details: [
    //               "2 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2. In-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               "2A. Other Hospital Services * \n"
    //             ],
    //             details: [
    //               "6,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2B. Surgical Fees"
    //             ],
    //             details: [
    //               "8,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2C. In-Hospital Doctor’s Visit*"
    //             ],
    //             details: [
    //               "$175 per day"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3. Out-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               "3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
    //               "3B. Pre-hospitalization Specialists’ Consultation & Medication*",
    //               "3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
    //             ],
    //             details: [
    //               "      \n",
    //               "  1,750 ",
    //               "      \n"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3D. Emergency Outpatient Treatment"
    //             ],
    //             details: [
    //               "1,750"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3E. Dental - removal of impacted teeth / fractured jaw"
    //             ],
    //             details: [
    //               "1,500"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3F. Rehabilitation Benefit"
    //             ],
    //             details: [
    //               "5,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3G. Inpatient Psychiatric Treatment"
    //             ],
    //             details: [
    //               "1,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3H. Overall Limits per disability for admission to Government Restructured Hospitals"
    //             ],
    //             details: [
    //               "30,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3I. Death Benefit"
    //             ],
    //             details: [
    //               "3,000"
    //             ]
    //           }
    //         ]
    //       }
    //     },
    //     // {
    //     //   overview: {
    //     //     header: "Group Supplementary Major Medical (GSMM) Benefits",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "1A. Hospital Room & Board*"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1B. Other In-Hospital Benefits"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1C. Deductible"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
    //     //         ],
    //     //         details: [
    //     //           "20,000"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1E. Parental Accommodation of child age below 12 years of age*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1F. Daily Home Nursing*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2. Co-insurance"
    //     //         ],
    //     //         details: [
    //     //           ""
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2A. Insured",
    //     //           "2B. Prudential"
    //     //         ],
    //     //         details: [
    //     //           "15%",
    //     //           "85%"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "3. Subject to overall maximum benefit payable per disability"
    //     //         ],
    //     //         details: [
    //     //           "40,000"
    //     //         ]
    //     //       },
    //     //     ]
    //     //   }
    //     // }
    //   ]
    // },
    // {
    //   header: "Plan 3",
    //   option: '003',
    //   details: [
    //     // {
    //     //   overview: {
    //     //     header: "Overview",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "Coverage"
    //     //         ],
    //     //         details: [
    //     //           "Group Hospital & Surgical (GHS)",
    //     //           "Group Supplementary Major Medical (GSMM)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "Types of Hospital"
    //     //         ],
    //     //         details: [
    //     //           "Private Hospital (PTE)"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "No. of Beds in Room"
    //     //         ],
    //     //         details: [
    //     //           {
    //     //             image: bedImage,
    //     //             number: 1
    //     //           }
    //     //         ]
    //     //       }
    //     //     ]
    //     //   },
    //     // },
    //     {
    //       overview: {
    //         header: "Group Hospital & Surgical (GHS) Benifits",
    //         details: [
    //           {
    //             header: [
    //               "Hospital Room & Board"
    //             ],
    //             details: [
    //               "1 bed in Private Hospital"
    //             ]
    //           },
    //           {
    //             header: [
    //               "Intensive Care Unit (ICU)"
    //             ],
    //             details: [
    //               "3 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "High Dependency Ward (HDW)"
    //             ],
    //             details: [
    //               "2 times Hospital Room & Board"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2. In-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               "2A. Other Hospital Services* \n"
    //             ],
    //             details: [
    //               "7,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2B. Surgical Fees"
    //             ],
    //             details: [
    //               "9,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "2C. In-Hospital Doctor’s Visit*"
    //             ],
    //             details: [
    //               "$200 per day"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3. Out-Patient Expenses: \n"
    //             ],
    //             details: [
    //               ""
    //             ]
    //           },
    //           {
    //             header: [
    //               "3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
    //               "3B. Pre-hospitalization Specialists’ Consultation & Medication*",
    //               "3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
    //             ],
    //             details: [
    //               "      \n",
    //               "  2,000 \n",
    //               "      \n"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3D. Emergency Outpatient Treatment"
    //             ],
    //             details: [
    //               "2,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3E. Dental - removal of impacted teeth / fractured jaw"
    //             ],
    //             details: [
    //               "1,500"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3F. Rehabilitation Benefit"
    //             ],
    //             details: [
    //               "5,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3G. Inpatient Psychiatric Treatment"
    //             ],
    //             details: [
    //               "1,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3H. Overall Limits per disability for admission to Government Restructured Hospitals"
    //             ],
    //             details: [
    //               "35,000"
    //             ]
    //           },
    //           {
    //             header: [
    //               "3I. Death Benefit"
    //             ],
    //             details: [
    //               "3,000"
    //             ]
    //           }
    //         ]
    //       },
    //     },
    //     // {
    //     //   overview: {
    //     //     header: "Group Supplementary Major Medical (GSMM) Benefits",
    //     //     details: [
    //     //       {
    //     //         header: [
    //     //           "1A. Hospital Room & Board*"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1B. Other In-Hospital Benefits"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1C. Deductible"
    //     //         ],
    //     //         details: [
    //     //           "As per GHS Plan"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
    //     //         ],
    //     //         details: [
    //     //           "25,000"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1E. Parental Accommodation of child age below 12 years of age *"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1F. Daily Home Nursing*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
    //     //         ],
    //     //         details: [
    //     //           "N/A"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2. Co-insurance"
    //     //         ],
    //     //         details: [
    //     //           ""
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "2A. Insured",
    //     //           "2B. Prudential"
    //     //         ],
    //     //         details: [
    //     //           "15%",
    //     //           "85%"
    //     //         ]
    //     //       },
    //     //       {
    //     //         header: [
    //     //           "3. Subject to overall maximum benefit payable per disability"
    //     //         ],
    //     //         details: [
    //     //           "40,000"
    //     //         ]
    //     //       },
    //     //     ]
    //     //   }
    //     // }
    //   ]
    // }
  ],
  current: [
    {
      planType: "Medical Benefits GHSB & GSOP",
      coverageId: "GHSB",
      planDetails: [
        {
          header: "Plan 1",
          option: "001",
          coverageId: "GHSB",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GHS Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["2 bed in Private Hospital"]
                  },
                  {
                    header: ["1B. Intensive Care Unit (ICU)*"],
                    details: ["3 times Hospital Room & Board"]
                  },
                  {
                    header: ["1C. High Dependency Ward (HDW)*"],
                    details: ["2 times Hospital Room & Board"]
                  },
                  {
                    header: ["2. In-Patient Expenses: "],
                    details: [""]
                  },
                  {
                    header: ["2A. Other Hospital Services* "],
                    details: ["5,000"]
                  },
                  {
                    header: ["2B. Surgical Fees"],
                    details: ["7,000"]
                  },
                  {
                    header: ["2C. In-Hospital Doctor’s Visit*"],
                    details: ["$150 per day"]
                  },
                  {
                    header: ["3. Out-Patient Expenses: \n"],
                    details: [""]
                  },
                  {
                    header: [
                      "3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
                      "3B. Pre-hospitalization Specialists’ Consultation & Medication*",
                      "3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
                    ],
                    details: ["      \n", "  1,500 \n", "      \n"]
                  },
                  {
                    header: ["3D. Emergency Outpatient Treatment"],
                    details: ["1,250"]
                  },
                  {
                    header: [
                      "3E. Dental - removal of impacted teeth / fractured jaw"
                    ],
                    details: ["1,500"]
                  },
                  {
                    header: ["3F. Rehabilitation Benefit"],
                    details: ["5,000"]
                  },
                  {
                    header: ["3G. Inpatient Psychiatric Treatment"],
                    details: ["1,000"]
                  },
                  {
                    header: [
                      "3H. Overall Limits per disability for admission to Government Restructured Hospitals"
                    ],
                    details: ["25,000"]
                  },
                  {
                    header: ["3I. Death Benefit"],
                    details: ["3,000"]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GSOP Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["20,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["40,000"]
                  }
                ]
              }
            }
          ]
        },
        {
          header: "Plan 2",
          option: "002",
          coverageId: "GHSB",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GHS Benefits",
                details: [
                  {
                    header: ["Hospital Room & Board"],
                    details: ["1 bed in Government Restructured Hospital"]
                  },
                  {
                    header: ["Intensive Care Unit (ICU)"],
                    details: ["3 times Hospital Room & Board"]
                  },
                  {
                    header: ["High Dependency Ward (HDW)"],
                    details: ["2 times Hospital Room & Board"]
                  },
                  {
                    header: ["2. In-Patient Expenses: \n"],
                    details: [""]
                  },
                  {
                    header: ["2A. Other Hospital Services* \n"],
                    details: ["6,000"]
                  },
                  {
                    header: ["2B. Surgical Fees"],
                    details: ["8,000"]
                  },
                  {
                    header: ["2C. In-Hospital Doctor’s Visit*"],
                    details: ["$175 per day"]
                  },
                  {
                    header: ["3. Out-Patient Expenses: \n"],
                    details: [""]
                  },
                  {
                    header: [
                      "3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
                      "3B. Pre-hospitalization Specialists’ Consultation & Medication*",
                      "3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
                    ],
                    details: ["      \n", "  1,750 \n", "      \n"]
                  },
                  {
                    header: ["3D. Emergency Outpatient Treatment"],
                    details: ["1,750"]
                  },
                  {
                    header: [
                      "3E. Dental - removal of impacted teeth / fractured jaw"
                    ],
                    details: ["1,500"]
                  },
                  {
                    header: ["3F. Rehabilitation Benefit"],
                    details: ["5,000"]
                  },
                  {
                    header: ["3G. Inpatient Psychiatric Treatment"],
                    details: ["1,000"]
                  },
                  {
                    header: [
                      "3H. Overall Limits per disability for admission to Government Restructured Hospitals"
                    ],
                    details: ["30,000"]
                  },
                  {
                    header: ["3I. Death Benefit"],
                    details: ["3,000"]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GSOP Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["20,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["40,000"]
                  }
                ]
              }
            }
          ]
        },
        {
          header: "Plan 3",
          option: "003",
          coverageId: "GHSB",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GHS Benefits",
                details: [
                  {
                    header: ["Hospital Room & Board"],
                    details: ["1 bed in Private Hospital"]
                  },
                  {
                    header: ["Intensive Care Unit (ICU)"],
                    details: ["3 times Hospital Room & Board"]
                  },
                  {
                    header: ["High Dependency Ward (HDW)"],
                    details: ["2 times Hospital Room & Board"]
                  },
                  {
                    header: ["2. In-Patient Expenses: \n"],
                    details: [""]
                  },
                  {
                    header: ["2A. Other Hospital Services* \n"],
                    details: ["7,000"]
                  },
                  {
                    header: ["2B. Surgical Fees"],
                    details: ["9,000"]
                  },
                  {
                    header: ["2C. In-Hospital Doctor’s Visit*"],
                    details: ["$200 per day"]
                  },
                  {
                    header: ["3. Out-Patient Expenses: \n"],
                    details: [""]
                  },
                  {
                    header: [
                      "3A. Pre-hospitalization Diagnostics X-ray & Lab Test* \n",
                      "3B. Pre-hospitalization Specialists’ Consultation & Medication*",
                      "3C. Post-hospitalization treatment Inclusive of TCM (only available in 2020)*"
                    ],
                    details: ["       \n", "  2,000 \n", "      \n"]
                  },
                  {
                    header: ["3D. Emergency Outpatient Treatment"],
                    details: ["2,000"]
                  },
                  {
                    header: [
                      "3E. Dental - removal of impacted teeth / fractured jaw"
                    ],
                    details: ["1,500"]
                  },
                  {
                    header: ["3F. Rehabilitation Benefit"],
                    details: ["5,000"]
                  },
                  {
                    header: ["3G. Inpatient Psychiatric Treatment"],
                    details: ["1,000"]
                  },
                  {
                    header: [
                      "3H. Overall Limits per disability for admission to Government Restructured Hospitals"
                    ],
                    details: ["35,000"]
                  },
                  {
                    header: ["3I. Death Benefit"],
                    details: ["3,000"]
                  }
                ]
              }
            },
            {
              overview: {
                header: "GSOP Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["20,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["40,000"]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      planType: "Group Supplementary Major Medical (GSMM) Benefits",
      coverageId: "GSMM",
      planDetails: [
        {
          header: "Plan 1",
          option: "001",
          coverageId: "GSMM",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "More Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["20,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["40,000"]
                  }
                ]
              }
            }
          ]
        },
        {
          header: "Plan 2",
          option: "002",
          coverageId: "GSMM",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "More Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["20,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["60,000"]
                  }
                ]
              }
            }
          ]
        },
        {
          header: "Plan 3",
          option: "003",
          coverageId: "GSMM",
          details: [
            {
              overview: {
                header: "Overview",
                details: [
                  {
                    header: ["Coverage"],
                    details: [
                      "Group Hospital & Surgical (GHS)",
                      "Group Supplementary Major Medical (GSMM)"
                    ]
                  },
                  {
                    header: ["Types of Hospital"],
                    details: ["Private Hospital (PTE)"]
                  },
                  {
                    header: ["No. of Beds in Room"],
                    details: [
                      {
                        image: bedImage,
                        number: 2
                      }
                    ]
                  }
                ]
              }
            },
            {
              overview: {
                header: "More Benefits",
                details: [
                  {
                    header: ["1A. Hospital Room & Board*"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1B. Other In-Hospital Benefits"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: ["1C. Deductible"],
                    details: ["As per GHS Plan"]
                  },
                  {
                    header: [
                      "1D. Outpatient Kidney Dialysis, Cancer Treatment*"
                    ],
                    details: ["25,000"]
                  },
                  {
                    header: [
                      "1E. Parental Accommodation of child age below 12 years of age*"
                    ],
                    details: ["100 per day"]
                  },
                  {
                    header: ["1F. Daily Home Nursing*"],
                    details: ["120 per day"]
                  },
                  {
                    header: [
                      "1G. HIV Aids Treatment due to occupational and accidental blood transfusion*"
                    ],
                    details: ["5,000"]
                  },
                  {
                    header: ["2. Co-insurance"],
                    details: [""]
                  },
                  {
                    header: ["2A. Insured", "2B. Prudential"],
                    details: ["15%", "85%"]
                  },
                  {
                    header: [
                      "3. Subject to overall maximum benefit payable per disability"
                    ],
                    details: ["80,000"]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
};
