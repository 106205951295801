import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleMessageDialog from "./SimpleMessageDialog";
import moment from "moment";
import Idle from "react-idle";

import { userActions } from "actions";

class SessionExpireDialog extends Component {
  state = {
    open: false,
    idle: false
  };

  componentDidMount() {
    if (this.props.user.auth) {
      const intervalId = setInterval(this.handleRefreshToken, 1000 * 60);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    // Destroy interval
    clearInterval(this.state.intervalId);
  }

  handleClose = () => {
    const refresh_token = this.props.user.auth;
    this.props.refreshToken(refresh_token);
    this.props.logout();
    this.props.history.push("/");
  };

  handleIdle = ({ idle }) => {
    //if idle set session expire dialog open
    if (idle) {
      this.setState({ open: true, idle: true });
      clearInterval(this.state.intervalId);
    } else this.setState({ idle: false });
  };

  handleRefreshToken = () => {
    const { tokenRefreshTimeInMin, user } = this.props;
    const { refresh_token, expire_time } = user.auth;
    const { idle } = this.state;

    const expire = moment(expire_time);
    const diffInMinute = expire.diff(moment(), "minutes");
    if (
      !user.isTokenRefreshing &&
      !idle &&
      tokenRefreshTimeInMin > diffInMinute
    ) {
      this.props.refreshToken(refresh_token);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.app.error &&
      nextProps.app.error.code === 401 &&
      nextProps.app.error.message.includes("Invalid token")
    ) {
      this.setState({ open: true, idle: true });
    }
  }

  render() {
    return (
      <div>
        {this.props.user.auth && (
          <Idle
            timeout={this.props.user.auth.expires_in * 1000}
            onChange={this.handleIdle}
          />
        )}
        <SimpleMessageDialog
          type="none"
          name="timeout-dialog"
          title="Session Expired"
          description="Please login again."
          closeButtonText="OK"
          closeHandler={this.handleClose}
          open={this.state.open}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout()),
    refreshToken: (...res) => dispatch(userActions.refreshToken(...res))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionExpireDialog);
