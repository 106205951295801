import Enums from "utils/Enums";
var bedImage = require("assets/img/dashboard/bed_img.png").default;

export default {
  dependentPlans: [
    {
      header: "Plan 1",
      option: "001",
      details: {
        header: "GHS & GSMM",
        details: [
          {
            header: "Spouse Only",
            label: "for your Spouse",
            premiumGHS: 317,
            premiumGSMM: 189
          },
          {
            header: "Child(ren) Only",
            label: "for your Child(ren)",
            premiumGHS: 317,
            premiumGSMM: 189
          },
          {
            header: "Spouse & Child(ren)",
            label: "for your Spouse + Child(ren)",
            premiumGHS: 529,
            premiumGSMM: 315
          },
          {
            header: "Employee",
            label: "Opt Out All Dependents",
            premiumGHS: 0,
            premiumGSMM: 0
          }
        ]
      }
    },
    {
      header: "Plan 2",
      option: "002",
      details: {
        header: "GHS & GSMM",
        details: [
          {
            header: "Spouse Only",
            label: "for your Spouse",
            premiumGHS: 581.25,
            premiumGSMM: 240
          },
          {
            header: "Child(ren) Only",
            label: "for your Child(ren)",
            premiumGHS: 581.25,
            premiumGSMM: 240
          },
          {
            header: "Spouse & Child(ren)",
            label: "for your Spouse + Child(ren)",
            premiumGHS: 1162.5,
            premiumGSMM: 400
          },
          {
            header: "Employee",
            label: "Opt Out All Dependents",
            premiumGHS: 90,
            premiumGSMM: 34
          }
        ]
      }
    },
    {
      header: "Plan 3",
      option: "003",
      details: {
        header: "GHS & GSMM",
        details: [
          {
            header: "Spouse Only",
            label: "for your Spouse",
            premiumGHS: 782.5,
            premiumGSMM: 267
          },
          {
            header: "Child(ren) Only",
            label: "for your Child(ren)",
            premiumGHS: 782.5,
            premiumGSMM: 267
          },
          {
            header: "Spouse & Child(ren)",
            label: "for your Spouse + Child(ren)",
            premiumGHS: 1565,
            premiumGSMM: 445
          },
          {
            header: "Employee",
            label: "Opt Out All Dependents",
            premiumGHS: 251,
            premiumGSMM: 52
          }
        ]
      }
    }
  ],

  previousPlan: {
    header: "Your 2019 Plan",
    details: [
      {
        overview: {
          header: "Overview",
          details: [
            {
              header: ["Coverage"],
              details: [
                "Group Hospital & Surgical (GHS)",
                "Group Supplementary Major Medical (GSMM)",
                "Group Clinical General Practitioner"
              ]
            },

            {
              header: ["Types of Hospital"],
              details: ["Private Hospital (PTE))"]
            },
            {
              header: ["No. of Beds in Room"],
              details: [{ image: bedImage, number: 2 }]
            }
          ]
        }
      },
      {
        overview: {
          header: "Group Hospital & Surgical (GHS) Benefits",
          details: [
            {
              header: ["Daily Room & Board"],
              details: ["2 beds in private hospital"]
            },

            {
              header: ["Intensive Care Unit (ICU)"],
              details: ["3 times daily room & board"]
            },
            {
              header: ["High Dependency Ward (HDW)"],
              details: ["2 times daily room & board"]
            },
            {
              header: [
                "Benefits for Private Hospitals: \n",
                "A. Other Hospital Services \n",
                "B. Surgical Fees"
              ],
              details: ["   -  \n", "6000", "8000"]
            }
          ]
        }
      },
      {
        overview: {
          header: "Group Supplementary Major Medical (GSMM) Benefits",
          details: [
            {
              header: ["Coverage"],
              details: [
                "Group Hospital & Surgical (GHS)",
                "Group Supplementary Major Medical (GSMM)",
                "Group Clinical General Practitioner"
              ]
            },

            {
              header: ["Types of Hospital"],
              details: ["Private Hospital (PTE))"]
            },
            {
              header: ["No. of Beds in Room"],
              details: ["2"]
            }
          ]
        }
      }
    ]
  },

  list: [
    {
      header: "Plan 1",
      option: "001",
      details: [
        {
          overview: {
            header: "Overview",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Government Restructured Hospital (GRH)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: [
                  { image: bedImage, number: 2 }

                  //this.asset.dashBoardImages.bedImage
                ]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Hospital & Surgical (GHS) Benefits",
            details: [
              {
                header: ["Daily Room & Board"],
                details: ["1 bed in government restructured hospital"]
              },
              {
                header: ["Intensive Care Unit (ICU)"],
                details: ["3 times daily room & board"]
              },
              {
                header: ["High Dependency Ward (HDW)"],
                details: ["2 times daily room & board"]
              },
              {
                header: [
                  "Benefits for Private Hospitals: \n",
                  "A. Other Hospital Services \n",
                  "B. Surgical Fees"
                ],
                details: ["    -   \n", "6000", "8000"]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Supplementary Major Medical (GSMM) Benefits",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Government Restructured Hospital (GRH)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: ["2"]
              }
            ]
          }
        }
      ]
    },

    {
      header: "Plan 2",
      option: "002",
      details: [
        {
          overview: {
            header: "Overview 1",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Private Hospital (PTE)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: [
                  { image: bedImage, number: 2 }
                  // this.asset.dashBoardImages.bedImage
                ]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Hospital & Surgical (GHS) Benefits",
            details: [
              {
                header: ["Daily Room & Board"],
                details: ["1 bed in private hospital"]
              },
              {
                header: ["Intensive Care Unit (ICU)"],
                details: ["3 times daily room & board"]
              },
              {
                header: ["High Dependency Ward (HDW)"],
                details: ["2 times daily room & board"]
              },
              {
                header: [
                  "Benefits for Private Hospitals: \n",
                  "A. Other Hospital Services \n",
                  "B. Surgical Fees"
                ],
                details: ["    -    \n", "6000", "8000"]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Supplementary Major Medical (GSMM) Benefits",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Government Restructured Hospital (GRH)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: ["2"]
              }
            ]
          }
        }
      ]
    },

    {
      header: "Plan 3",
      option: "003",
      details: [
        {
          overview: {
            header: "Overview",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Private Hospital (PTE)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: [
                  { image: bedImage, number: 1 }
                  // this.asset.dashBoardImages.bedImage
                ]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Hospital & Surgical (GHS) Benefits",
            details: [
              {
                header: ["Daily Room & Board"],
                details: ["1 bed in government restructured hospital"]
              },
              {
                header: ["Intensive Care Unit (ICU)"],
                details: ["3 times daily room & board"]
              },
              {
                header: ["High Dependency Ward (HDW)"],
                details: ["2 times daily room & board"]
              },
              {
                header: [
                  "Benefits for Private Hospitals:",
                  "A. Other Hospital Services",
                  "B. Surgical Fees"
                ],
                details: ["    -   \n", "6000", "8000"]
              }
            ]
          }
        },
        {
          overview: {
            header: "Group Supplementary Major Medical (GSMM) Benefits",
            details: [
              {
                header: ["Coverage"],
                details: [
                  "Group Hospital & Surgical (GHS)",
                  "Group Supplementary Major Medical (GSMM)"
                ]
              },
              {
                header: ["Types of Hospital"],
                details: ["Government Restructured Hospital (GRH)"]
              },
              {
                header: ["No. of Beds in Room"],
                details: ["2"]
              }
            ]
          }
        }
      ]
    }
  ]
};
