import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PlanSummary from "components/PlanSummary";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, history, user } = this.props;

    return (
      <div className={classes.rootInfo}>
        <div className={classes.contentInfo}>
          <PlanSummary
            history={history}
            confirmationType={this.props.location && this.props.location.state}
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  contentInfo: {
    paddingTop: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Confirmation));
