import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "actions";
import { withStyles } from "@mui/styles";
import { dashBoardImages } from "assets";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { injectIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import moment from "moment";
import MessageFormat from "messageformat";
import LoadingPopup from "components/LoadingPopup";

class ViewDashboard extends Component {
  constructor(props) {
    super(props);
    let contentVariable = new Map();
    this.route = this.props.route;

    this.state = {
      premium: props.user.premiumPlans,
      dependentPlans: props.user.premiumPlans,
      planSelected: props.user.planSelected,
      policyStartYear: moment(this.props.user.policy_start_date).format("YYYY"),
      content: props.user.content,
      contentVariable: contentVariable.set(
        "{year}",
        moment(this.props.user.policy_start_date).format("YYYY")
      ),
      placeholderMap: this.props.user.placeholderMap,
      triggerLoadingPopup: false
    };
  }

  viewPlanDetails = () => {
    this.props.employeePlan();
    this.props.dependantPlan();
    this.props.history.push("/auth/viewPlan");
  };

  premiumCalculationDep = planSelectedDependent => {
    let tempPremium = 0;
    if (planSelectedDependent === "Employee") {
      return tempPremium;
    }
    this.props.user.premiumPlans.map((plan, keyPlan) => {
      if (plan.option === this.props.user.option) {
        plan.details.details.map(planType => {
          if (planType.header === planSelectedDependent) {
            tempPremium = planType.premiumGSMM + planType.premiumGHS;
          }
        });
      }
    });
    return tempPremium;
  };

  premiumCalculationEmp = planSelected => {
    let sumPremium = 0;
    let planSelectedArray = Array.from(planSelected);
    this.state.dependentPlans.map(premiumPlans => {
      planSelectedArray.map(selectedPlans => {
        if (selectedPlans[1].option === premiumPlans.option) {
          let tempPremium = premiumPlans.details
            .find(plan => plan.header === selectedPlans[0])
            .details.find(premium => premium.header === "Employee").premium;
          sumPremium = sumPremium + tempPremium;
        }
      });
    });
    return sumPremium;
  };

  componentDidMount() {
    this.setState({
      premium: this.props.user.premiumPlans,
      content: this.props.user.content,
      triggerLoadingPopup: this.state.content ? false : true
    });
  }
  componentWillMount() {
    this.setState({
      premium: this.props.user.premiumPlans,
      dependentPlans: this.props.user.premiumPlans,
      planSelected: this.props.user.planSelected,
      content: this.props.user.content,
      triggerLoadingPopup: this.state.content ? false : true
    });
  }

  render() {
    const { classes, intl, user } = this.props;
    const labels = this.state.content && this.state.content.labels;
    const { content, placeholderMap } = this.state;
    const mf = new MessageFormat("en");
    const viewDescription =
      labels && mf.compile(labels["dashboard.view.description"]);
    const select = labels && mf.compile(labels["dashboard.view.select"]);
    const viewHeader = labels && mf.compile(labels["dashboard.view.header"]);
    const placeholderMapTemp = new Map(placeholderMap);

    return (
      <div className={classes.container}>
        <Grid container spacing={"24px"} className={classes.contentContainer}>
          <Grid item xs={12}>
            <Typography
              className={classes.titleText}
              variant="headline"
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: viewHeader({
                  fullName: placeholderMapTemp.get("{fullName}")
                })
              }}
            />
            <Typography
              className={classes.contentText}
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: viewDescription({
                  year: placeholderMapTemp.get("{year}"),
                  supportEmail: placeholderMapTemp.get("{supportEmail}"),
                  policyStartDate: placeholderMapTemp.get("{policyStart}"),
                  policyEndDate: placeholderMapTemp.get("{policyEnd}"),
                  policyYear: moment(
                    placeholderMapTemp.get("{policyStart}")
                  ).format("YYYY")
                })
              }}
            />
            <Grid item xs={12} md={6} className={classes.boxText}>
              <Card
                className={classes.cardIconBox}
                onClick={this.viewPlanDetails}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={dashBoardImages.viewPlan}
                      alt="View Plan logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography
                      className={classes.contentBoxText}
                      dangerouslySetInnerHTML={{
                        __html: select({
                          year: placeholderMapTemp.get("{year}")
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <LoadingPopup
          title="Loading"
          description="Your request is currently being processed"
          subDescription="Please wait for a while"
          name="send-document-loading"
          open={this.state.triggerLoadingPopup}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    height: "100%",
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },

  contentContainer: {
    padding: 20,
    marginTop: 50
  },
  titleText: {
    margin: theme.spacing.unit,
    padding: 20,
    //fontWeight: 400,
    fontSize: "36px",
    lineHeight: "40px",
    color: "#000000"
  },
  contentText: {
    margin: theme.spacing.unit,
    padding: 20,
    fontSize: "24px",
    lineHeight: "40px",
    color: "#6B6A6D"
  },

  contentBoxText: {
    margin: theme.spacing.unit,
    fontSize: 20,
    lineHeight: "40px"
  },

  button: {
    margin: theme.spacing.unit
  },

  cardIconBox: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },

  cardIconBoxContent: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px"
  },

  socialMedia: {
    width: 60,
    height: 60,
    marginLeft: 20,
    marginTop: 5
  },

  boxText: {
    padding: 20
  }
});

function mapStateToProps(state, prop) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(ViewDashboard)));
